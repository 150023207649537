<template>
  <div>
    <div class="row">
      <div class="col-12">
        <ValidationProvider name="subject" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El asunto es obligatorio',
        }">
          <base-input type="text" label="Asunto" placeholder="Asunto" v-model="layout.subject">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>

        <!-- <label>Header</label>
        <div class="row">
          <div class="col-5">
            <b-form-file v-model="layout.file" browse-text="Seleccionar" drop-placeholder="Cargar aquí"
              placeholder="Da click aquí o arrastra tu imagen" />
          </div>
          <div class="col-6 image" v-if="layout.header != null">
            <img class="lay" :src="layout.header" />
          </div>
          <div class="col-1" v-if="layout.header != null">
            <b-button variant="danger" size="sm" v-b-tooltip.hover title="Eliminar" @click="deleteFile(layout.id)">
              <b-icon-trash />
            </b-button>
          </div>
        </div>
        <br /> -->

        <label>Cuerpo</label>
        <div>
          <ValidationProvider name="body" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'El cuerpo es obligatorio',
          }">
            <vue-editor v-model="layout.body" :editor-toolbar="customToolbar" />
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <br />

        <!-- <label>Footer</label>
          <div class="row">
            <div class="col-6">
              <ValidationProvider
                name="fileFooter"
                rules="dimensions:1224,792"
                v-slot="{ errors }"
                :custom-messages="{
                  required: 'El archivo para footer es obligatorio',
                  dimensions: 'El footer debe medir 500 x 500 pixeles',
                }"
              >
                <input type="file" accept="image/*" />
                <span class="cardErrors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="col-6 image" v-if="layout.footer != null">
              <img class="lay" :src="layout.footer" />
            </div>
          </div> -->
        <!-- </card> -->

        <button type="submit" class="btn orange-btn float-right">
          Guardar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
// import { extend } from 'vee-validate';
// extend('containsUrlLogin', {
//     validate: (value, { compare }) => {
//         return date_compare({value, compare, validationType: 'earlier'});
//     },
//     params: ['compare', 'dateType'],
//     message: 'The selected date must not be earlier than {dateType}'
// });

export default {
  name: "LayoutEmail",

  methods: {
    deleteFile(id) {
      this.$emit("id", id);
    },
  },

  props: {
    layout: {
      id: null,
      // type: String,
      // required: true,
      file: null,
      header: null,
      // footer: null,
      body: null,
    },

    components: {
      VueEditor,
    },
  },

  data() {
    return {
      form: {
        hader: null,
        ooter: null,
        body: null,
        subject: null,
      },
      customToolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline"],
        // [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { font: [] }],
      ],
    };
  },
};
</script>

<style scoped>
.image {
  max-width: 40%;
}

.lay {
  width: 100%;
}
</style>
