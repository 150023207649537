<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <form @submit.prevent="onSubmit">

        <div class="row">
          <div class="col-md-12">
            <ValidationProvider name="persona" rules="required" v-slot="{ errors }"
              :custom-messages="{ required: 'La persona es obligatoria' }">
              <b-form-group id="input-group-1" label="Persona:" label-for="input-1">
                <b-form-select id="input-1" class="custom-select" v-model="tipoPersona" :options="optionsPersona">
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- Seleccione una opción --</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div v-if="tipoPersona === 'Física'">
          <div class="row mt-4">
            <div class="col-md-4">
              <ValidationProvider name="nombre" rules="required" v-slot="{ errors }"
                :custom-messages="{ required: 'El nombre es obligatorio' }">
                <b-form-group id="input-group-2" label="Nombre:" label-for="input-2">
                  <b-form-input id="input-2" v-model="solicitantePersonaFisica.nombre" type="text"
                    placeholder="Nombre"></b-form-input>
                </b-form-group>
                <span class="cardErrors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="col-md-4">
              <ValidationProvider name="nombre" rules="required" v-slot="{ errors }"
                :custom-messages="{ required: 'El apellido paterno es obligatorio' }">
                <b-form-group id="input-group-3" label="Apellido paterno:" label-for="input-3">
                  <b-form-input id="input-3" v-model="solicitantePersonaFisica.paterno" type="text"
                    placeholder="Paterno"></b-form-input>
                </b-form-group>
                <span class="cardErrors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="col-md-4">
              <b-form-group id="input-group-4" label="Apellido materno:" label-for="input-4">
                <b-form-input id="input-4" v-model="solicitantePersonaFisica.materno" type="text"
                  placeholder="Materno"></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row mt-4" v-if="askForNumber">
            <div class="col-md-12">
              <ValidationProvider name="numero" :rules="{ required: true, length: 10, regex: /^\d{10}$/ }"
                v-slot="{ errors }" :custom-messages="{
                  required: 'El número es obligatorio',
                  length: 'El número debe tener 10 dígitos',
                  regex: 'El número no tiene el formato correcto'
                }">
                <b-form-group id="input-group-number" label="Número de teléfono:" label-for="input-number">
                  <b-form-input class="col-md-4" id="input-number" v-model="solicitantePersonaFisica.telefono.numero"
                    type="tel" placeholder="Número de teléfono"></b-form-input>
                </b-form-group>
                <div class="row">
                  <span class="cardErrors">{{ errors[0] }}</span>
                </div>
                <div class="row">
                  <span class="cardErrors">
                    Ingrese un número de teléfono celular válido que será usado para enviarle un SMS como parte del
                    proceso de firma electrónica
                  </span>
                </div>
              </ValidationProvider>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-4">
              <ValidationProvider name="rfc" :rules="{
                required: true,
                length: 13,
                regex: /^[A-Z&Ñ]{4}\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])[A-Z0-9]{3}$/,
              }" v-slot="{ errors }" :custom-messages="{
                required: 'El RFC es obligatorio',
                length: `Debe tener 13 caracteres`,
                regex: 'El RFC no tiene el formato correcto',
              }">
                <base-input type="text" label="RFC" placeholder="RFC" v-model="solicitantePersonaFisica.rfc">
                </base-input>
                <span class="cardErrors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-md-4">
              <ValidationProvider name="curp" :rules="{
                required: true,
                length: 18,
                regex:
                  /^[A-Z&Ñ]{4}\d{6}[HM](AS|BC|BS|CC|CL|CM|CS|CH|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS)[A-Z0-9]{3}\d{2}$/,
              }" v-slot="{ errors }" :custom-messages="{
                required: 'La CURP es obligatorio',
                length: 'Debe tener 18 caracteres',
                regex: 'La CURP no tiene el formato correcto',
              }">
                <base-input type="text" label="CURP" placeholder="CURP" v-model="solicitantePersonaFisica.curp">
                </base-input>
                <span class="cardErrors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-md-4">
              <ValidationProvider name="fecha_nacimiento" rules="required" v-slot="{ errors }" :custom-messages="{
                required: 'La fecha de nacimiento es obligatoria',
              }">
                <base-input type="date" label="Fecha de nacimiento" placeholder="Fecha de nacimiento"
                  v-model="solicitantePersonaFisica.fecha_nacimiento">
                </base-input>
                <span class="cardErrors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="row mt-4">
            <div class="col-md-12">
              <ValidationProvider name="ocupacion" rules="required" v-slot="{ errors }" :custom-messages="{
                required: 'La ocupación es obligatoria',
              }">
                <base-input type="text" label="Ocupación / Actividad / Profesión" placeholder="Ocupación"
                  v-model="solicitantePersonaFisica.ocupacion">
                </base-input>
                <span class="cardErrors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-md-4">
              <ValidationProvider name="lugar_nacim_pais" rules="required" v-slot="{ errors }"
                :custom-messages="{ required: 'El país es obligatorio' }">
                <label>Lugar de nacimiento: País</label>
                <select @change="fetchStatesByCountry" v-model="selectCountry" class="form-control">
                  <option v-for="option in countries" :key="option.id">
                    {{ option.name }}
                  </option>
                </select>
                <span class="cardErrors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="col-md-4">
              <ValidationProvider name="lugar_nacim_estado" rules="required" v-slot="{ errors }" :custom-messages="{
                required: 'El estado es obligatorio',
              }">
                <label>Lugar de nacimiento: Estado</label>
                <select @change="fetchCitiesByState" v-model="selectState" class="form-control">
                  <option v-if="isEmptyStates" value="noStates" selected>
                    {{ noStatesText }}
                  </option>

                  <option v-for="option in states" :key="option.id">
                    {{ option.name }}
                  </option>
                </select>
                <span class="cardErrors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="col-md-4">
              <ValidationProvider name="lugar_nacim_ciudad" rules="required" v-slot="{ errors }" :custom-messages="{
                required: 'La ciudad de nacimiento es obligatoria',
              }">
                <label>Municipio / Alcaldía</label>
                <select v-model="selectCity" class="form-control">
                  <option v-if="isEmptyCities" value="noCities" selected>
                    {{ noCitiesText }}
                  </option>
                  <option v-for="option in cities" :key="option.id">
                    {{ option.name }}
                  </option>
                </select>
                <span class="cardErrors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

        </div>


        <div class="mt-4" v-else-if="tipoPersona === 'Moral'">
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider name="denominacion_social" rules="required" v-slot="{ errors }"
                :custom-messages="{ required: 'La denominacion social es obligatoria' }">
                <b-form-group id="input-group-5" label="Denominación social:" label-for="input-5">
                  <b-form-input id="input-5" v-model="solicitantePersonaMoral.denominacion_social" type="text"
                    placeholder="Denominación social"></b-form-input>
                </b-form-group>
                <span class="cardErrors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <ValidationProvider name="rfc" :rules="{
                required: true,
                length: 12,
                regex:
                  /^[A-Z&Ñ]{3}\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])[A-Z0-9]{3}$/,
              }" v-slot="{ errors }" :custom-messages="{
                required: 'El RFC es obligatorio',
                length: `Debe tener 12 caracteres`,
                regex: 'El RFC no tiene el formato correcto',
              }">
                <base-input type="text" label="RFC" placeholder="RFC" v-model="solicitantePersonaMoral.rfc">
                </base-input>
                <span class="cardErrors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-md-6">
              <ValidationProvider name="fecha_constitucion" rules="required" v-slot="{ errors }"
                :custom-messages="{ required: 'La fecha de constitución es obligatoria' }">
                <base-input type="date" label="Fecha de constitución" placeholder="Fecha de constitución"
                  v-model="solicitantePersonaMoral.fecha_constitucion">
                </base-input>
                <span class="cardErrors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-4">
              <ValidationProvider name="lugar_nacim_pais" rules="required" v-slot="{ errors }"
                :custom-messages="{ required: 'El país es obligatorio' }">
                <label>Lugar de constitución: País</label>
                <select @change="fetchStatesByCountry" v-model="selectCountry" class="form-control">
                  <option v-for="option in countries" :key="option.id">
                    {{ option.name }}
                  </option>
                </select>
                <span class="cardErrors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>

            <div class="col-md-4">
              <ValidationProvider name="clave" rules="required" v-slot="{ errors }" :custom-messages="{
                required: 'La clave LADA es requerida',
              }">
                <base-input type="text" label="Código país" placeholder="Código país" v-model="phoneKey"></base-input>
                <span class="cardErrors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="col-md-4">
              <ValidationProvider name="numero" rules="required|length:10" v-slot="{ errors }" :custom-messages="{
                required: 'El número de teléfono es requerido',
                length: 'El número debe tener 10 dígitos'
              }">
                <base-input type="text"
                  label="Número de Teléfono Celular" placeholder="Número"
                  v-model="solicitantePersonaMoral.telefonos[0].numero">
                </base-input>
                <span class="cardErrors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>


          <div class="row">
            <label class="col-md-12 fs-6 text-black-50 text-uppercase">Representante(s)</label>
          </div>

          <div v-for="(representante, index) in solicitantePersonaMoral.representantes" :key="index">
            <div class="row">
              <div class="col-md-4">
                <ValidationProvider name="nombre" rules="required" v-slot="{ errors }"
                  :custom-messages="{ required: 'El nombre es obligatorio' }">
                  <b-form-group :id="'input-group-representante-nombre-' + index" label="Nombre:"
                    :label-for="'input-representante-nombre-' + index">
                    <b-form-input :id="'input-representante-nombre-' + index" v-model="representante.nombre" type="text"
                      placeholder="Nombre"></b-form-input>
                  </b-form-group>
                  <span class="cardErrors">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-md-4">
                <ValidationProvider name="nombre" rules="required" v-slot="{ errors }"
                  :custom-messages="{ required: 'El apellido paterno es obligatorio', }">
                  <b-form-group :id="'input-group-representante-paterno-' + index" label="Apellido paterno:"
                    :label-for="'input-representante-materno-' + index">
                    <b-form-input :id="'input-representante-materno-' + index" v-model="representante.paterno"
                      type="text" placeholder="Paterno"></b-form-input>
                  </b-form-group>
                  <span class="cardErrors">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-md-4">
                <b-form-group :id="'input-group-representante-materno-' + index" label="Apellido materno:"
                  :label-for="'input-representante-materno-' + index">
                  <b-form-input :id="'input-representante-materno-' + index" v-model="representante.materno" type="text"
                    placeholder="Materno"></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row mt-4" v-if="askForNumber">
              <div class="col-md-12">
                <ValidationProvider name="numero" :rules="{ required: true, length: 10, regex: /^\d{10}$/ }"
                  v-slot="{ errors }" :custom-messages="{
                    required: 'El número es obligatorio',
                    length: 'El número debe tener 10 dígitos',
                    regex: 'El número no tiene el formato correcto'
                  }">
                  <b-form-group :id="'input-group-representante-number-' + index" label="Número de teléfono:"
                    :label-for="'input-representante-number-' + index">
                    <b-form-input class="col-md-4" id="input-number" v-model="representante.telefono.numero" type="tel"
                      placeholder="Número de teléfono"></b-form-input>
                  </b-form-group>
                  <div class="row">
                    <span class="cardErrors">{{ errors[0] }}</span>
                  </div>
                  <div class="row">
                    <span class="cardErrors">
                      Ingrese un número de teléfono celular que válido será usado para enviarle un SMS como parte del
                      proceso de firma electrónica
                    </span>
                  </div>
                </ValidationProvider>
              </div>
            </div>
          </div>

          <div class="row">
            <b-button v-if="canRemoveRepresentante" variant="danger" class="btn-fill spaceL" size="sm"
              @click="removeRepresentante">
              Remover representante
            </b-button>
            <b-button v-if="canAddRepresentante" variant="secondary" class="btn-fill spaceL" size="sm"
              @click="addRepresentante">
              Agregar otro representante
            </b-button>
          </div>

        </div>

        <div class="row mt-4"></div>

        <div class="mt-4 text-center">
          <button class="btn btn-fill float-right orange-btn" type="submit" :disabled="invalid">
            Guardar
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "ComplementaInformacionSolicitantes",

  props: {
    currentSolicitante: { type: Object },
    maxNumberOfRepresentantes: {
      type: Number,
      default: () => 1
    },
    askForNumber: {
      type: Boolean,
      default: () => false
    }
  },

  data: function () {
    return ({
      optionsPersona: [
        { text: "Persona Física", value: "Física" },
        { text: "Persona Moral", value: "Moral" },
      ],

      tipoPersona: null,

      solicitantePersonaFisica: {
        nombre: "",
        paterno: "",
        materno: "",
        numero: "",
        telefono: {
          tipo: "Celular",
          clave: 52,
          numero: ""
        }
      },
      solicitantePersonaMoral: {
        denominacion_social: "",
        representantes: []
      },

      phoneKey: "",
      noStatesText: "No hay estados disponibles",
      noCitiesText: "No hay ciudades disponibles",
      selectCountry: "",
      selectState: "",
      selectCity: "",
    });
  },

  created() {
    console.log(this.currentSolicitante);
    this.initProperties();
  },

  computed: {
    canAddRepresentante() {
      return this.solicitantePersonaMoral.representantes.length < this.maxNumberOfRepresentantes;
    },
    canRemoveRepresentante() {
      return this.solicitantePersonaMoral.representantes.length > 1;
    },
    ...mapState([
      "countries",
      "states",
      "cities",
      "isEmptyStates",
      "isEmptyCities",
    ]),
    ...mapGetters([
      "getCountries",
      "getStatesByCountryId",
      "getCitiesByStateId",
    ]),
  },

  async mounted() {
    await this.fetchCountries();

    if (this.currentSolicitante.lugar_nacim_pais) {
      this.selectCountry = this.currentSolicitante.lugar_nacim_pais;

      if (this.currentSolicitante.telefonos[0].clave) {
        this.phoneKey = this.currentSolicitante.telefonos[0].clave;
      }
      if (this.currentSolicitante.lugar_nacim_estado) {
        await this.getExistCountry(this.currentSolicitante.lugar_nacim_pais);
      }
      if (this.currentSolicitante.lugar_nacim_estado) {
        await this.getExistState(this.currentSolicitante.lugar_nacim_estado);
      }
      if (this.currentSolicitante.lugar_nacim_ciudad) {
        await this.getExistCity(this.currentSolicitante.lugar_nacim_ciudad);
      }
    }
  },

  watch: {
    "currentSolicitante.rfc": function (value) {
      if (!value) {
        this.currentSolicitante.rfc = "";

        return;
      }

      this.currentSolicitante.rfc = this.currentSolicitante.rfc.toUpperCase();
    },

    "currentSolicitante.curp": function (value) {
      if (!value) {
        this.currentSolicitante.curp = "";

        return;
      }

      this.currentSolicitante.curp = this.currentSolicitante.curp.toUpperCase();
    }
  },

  methods: {
    initProperties() {
      if (!this.currentSolicitante.persona) {
        this.tipoPersona = null;
        this.solicitantePersonaMoral.representantes.push({});

        return;
      }

      if (this.currentSolicitante.persona === "Física") {
        this.tipoPersona = "Física";
        this.solicitantePersonaFisica.nombre = this.currentSolicitante.nombre || "";
        this.solicitantePersonaFisica.paterno = this.currentSolicitante.paterno || "";
        this.solicitantePersonaFisica.materno = this.currentSolicitante.materno || "";

        if (this.currentSolicitante.telefono) {
          this.solicitantePersonaFisica.telefono.tipo = this.currentSolicitante.telefono.tipo || "Celular";
          this.solicitantePersonaFisica.telefono.clave = this.currentSolicitante.telefono.clave || 52;
          this.solicitantePersonaFisica.telefono.numero = this.currentSolicitante.telefono.numero || "";
        } else {
          this.solicitantePersonaFisica.telefono = {
            tipo: "Celular",
            clave: 52,
            numero: ""
          }
        }

        return;
      }

      this.tipoPersona = "Moral";
      this.solicitantePersonaMoral.denominacion_social = this.currentSolicitante.denominacion_social || "";

      for (const representante of this.currentSolicitante.representantes) {
        // TODO Asignar propiedades manualmente
        this.solicitantePersonaMoral.representantes.push(representante);
      }
    },

    addRepresentante() {
      this.solicitantePersonaMoral.representantes.push({});
    },

    removeRepresentante() {
      this.solicitantePersonaMoral.representantes.pop();
    },

    onSubmit() {
      if (!this.tipoPersona) {
        return;
      }

      const solicitante = {
        id: this.currentSolicitante.id,
        tipoPersona: this.tipoPersona
      };

      if (this.tipoPersona === "Física") {
        Object.assign(solicitante, this.solicitantePersonaFisica);
      } else if (this.tipoPersona === "Moral") {
        Object.assign(solicitante, this.solicitantePersonaMoral);
      }

      this.$emit('submit-form', solicitante);
    },

    ...mapActions(["fetchCountries", "fetchStates", "fetchCities"]),

    async fetchStatesByCountry() {
      /**
       * Esta fx se llama solo si ya se tiene un país seleccionado
       */
      this.selectState = "";
      const pais = this.selectCountry;
      this.selectCity = "";
      // change store state cities to empty
      this.$store.commit("setCities", []);
      // Obtengo el id del país
      const paisId = await this.getIdCountry(pais);
      // obtengo el código de area del país
      const phoneCode = await this.getPhoneCode(pais);
      this.phoneKey = phoneCode;
      // this.currentSolicitante.telefonos[0].clave = phoneCode;
      // obtengo los estados del país
      await this.fetchStates(paisId);
      if (this.isEmptyStates) {
        this.selectState = "noStates";
        this.selectCity = "noCities";
      }
    },

    async fetchCitiesByState() {
      /**
       * Se llama para obtener las ciudades de un estado seleccionado
       */
      this.selectCity = "";
      const estado = this.selectState
      const estadoId = await this.getIdState(estado);
      await this.fetchCities(estadoId);
    },

    async getIdCountry(countryName) {
      /**
       * Obtiene el id del país seleccionado
       */
      const country = this.countries.find((c) => c.name === countryName);
      return country ? country.id : null;
    },

    async getIdState(stateName) {
      /**
       * Obtiene el id del estado seleccionado
       */
      const state = this.states.find((s) => s.name === stateName);
      return state ? state.id : null;
    },

    async getPhoneCode(countryName) {
      /**
       * Obtiene el código de área del país seleccionado
       */
      const country = this.countries.find((c) => c.name === countryName);
      return country ? country.phone_code : null;
    },

    async getExistCountry(countryName) {
      /**
       * Se llama si ya se tiene un país seleccionado
       */
      const currentCountry = countryName === "Mexico" ? "México" : this.currentSolicitante.lugar_nacim_pais;
      this.selectCountry = currentCountry;
    },

    async getExistState(stateName) {
      /**
       * Se llama si ya se tiene un estado seleccionado
       */
      const currentState = stateName
      this.selectState = currentState;
      const paisId = await this.getIdCountry(this.selectCountry);
      await this.fetchStates(paisId);
    },

    async getExistCity(cityName) {
      /**
       * Se llama si ya se tiene una ciudad seleccionada
       */
      const currentCity = cityName;
      this.selectCity = currentCity;
      const estadoId = await this.getIdState(this.selectState);
      await this.fetchCities(estadoId);
    },

    setGeoCompareciente() {
      /**
       * Setea los valores de los campos de compareciente
       */
      this.currentSolicitante.lugar_nacim_pais = this.selectCountry;
      this.currentSolicitante.lugar_nacim_estado = this.selectState;
      this.currentSolicitante.lugar_nacim_ciudad = this.selectCity;
      this.currentSolicitante.telefonos[0].clave = this.phoneKey;
    },
  }
}
</script>