<template>
  <div class="container" id="container-openpay">
    <div v-if="loading">
      <div id="loader">
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div>

    <div class="bkng-tb-cntnt">
      <div class="row">
        <div class="col-12">
          <h4 class="cardD mt-3">Saldo actual</h4>
          <h3 class="saldo">{{ formatedSaldo }}</h3>
        </div>
      </div>

      <div v-if="suficiente">
        <label>Costo total del servicio {{ formatedPrecioTotal }}</label>
      </div>

      <div v-if="suficiente">
        <b-button class="btn-fill orange-btn bottom2" size="m" @click="pagar()">Pagar</b-button>
      </div>

      <div class="mt-3" v-if="!suficiente">
        El saldo en el monedero no es suficiente para realizar esta compra por {{ formatedPrecioTotal }}. Por favor
        recargue el Monedero de Correduría Digital en el apartado de "Monedero"
      </div>
    </div>

  </div>
</template>

<script>
import { FuncionesApi } from "@/api";
import Storage from "@/utils/storage";
import { formatCurrency } from "@/utils/number-utils";

const storage = new Storage();

export default {
  name: "Monedero",

  props: {
    cotizacion_id: { type: Number, default: null },
    clientes_id: { type: Number, default: null },
    precio_total: { type: Number, default: 0 },
    servicio: { type: String, default: null }
  },

  data: () => ({
    loading: false,
    saldo: 0,
    suficiente: false
  }),

  computed: {
    formatedSaldo() {
      return formatCurrency(this.saldo);
    },
    formatedPrecioTotal() {
      return formatCurrency(this.precio_total);
    }
  },

  watch: {
    "clientes_id": function (newVal, oldVal) {
      if (newVal && (newVal !== oldVal)) {
        this.determinar_saldo();
      }
    },
    "precio_total": function (newVal, oldVal) {
      if (newVal && (newVal !== oldVal)) {
        this.verificarSaldoSuficiente();
      }
    }
  },

  created() {
    if (this.clientes_id) {
      this.determinar_saldo();
    }
  },

  methods: {
    verificarSaldoSuficiente() {
      if (Number(this.saldo) >= Number(this.precio_total)) {
        this.suficiente = true;
      } else {
        this.suficiente = false;
      }
    },

    async determinar_saldo() {
      this.saldo = await FuncionesApi.getSaldoCliente(this.clientes_id);

      this.verificarSaldoSuficiente();
    },

    async pagar() {
      try {
        this.loading = true;

        const email = storage.getItem("user");
        const pagoConMonederoRequest = {
          clientes_id: this.clientes_id,
          monto: this.precio_total,
          servicio: this.servicio,
          email: email,
          cotizaciones_id: this.cotizacion_id
        }

        await FuncionesApi.pagoConMonedero(pagoConMonederoRequest);

        this.$toast.success("Pagado", {
          timeout: 4000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });

        this.$emit("payCotizacion", true);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.saldo {
  text-align: center;
}
</style>
