<template>
  <div>
    <table class="tabla">
      <tr>
        <th colspan="10" class="section">
          {{ datos.compareciente_principal }}
        </th>
      </tr>
      <tr class="date">
        <th>
          Ciudad de México a {{ datos.day }} de {{ datos.month }} de {{ datos.year }}
        </th>
      </tr>
    </table>

    <table class="tabla">
      <tr>
        <td style="width: 62.5%; font-weight: bolder;">
          Correduria Digital, S. de R.L. de C.V.
        </td>
      </tr>
      <tr>
        <td>
          Av. Paseo de las Palmas 731, oficina 202,
        </td>
      </tr>
      <tr>
        <td>
          Lomas de Chapultepec, 11000, Miguel Hidalgo,
        </td>
      </tr>
      <tr>
        <td>
          Ciudad de México, México
        </td>
      </tr>
    </table>

    <table class="tabla" style="margin-top: 15px; ">
      <tr>
        <td style="width: 5%; font-weight: bolder; vertical-align: top;">
          Atn':
        </td>
        <td>
          Corredor Público asignado por Correduria Digital, S. de R.L. de C.V., para llevar a
          cabo la diligencia a que se refiere la presente instrucción
        </td>
      </tr>
    </table>

    <table class="tabla" style="margin-top: 5px; border-spacing: 0 15px;">
      <tr>
        <td>
          Por medio del presente escrito, {{ autorizo }} a Correduría Digital, S. de R.L. de C.V. para
          gestionar con el Corredor Público de su elección, en la plaza de {{ datos.plaza }}, para que éste lleve
          a cabo como si {{ elSuscritoInstruyera }} de forma directa, la diligencia a que se refiere la presente
          instrucción.
        </td>
      </tr>
      <tr>
        <td>
          Por medio de la presente {{ solicito }} al Corredor Público designado por Correduría Digital, S. de
          R.L. de C.V., se sirva notificar a {{ datos.notificados }} en el domicilio ubicado en {{ datos.domicilio }},
          {{ elOriginal_unaImpresionDeLaVersionDigital }} de la
          comunicación que adjunto a la presente solicitud, cuyo contenido y alcance declaro, bajo
          protesta de decir verdad, conocer y entender.
        </td>
      </tr>
      <tr>
        <td>
          Expresamente {{ autorizo }} al Corredor Público para (i) llevar a cabo la diligencia de notificación
          en el domicilio señalado, no obstante que al momento de la actuación, la persona que atienda
          le informe que en dicho domicilio no se encuentra el destinatario; (ii) llevar a cabo la
          diligencia de notificación en el domicilio señalado mediante la fijación del instructivo de
          notificación y anexos correspondientes en la puerta u otro lugar visible del domicilio, o bien
          depositándolos en el interior del domicilio por cualquier acceso; y (iii) en caso de que la
          persona a la que se dirige la carta objeto de la notificación se encuentre en otro domicilio al
          anteriormente señalado, llevar a cabo la diligencia correspondiente en dicho otro domicilio,
          siempre y cuando la persona con quien se entienda la diligencia manifieste que es domicilio
          de la persona con la cual deba practicarse la notificación, o le conste por cualquier otro medio.
        </td>
      </tr>
      <tr>
        <td>
          Asimismo, {{ autorizo }} al Corredor Público designado para, que en caso de que no le sea
          permitido el acceso a los números interiores del inmueble, realizar la notificación en los
          términos arriba planteados en la recepción del inmueble principal, en la entrada principal del
          mismo, en cualquier buzón localizado en el inmueble, o bien en la oficialía de partes, de
          mensajería o similar ubicada en el domicilio señalado.
        </td>
      </tr>
      <tr>
        <td>
          {{ Declaro }}, bajo protesta de decir verdad: (i) conocer y entender el contenido y alcance de la
          comunicación objeto de la diligencia de notificación que se solicita; (ii) que los hechos sobre
          los cuales versará la diligencia solicitada, forman parte del medio para el ejercicio de la
          actividad mercantil de los comerciantes involucrados; (iii) que la comunicación señalada
          forma parte de los documentos a que se refieren los artículos treinta y tres a cincuenta del
          Código de Comercio; y (iv) ser {{ responsable }} del contenido del documento que presento para
          llevar a cabo la diligencia, y de la exactitud de los datos proporcionados para el desarrollo de
          la misma.
        </td>
      </tr>
      <tr>
        <td>
          Atentamente,
        </td>
      </tr>
    </table>
    <table class="tabla" v-if="datos.apoderado">
      <tr class="space" style="height: 200px;"></tr>
      <tr>
        <th colspan="10" class="section">
          {{ datos.compareciente_principal }}
        </th>
      </tr>
      <tr class="space" style="height: 100px;"></tr>
      <tr>
        <td class="space" style="width: 10%;"></td>
        <td class="user" style="width: auto; background-color: white !important; border-bottom: solid 2px black;"></td>
        <td class="space" style="width: 30%;"></td>
        <td class="user" style="width: auto; background-color: white; border-bottom: solid 2px"></td>
        <td style="width: 10%;"></td>
      </tr>
      <tr>
        <td class="space" style="width: 20%;"></td>
        <td style="width: 60%; vertical-align: top; font-size: 14px; text-align: center; background-color: white; ">
          Por: {{ datos.apoderado }}
        </td>
        <td colspan="2" class="space" style="width: 20%;"></td>
      </tr>
      <tr>
        <td class="space" style="width: 20%;"></td>
        <td style="width: 60%; vertical-align: top; font-size: 14px; text-align: center; background-color: white; ">
          Cargo: Representante Legal
        </td>
        <td colspan="2" class="space" style="width: 20%;"></td>
      </tr>
    </table>
    <table class="tabla" v-else>
      <tr class="space" style="height: 200px;"></tr>
      <tr>
        <th colspan="10" class="section">
          {{ datos.compareciente_principal }}
        </th>
      </tr>
      <tr class="space" style="height: 100px;"></tr>
      <tr>
        <td class="space" style="width: 20%;"></td>
        <td class="user" style="width: 60%; background-color: white !important; border-bottom: solid 2px black;"></td>
        <td class="space" style="width: 20%;"></td>
      </tr>
      <!-- <tr v-if="datos.apoderado">
        <td class="space" style="width: 20%;"></td>
        <td style="width: 60%; vertical-align: top; font-size: 14px; text-align: center; background-color: white; ">
          Por: {{ datos.apoderado }}
        </td>
        <td colspan="2" class="space" style="width: 20%;"></td>
      </tr>
      <tr v-if="datos.apoderado">
        <td class="space" style="width: 20%;"></td>
        <td style="width: 60%; vertical-align: top; font-size: 14px; text-align: center; background-color: white; ">
          Cargo: Representante Legal
        </td>
        <td colspan="2" class="space" style="width: 20%;"></td>
      </tr> -->
    </table>

  </div>
</template>

<script>
export default {
  name: "CartaInstruccionTemplate",

  props: {
    datos: { type: Object },
  },

  created() {
    if (this.datos.plural) {
      this.elSuscritoInstruyera = 'los suscritos instruyeran',
        this.solicito = 'solicitamos',
        this.autorizo = 'autorizamos',
        this.Declaro = 'Declaramos',
        this.responsable = 'responsable'
    }

    if (this.datos.fad) {
      this.elOriginal_unaImpresionDeLaVersionDigital = 'una impresión de la versión digital'
    }
  },

  data() {
    return {
      elSuscritoInstruyera: 'el suscrito instruyera',
      solicito: 'solicito',
      elOriginal_unaImpresionDeLaVersionDigital: 'el original',
      autorizo: 'autorizo',
      Declaro: 'Declaro',
      responsable: 'responsable'
    }
  }
}
</script>
