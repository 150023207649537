<template>
  <div class="container">
    <div v-if="loading">
      <div id="loader">
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">¿Seguro que quiere eliminar al accionista?</h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteAccionista">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="accionista-modal" title="" hide-footer id="modalC" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(putAccionista)">
          <ModalCapitalAccionista :currentAccionista="currentAccionista" @submit.prevent
            @calcular="calcularCapitales" />
        </form>
      </ValidationObserver>
    </b-modal>

    <h4 class="cardD">Datos generales del Capital y de los Accionistas</h4>

    <div class="row mt-4">
      <div class="col-6">
        <ValidationProvider name="capital_fijo" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El capital fijo es obligatorio'
        }">
          <base-input :disabled="constitutiva.disabledCapital" type="number" label="Monto de Capital Fijo"
            placeholder="$" v-model="constitutiva.capital_fijo" @keyup="calcularCapitales">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <!-- <div class="col-6" v-if="this.constitutiva.tiene_capital_variable">
        <ValidationProvider
          name="capital_variable"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El capital variable es obligatorio',
          }"
        >
          <base-input
            :disabled="constitutiva.disabledCapital"
            type="number"
            label="Monto de Capital Variable"
            placeholder="$"
            v-model="constitutiva.capital_variable"
            @keyup="calcularCapitales"
          >
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div> -->
    </div>

    <div class="row mt-4">
      <div class="col-12">
        <label for="">Hay Personas Físicas extranjeras</label>

        <div v-if="constitutiva.roleStaff">
          <b-form-checkbox v-model="constitutiva.capital_extranjero" :disabled="constitutiva.disabledCapital">
          </b-form-checkbox>
        </div>
        <div v-else>
          <b-form-checkbox v-model="constitutiva.capital_extranjero" :disabled="constitutiva.disabled">
          </b-form-checkbox>
        </div>

        <label class="mt-2 leyenda" v-if="constitutiva.capital_extranjero">
          La cotización básica no incluye capital extranjero.
        </label>
      </div>
    </div>

    <div class="mt-4">
      <label for="">Cuadro Accionario</label>
      <b-button v-if="constitutiva.roleStaff" :disabled="constitutiva.disabledCapital" variant="success"
        class="btn-fill spaceL" size="sm" @click="addAccionista">+</b-button>
    </div>

    <div class="mt-4">
      <label for="">Seleccionar Comparecientes</label>
      <select class="custom-select" v-model="selectedCompareciente" @change="selectCompareciente"
        :disabled="constitutiva.disabled">
        <option v-for="option in options_comparecientes" :key="option.value" v-bind:value="option.value">
          {{ option.text }}
        </option>
      </select>
    </div>

    <div>
      <b-table hover :fields="fieldsCuadro" :items="constitutiva.accionistas">
        <template #cell(acciones)="{ item }">
          <b-button :disabled="constitutiva.disabledCapital" variant="warning" size="sm" v-b-tooltip.hover
            title="Editar" class="editBtn" @click="editAccionista(item)">
            <b-icon-pencil />
          </b-button>
          <b-button v-if="constitutiva.roleStaff" :disabled="constitutiva.disabledCapital" variant="danger" size="sm"
            v-b-tooltip.hover title="Eliminar" @click="predeleteAccionista(item.index)">
            <b-icon-trash />
          </b-button>
        </template>
      </b-table>
      <label v-if="totalPorcentajes != null" for="">Total {{ totalPorcentajes }} %</label>
      <p></p>
      <ValidationProvider name="porcentaje_total" rules="cien" v-slot="{ errors }">
        <input type="text" v-model="totalPorcentajes" hidden />
        <span class="cardErrors">{{ errors[0] }}</span>
      </ValidationProvider>
    </div>

    <label class="mt-2 leyenda" v-if="constitutiva.accionistas.length > 2">
      La cotización básica incluye solo 2 accionistas personas físicas.
    </label>

    <label class="mt-2 leyenda" v-if="constitutiva.accionistas.length < 2">
      Se deben agregar mínimo 2 accionistas.
    </label>

    <!-- <div class="mt-4">
      <div class="">
        <b-button
          type="submit"
          class="btn-fill orange-btn bottom2"
          size="m"
          :disabled="constitutiva.disabledCapital"
          >Validar</b-button
        >
      </div>
    </div> -->
    <div class="mt-4" v-if="constitutiva.accionistas.length < 2">
      <div class="row">
        <div class="col-10">
          <b-button type="submit" class="btn-fill orange-btn bottom2" size="m" disabled>Guardar</b-button>
        </div>
        <div class="col-2">
          <b-button variant="success" class="btn-fill bottom2" size="m" disabled>Validar</b-button>
        </div>
      </div>
    </div>

    <div class="mt-4" v-else>
      <div class="row">
        <div class="col-10">
          <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
            :disabled="constitutiva.disabledCapital">Guardar</b-button>
        </div>
        <div class="col-2">
          <b-button variant="success" class="btn-fill bottom2" size="m" :disabled="constitutiva.disabledCapital"
            @click="validar()">Validar</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCapitalAccionista from "@/views/clients/procesos/constitucion/infoBase/modales/ModalCapitalAccionista.vue";
import { ComparecientesApi } from "@/api";

export default {
  name: "TabDatosCapital",

  computed: {
    fieldsCuadro() {
      let fieldsCuadro = [
        { key: "index", label: "", class: "text-center" },
        {
          key: "nombreCompleto",
          label: "Nombre",
          class: "text-center"
        },
        // { key: "paterno", label: "Paterno", class: "text-center" },
        // { key: "materno", label: "Materno", class: "text-center" },
        { key: "persona", label: "Persona", class: "text-center" },

        { key: "porcentaje", label: "Porcentaje", class: "text-center" },
        { key: "capital_fijo", label: "Capital Fijo", class: "text-center" },
        // {
        //   key: "capital_variable",
        //   label: "Capital Variable",
        //   class: "text-center"
        // },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fieldsCuadro;
    }
  },

  created() {
    this.calcularCapitales();
    this.getComparecientes();
  },

  components: {
    ModalCapitalAccionista
  },

  props: {
    constitutiva: { type: Object }
  },

  data() {
    return {
      loading: false,
      comparecientes: [],
      options_comparecientes: [],
      selectedCompareciente: null,

      currentAccionistaId: null,
      currentAccionista: null,
      totalPorcentajes: null,

      form: {
        capital_fijo: null,
        capital_variable: null,
        accionistas: [],
        totalPorcentajesF: null,
        capital_extranjero: null
      }
    };
  },

  methods: {
    async getComparecientes() {
      try {
        this.loading = true;

        const { value } = await ComparecientesApi.combo({
          clientes_id: 0
        });

        value.forEach(element => {
          let nombreTotal = "";
          if (element.persona === "Física") {
            nombreTotal = `${element.nombre} ${element.paterno} ${element.materno}`;
          } else {
            nombreTotal = element.denominacion_social;
          }
          const obj = {
            text: nombreTotal,
            value: element.id
          };
          this.options_comparecientes.push(obj);

          element.nombreCompleto = nombreTotal;
          element.persona = `Persona ${element.persona}`;
          element.rfc = element.rfc;
        });

        this.comparecientes = value;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    selectCompareciente() {
      let indexYa = this.constitutiva.accionistas.findIndex(o => o.id === this.selectedCompareciente);

      // console.log(this.selectedCompareciente);
      // console.log(indexYa);
      if (indexYa != -1) {
        this.$toast.warning("No se puede seleccionar 2 veces al mismo compareciente", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });

        return;
      }

      let index = this.comparecientes.findIndex(o => o.id === this.selectedCompareciente);

      // console.log(this.comparecientes[index]);
      this.comparecientes[index].capital_fijo = 0;
      this.comparecientes[index].capital_variable = 0;
      this.comparecientes[index].porcentaje = 0;
      this.comparecientes[index].rfc = this.comparecientes[index].rfc;
      
      const a = Object.assign({}, this.comparecientes[index]);
      a.index = this.constitutiva.accionistas.length + 1;

      this.constitutiva.accionistas.push(a);
    },

    calcularCapitales() {
      let totalPorcentajes = 0;
      for (let i = 0; i < this.constitutiva.accionistas.length; i++) {
        let capital_fijo = (this.constitutiva.accionistas[i].porcentaje * this.constitutiva.capital_fijo) / 100;

        this.constitutiva.accionistas[i].capital_fijo = capital_fijo;

        let capital_variable = (this.constitutiva.accionistas[i].porcentaje * this.constitutiva.capital_variable) / 100;
        this.constitutiva.accionistas[i].capital_variable = capital_variable;

        totalPorcentajes = totalPorcentajes + parseFloat(this.constitutiva.accionistas[i].porcentaje);

        const a = Object.assign({}, this.constitutiva.accionistas[i]);
        this.constitutiva.accionistas.splice([i], 1, a);
        // this.constitutiva.accionistas[i] = a;
      }
      this.totalPorcentajes = totalPorcentajes;
    },

    addAccionista() {
      this.$refs["accionista-modal"].show();
      this.currentAccionista = {};
      this.currentAccionista.capital_fijo = 0;
      this.currentAccionista.capital_variable = 0;
    },

    editAccionista(item) {
      this.$refs["accionista-modal"].show();
      this.currentAccionista = item;
    },

    putAccionista() {
      if (!this.currentAccionista.index) {
        this.currentAccionista.index = this.constitutiva.accionistas.length + 1;

        let nombre = "";
        if (this.currentAccionista.persona === "Persona Física") {
          nombre = `${this.currentAccionista.nombre} ${this.currentAccionista.paterno} ${this.currentAccionista.materno}`;
        } else {
          nombre = `${this.currentAccionista.denominacion_social}`;
        }
        this.currentAccionista.nombreCompleto = nombre;
        this.currentAccionista.visible = 1;

        const a = Object.assign({}, this.currentAccionista);
        this.constitutiva.accionistas.push(a);
      } else {
        let index = this.constitutiva.accionistas.findIndex(o => o.index === this.currentAccionista.index);
        let nombre = "";

        if (this.currentAccionista.persona === "Persona Física") {
          nombre = `${this.currentAccionista.nombre} ${this.currentAccionista.paterno} ${this.currentAccionista.materno}`;
        } else {
          nombre = `${this.currentAccionista.denominacion_social}`;
        }

        this.currentAccionista.nombreCompleto = nombre;

        const a = Object.assign({}, this.currentAccionista);
        this.constitutiva.accionistas.splice([index], 1, a);
      }

      this.calcularCapitales();
      this.$refs["accionista-modal"].hide();
    },

    predeleteAccionista(id) {
      this.currentAccionistaId = id;
      this.$refs["modal-confirm-dialog"].show();
    },

    deleteAccionista() {
      const index2 = this.constitutiva.accionistas.findIndex(o => o.index === this.currentAccionistaId);

      this.constitutiva.accionistas.splice(index2, 1);
      this.calcularCapitales();

      this.$refs["modal-confirm-dialog"].hide();
    },

    validar() {
      this.$emit("validaForm", "DatosCapital");
    }
  }
};
</script>

<style scoped>
.spaceL {
  margin-left: 1%;
}
</style>
