// API
import BaseApi from "./base";

class FuncionesApi extends BaseApi {
  constructor() {
    super("fnc");
  }

  file(id, carpeta, file) {
    return this.request.post(`/fnc/file/${carpeta}/${id}`, file);
  }

  deleteFile(carpeta, data, params = {}) {
    return this.request.post(`/fnc/file/${carpeta}/del`, data, {
      params: {
        ...params
      }
    });
  }

  generaProyecto(data) {
    return this.request.post(`/fnc/generaProyecto`, data, {
      params: {
        code: "-O1JMj79poryWUJaTCcjBgZRDOolI_8JtpuIMVWMRAauAzFuANdPIQ==",
      },
    });
  }

  generaKyc(data) {
    return this.request.post(`/fnc/generaKyc`, data);
  }

  mapIntersect(data, params = {}) {
    return this.request.post(`/fnc/mapIntersect`, data, {
      params: {
        ...params
      }
    });
  }

  procesos(estatus, params = {}) {
    return this.request.get(`/fnc/procesos/${estatus}`, {
      params: {
        ...params,
      }
    });
  }

  clonDecision(comparecientes_id, params = {}) {
    return this.request.get(
      `/fnc/clonDecision/compareciente/${comparecientes_id}`, {
      params: {
        ...params
      }
    });
  }

  sendKycsFad(data) {
    return this.request.post(`/fnc/sendKycsFad`, data);
  }

  checkFadKycsEstatus(data) {
    return this.request.post(`/fnc/checkFadKycsEstatus`, data);
  }

  getCatFadResponseRespuesta(cat_fad_responses_id) {
    return this.request.get(`/fnc/getCatFadResponseRespuesta/${cat_fad_responses_id}`);
  }

  payCotizacion(data) {
    return this.request.post(`/fnc/payCotizacion`, data);
  }

  getDescripcionPayError(error_code) {
    return this.request.get(`/fnc/payError/${error_code}`);
  }

  sendProyectoConstitutivaFad(data) {
    return this.request.post(`/fnc/sendProyectoConstitutivaFad`, data);
  }

  sendProyectoRatificacionesFad(data) {
    return this.request.post("/fnc/sendProyectoRatificacionesFad", data);
  }

  checkFadProyectoConstitutivaEstatus(data) {
    return this.request.post(`/fnc/checkFadProyectoConstitutivaEstatus`, data);
  }

  sendMail(data) {
    return this.request.post("/fnc/sendMail", data);
  }

  getCorreduriasDomicilios(params = {}) {
    return this.request.get(`/fnc/corredurias`, {
      params: {
        ...params
      }
    });
  }

  getSaldoCliente(clientes_id) {
    return this.request.get(`/fnc/saldoCliente/${clientes_id}`);
  }

  pagoConMonedero(data) {
    return this.request.post("/fnc/pagoConMonedero", data);
  }

  pagoConSpei(data) {
    return this.request.post("/fnc/payTransferenciaSpei", data);
  }

  sendLinkCalendario(data) {
    return this.request.post("/fnc/sendLinkCalendario", data);
  }

  getMunicipiosDeEntidad(cat_entidades_id) {
    return this.request.get(`/fnc/municipios/${cat_entidades_id}`);
  }

  descargarExpediente(catServicioId, procesoId) {
    return this.request.get(`/fnc/expediente/${catServicioId}/${procesoId}`, { responseType: 'blob' });
  }

  descargarDocumento(documentoId) {
    return this.request.get(`/fnc/documento/${documentoId}`, { responseType: 'blob' });
  }
}

export default new FuncionesApi();
