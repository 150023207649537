import { render, staticRenderFns } from "./TreeEditNotificaciones.vue?vue&type=template&id=330a978f&scoped=true"
import script from "./TreeEditNotificaciones.vue?vue&type=script&lang=js"
export * from "./TreeEditNotificaciones.vue?vue&type=script&lang=js"
import style0 from "./TreeEditNotificaciones.vue?vue&type=style&index=0&id=330a978f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "330a978f",
  null
  
)

export default component.exports