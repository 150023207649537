<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ path: '/dashboard' }">Inicio</b-breadcrumb-item>
      <b-breadcrumb-item active>Más información</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="container my-4">
      <!-- Descripción del servicio -->
      <section id="descripcion-servicio" class="mb-5">
        <h2 class="title">{{ datos.title }}</h2>
        <!-- <div>
          <p v-html="sanitizedHtmlContentDescription" />
        </div> -->
      </section>

      <!-- Video -->
      <section id="video" class="mb-4 text-center">
        <!-- <div class="embed-responsive embed-responsive-16by9">
          <video class="embed-responsive-item" controls>
            <source :src="video" type="video/mp4" />
            Tu navegador no soporta la reproducción de video.
          </video>
        </div> -->
        <ModalPreviewVideo :current-documento="videoObject"></ModalPreviewVideo>
      </section>

      <div class="container">
        <div class="row justify-content-center">
          <router-link :to="{ path: mapServiceToRoute }" class="route">
            <button class="btn orange-btn">
              Iniciar proceso
            </button>
          </router-link>
        </div>
      </div>

      <!-- Información requerida -->
      <!-- <section id="informacion-requerida" class="mb-4">
        <h2 class="title">Información requerida</h2>
        <p v-html="sanitizedHtmlContentInfo" />
      </section> -->
      <!-- Resultado o producto esperado -->
      <!-- <section id="resultado-esperado" class="mb-4">
        <h2 class="title">Resultado o producto esperado</h2>
        <p v-html="sanitizedHtmlContentResult" />
      </section> -->
    </div>
  </div>
</template>

<script>
import ModalPreviewVideo from '@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewVideo.vue';

import config from "@/config";
import DOMPurify from 'dompurify';

export default {
  name: "DescriptionServices",

  components: {
    ModalPreviewVideo
  },

  props: {
    datos: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },

  computed: {
    videoObject() {
      return {
        documento: config.STG_BASE_URL + this.datos.video + config.STG_ACCESS_KEY,
        autoplay: true,
        fileType: "video/webm",
        poster: "/img/cp_logooficial_fondoNEGRO.png"
      }
    },
    mapServiceToRoute() {
      let service = this.datos.title || "";

      switch (service) {
        case "Constitución de Sociedades":
          service = "addconstitucion";
          break;
        case "Formalización de Actas":
          service = "addformalizacion";
          break;
        case "Notificaciones":
          service = "addnotificacion";
          break;
        case "Copias Certificadas":
          service = "addcotejo";
          break;
        case "Fe de Hechos":
          service = "";
          break;
        case "Ratificación de Firmas":
          service = "addratificacion";
          break;
        case "Firma Digital de Documentos":
          service = "addratificaciondigital";
          break;
        case "Avalúos de mejoras":
          service = "";
          break;
        default:
          service = ""
          break;
      }

      return `/dashboard/procesos/${service}/`;
    },
    // video() {
    //   return this.datos.video || "";
    // },
    // sanitizedHtmlContentDescription() {
    //   return DOMPurify.sanitize(this.datos.description) || "";
    // },
    // sanitizedHtmlContentInfo() {
    //   return DOMPurify.sanitize(this.datos.information) || "";
    // },
    // sanitizedHtmlContentResult() {
    //   return DOMPurify.sanitize(this.datos.result) || "";
    // },
  }
};
</script>

<style>
.title {
  font-size: 2rem;
  font-weight: bold;
  color: #ea640b;
}
</style>
