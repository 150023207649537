<template>
  <div>
    <div v-if="user === 'Client'">

      <div class="row">
        <div class="col-12 text-center mb-3">
          <label>
            Entrega de información base
          </label>
        </div>
      </div>

      <div class="row flex align-items-center p-2 pointer">
        <div class="ml-3">
          <div class="circle completado">
            <span class="number">
              <i class="fa fa-check"></i>
            </span>
          </div>
        </div>
        <div class="ml-3">
          <span class="text-menu">
            Solicitantes
          </span>
        </div>
      </div>

      <div class="row flex align-items-center p-2 pointer">
        <div class="ml-3">
          <div class="circle completado">
            <span class="number">
              <i class="fa fa-check"></i>
            </span>
          </div>
        </div>
        <div class="ml-3">
          <span class="text-menu">
            Ubicación
          </span>
        </div>
      </div>

      <div class="row flex align-items-center p-2 pointer">
        <div class="ml-3">
          <div class="circle completado">
            <span class="number">
              <i class="fa fa-check"></i>
            </span>
          </div>
        </div>
        <div class="ml-3">
          <span class="text-menu">
            Documentos
          </span>
        </div>
      </div>

      <div class="row flex align-items-center p-2 pointer">
        <div class="ml-3">
          <div class="circle completado">
            <span class="number">
              <i class="fa fa-check"></i>
            </span>
          </div>
        </div>
        <div class="ml-3">
          <span class="text-menu">
            Cotización
          </span>
        </div>
      </div>

    </div>

    <template v-if="user === 'Staff'">
      <div>
        <div>
          <div class="row">
            <div class="col-12 text-center mb-3">
              <label>Valida la información</label>
            </div>
          </div>

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'Ubicacion' ? 'active-menu' : 'text-menu'"
                @click="selected('Ubicacion')">
                Ubicación
              </a>
            </div>

            <div class="mr-3">
              <div :class="['circle', notificacion.validaDomicilio ? 'completado' : '']">
                <span class="number">
                  <i v-if="notificacion.validaDomicilio" class="fa fa-check"></i>
                  <i v-else class="fa fa-minus"></i></span>
              </div>
            </div>
          </div>

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'Solicitantes' ? 'active-menu' : 'text-menu'"
                @click="selected('Solicitantes')">
                Solicitantes
              </a>
            </div>
            <div class="mr-3">
              <div :class="['circle', notificacion.validaSolicitantes ? 'completado' : '']">
                <span class="number">
                  <i v-if="notificacion.validaSolicitantes" class="fa fa-check"></i>
                  <i v-else class="fa fa-minus"></i>
                </span>
              </div>
            </div>
          </div>

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'Notificados' ? 'active-menu' : 'text-menu'"
                @click="selected('Notificados')">
                Notificados
              </a>
            </div>
            <div class="mr-3">
              <div :class="['circle', notificacion.validaNotificado ? 'completado' : '']">
                <span class="number">
                  <i v-if="notificacion.validaNotificado" class="fa fa-check"></i>
                  <i v-else class="fa fa-minus"></i>
                </span>
              </div>
            </div>
          </div>

          <div class="row flex align-items-center p-2 pointer justify-content-between">
            <div class="ml-3">
              <a :class="selectedComponent === 'Documentos' ? 'active-menu' : 'text-menu'"
                @click="selected('Documentos')">
                Documentos
              </a>
            </div>
            <div class="mr-3">
              <div :class="['circle', notificacion.validaDocumentos ? 'completado' : '']">
                <span class="number">
                  <i v-if="notificacion.validaDocumentos" class="fa fa-check"></i>
                  <i v-else class="fa fa-minus"></i></span>
              </div>
            </div>
          </div>

          <div class="row flex align-items-center p-2 pointer justify-content-between" v-if="notificacion.envio">
            <div class="ml-3">
              <a :class="selectedComponent === 'EnvioDomicilio' ? 'active-menu' : 'text-menu'"
                @click="selected('EnvioDomicilio')">
                Domicilio de Envio
              </a>
            </div>
            <div class="mr-3">
              <div :class="['circle', notificacion.validaEnvio ? 'completado' : '']">
                <span class="number">
                  <i v-if="notificacion.validaEnvio" class="fa fa-check"></i>
                  <i v-else class="fa fa-minus"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row flex align-items-center p-2 pointer justify-content-between">
        <div class="ml-3">
          <a :class="selectedComponent === 'Correduria' ? 'active-menu' : 'text-menu'" @click="selected('Correduria')">
            Asignar Correduria
          </a>
        </div>
        <div class="mr-3">
          <div :class="['circle', notificacion.corredurias_id ? 'completado' : '']">
            <span class="number">
              <i v-if="notificacion.corredurias_id" class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i>
            </span>
          </div>
        </div>
      </div>

      <div class="row flex align-items-center p-2 pointer justify-content-between">
        <div class="ml-3">
          <a :class="selectedComponent === 'Fad' ? 'active-menu' : 'text-menu'" @click="selected('Fad')">
            FAD
          </a>
        </div>
        <div class="mr-3">
          <div :class="['circle', [11, 12, 23].includes(notificacion.id_estatus) ? 'completado' : '']">
            <span class="number">
              <i v-if="[11, 12, 23].includes(notificacion.id_estatus)" class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i>
            </span>
          </div>
        </div>
      </div>

      <div class="col-12 text-center mb-3">
        <label>Diligencia</label>
      </div>

      <div class="row flex align-items-center p-2 pointer justify-content-between">
        <div class="ml-3">
          <a class="f12" :class="selectedComponent === 'Diligencia' ? 'active-menu' : 'text-menu'"
            @click="selected('Diligencia')">
            Gestión de Diligencia
          </a>
        </div>
        <div class="mr-3">
          <div :class="['circle', [12, 13, 23, 58].includes(notificacion.id_estatus) ? 'completado' : '']">
            <span class="number">
              <i v-if="[12, 13, 23, 58].includes(notificacion.id_estatus)" class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i>
            </span>
          </div>
        </div>
      </div>

      <div class="row flex align-items-center p-2 pointer justify-content-between">
        <div class="ml-3">
          <a :class="selectedComponent === 'Actas' ? 'active-menu' : 'text-menu'" @click="selected('Actas')">
            Actas
          </a>
        </div>
        <div class="mr-3">
          <div :class="['circle', (notificacion.id_estatus == 12 || notificacion.id_estatus == 13) ? 'completado' : '']">
            <span class="number">
              <i v-if="(notificacion.id_estatus == 12 || notificacion.id_estatus == 13)" class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i>
            </span>
          </div>
        </div>
      </div>

      <div class="row flex align-items-center p-2 pointer justify-content-between">
        <div class="ml-3">
          <a :class="selectedComponent === 'Expediente' ? 'active-menu' : 'text-menu'" @click="selected('Expediente')">
            Expediente
          </a>
        </div>
        <div class="mr-3">
          <div :class="['circle', notificacion.id_estatus == 13 ? 'completado' : '']">
            <span class="number">
              <i v-if="notificacion.id_estatus == 13" class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i>
            </span>
          </div>
        </div>
      </div>

      <div class="row flex align-items-center p-2 pointer justify-content-between">
        <div class="ml-3">
          <a :class="selectedComponent === 'Terminado' ? 'active-menu' : 'text-menu'" @click="selected('Terminado')">
            Terminado
          </a>
        </div>
        <div class="mr-3">
          <div :class="['circle', notificacion.id_estatus == 13 ? 'completado' : '']">
            <span class="number">
              <i v-if="notificacion.id_estatus == 13" class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i>
            </span>
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <div>
        <div class="row flex align-items-center p-2 pointer">
          <div class="ml-3">
            <div :class="['circle', notificacion.currentComponentValues.Pago ? 'completado' : '']">
              <span class="number">
                <i v-if="notificacion.currentComponentValues.Pago" class="fa fa-check"></i>
                <i v-else class="fa fa-minus"></i></span>
            </div>
          </div>
          <div class="ml-3">
            <a :class="selectedComponent === 'Pago' ? 'active-menu' : 'text-menu'" @click="selected('Pago')">
              Pago
            </a>
          </div>
        </div>

        <div class="row flex align-items-center p-2 justify-content-center">
          <div class="text-center">
            <label>Complementa la información</label>
          </div>
        </div>

        <!-- TODO: Checar este menu en donde se usa, agregarle los nuevos estilos  -->
        <div class="row flex align-items-center p-2 pointer" v-if="notificacion.envio == 1">
          <div class="ml-3">
            <div :class="['circle', notificacion.currentComponentValues.EnvioDomicilio ? 'completado' : '']">
              <span class="number">
                <i v-if="notificacion.currentComponentValues.EnvioDomicilio" class="fa fa-check"></i>
                <i v-else class="fa fa-minus"></i></span>
            </div>
          </div>
          <div class="ml-3">
            <a :class="selectedComponent === 'EnvioDomicilio' ? 'active-menu' : 'text-menu'"
              @click="selected('EnvioDomicilio')">
              Envío a Domicilio
            </a>
          </div>
        </div>

        <div class="row flex align-items-center p-2 pointer">
          <div class="ml-3">
            <div :class="['circle', notificacion.currentComponentValues.ComplementaNotificado ? 'completado' : '']">
              <span class="number">
                <i v-if="notificacion.currentComponentValues.ComplementaNotificado" class="fa fa-check"></i>
                <i v-else class="fa fa-minus"></i>
              </span>
            </div>
          </div>
          <div class="ml-3">
            <a :class="selectedComponent === 'ComplementaNotificado' ? 'active-menu' : 'text-menu'"
              @click="selected('ComplementaNotificado')">
              Notificado</a>
          </div>
        </div>

        <div class="row flex align-items-center p-2 pointer">
          <div class="ml-3">
            <div :class="['circle', notificacion.currentComponentValues.ComplementaSolicitantes ? 'completado' : '']">
              <span class="number">
                <i v-if="notificacion.currentComponentValues.ComplementaSolicitantes" class="fa fa-check"></i>
                <i v-else class="fa fa-minus"></i>
              </span>
            </div>
          </div>
          <div class="ml-3">
            <a :class="selectedComponent === 'ComplementaSolicitantes' ? 'active-menu' : 'text-menu'"
              @click="selected('ComplementaSolicitantes')">
              Solicitantes</a>
          </div>
        </div>

        <div class="row flex align-items-center p-2 pointer">
          <div class="ml-3">
            <div :class="['circle', notificacion.currentComponentValues.EntregaDocumentos ? 'completado' : '']">
              <span class="number">
                <i v-if="notificacion.currentComponentValues.EntregaDocumentos" class="fa fa-check"></i>
                <i v-else class="fa fa-minus"></i></span>
            </div>
          </div>
          <div class="ml-3">
            <a :class="selectedComponent === 'EntregaDocumentos' ? 'active-menu' : 'text-menu'"
              @click="selected('EntregaDocumentos')">
              Entrega de documentos
            </a>
          </div>
        </div>
        <!--
      <div class="row">
        <div class="col-10">
          <b-button
            block
            class="btn-fill blue-btn"
            @click="selected('FirmaDocumentos')"
          >
            Firma de documentos
          </b-button>
        </div>
        <div class="col-1">
          <div v-if="notificacion.currentComponentValues.FirmaDocumentos" class="led-green"></div>
          <div v-else class="led-yellow"></div>
        </div>
      </div>
      -->
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "TreeNotificaciones",

  props: {
    notificacion: { type: Object },
    user: { type: String },
    selectedComponent: { type: String }
  },

  methods: {
    selected(node) {
      this.$emit("node", node);
    }
  }
};
</script>

<style scoped>
.invi {
  display: none !important;
  ;
}

.completado {
  background-color: yellowgreen !important;
}

.icon-title {
  height: 20px;
  width: 20px;
  margin-top: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.icon-title-fa {
  font-size: 11px;
  color: black;
}

.number {
  font-size: 11px;
  color: white;
}

.title-text {
  font-weight: 600;
  color: black !important;

}

.complete-text {

  color: black !important;
}

.blue-btn {

  padding-left: 0px;
  padding-right: 0px;
  background-color: transparent !important;
  border-color: transparent !important;
  color: rgb(154, 154, 154);
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: -10px;
  text-align: left !important;

}

.blue-btn:hover {
  color: rgb(154, 154, 154);
  background-color: transparent !important;
  border-color: transparent !important;
  background-color: #effbff !important;
  border-color: #effbff !important;
}

.blue-btn:active {
  background-color: #effbff !important;
  border-color: #effbff !important;

}


.led-green {
  margin: 10px auto;
  width: 10px;
  height: 10px;
  background-color: #43eb34;
  border-radius: 50%;
  box-shadow: #257a1d 0 -1px 7px 1px, inset #460 0 -1px 9px, #7d0 0 2px 12px;
}

.led-red {
  margin: 10px auto;
  width: 10px;
  height: 10px;
  background-color: #e60505;
  border-radius: 50%;
  box-shadow: #a60903 0 -1px 7px 1px, inset #600 0 -1px 9px, #f00 0 2px 12px;
}

.led-yellow {
  margin: 10px auto;
  width: 10px;
  height: 10px;
  background-color: #f7e00a;
  border-radius: 50%;
  box-shadow: #d1ca04 0 -1px 7px 1px, inset #c4b206 0 -1px 9px, #dd0 0 2px 12px;
}
</style>
