<template>
  <div class="content">
    <div class="container-fluid">
      <!-- <h3 slot="header" class="card-title">Nuevo proceso</h3> -->
      <!-- <b-breadcrumb>
        <b-breadcrumb-item active>Nuevo proceso</b-breadcrumb-item>
      </b-breadcrumb> -->

      <div class="row">
        <div class="col-md-3 services">
          <b-card class="cardServicio">
            <template slot="header">
              <h4 class="card-title">Constitución de sociedades</h4>
            </template>
            <div class="text-center long">
              <img :src="urlTest" class="imgCard" />
            </div>
            <template slot="footer">
              <hr />
              <div class="stats">
                <router-link :to="{ name: 'InformationInDashboard', params: { type: 'constitucion-sociedades' } }">
                  Más información
                </router-link>
                <br />
                <router-link :to="{ path: '/dashboard/procesos/addconstitucion/' }" class="route">
                  Iniciar proceso
                </router-link>
              </div>
            </template>
          </b-card>
        </div>
        <div class="col-md-3 services">
          <b-card class="cardServicio">
            <template slot="header">
              <h4 class="card-title">Notificaciones</h4>
            </template>
            <div class="text-center long">
              <img src="/img/designed/notifs.PNG" alt="" class="imgCard" />
            </div>
            <template slot="footer">
              <hr />
              <div class="stats">
                <router-link :to="{ name: 'InformationInDashboard', params: { type: 'notificaciones' } }">
                  Más información
                </router-link>
                <br />
                <router-link :to="{ path: '/dashboard/procesos/addnotificacion/' }" class="route">
                  Iniciar proceso
                </router-link>
              </div>
            </template>
          </b-card>
        </div>
        <div class="col-md-3 services">
          <card class="cardServicio">
            <template slot="header">
              <h4 class="card-title">Copias certificadas</h4>
            </template>
            <div class="text-center long">
              <img src="/img/designed/cotejoDocs.PNG" alt="" class="imgCard" />
            </div>
            <template slot="footer">
              <hr />
              <div class="stats">
                <router-link :to="{ name: 'InformationInDashboard', params: { type: 'copias-certificadas' } }">
                  Más información
                </router-link>
                <br />
                <!-- <router-link :to="{ path: '/dashboard/procesos/addcotejo/' }" class="route">
                  Iniciar proceso
                </router-link> -->
                <router-link :to="{ path: '/dashboard/proximamente' }" class="route">
                  Iniciar proceso
                </router-link>
              </div>
            </template>
          </card>
        </div>
        <div class="col-md-3 services">
          <card class="cardServicio">
            <template slot="header">
              <h4 class="card-title">Ratificación de firmas</h4>
            </template>
            <div class="text-center long">
              <img src="/img/designed/ratifFirmas.PNG" alt="" class="imgCard" />
            </div>
            <template slot="footer">
              <hr />
              <div class="stats">
                <router-link :to="{ name: 'InformationInDashboard', params: { type: 'ratificacion-firmas' } }">
                  Más información
                </router-link>
                <br />
                <!-- <router-link :to="{ path: '/dashboard/procesos/addratificacion/' }" class="route">
                  Iniciar proceso
                </router-link> -->
                <router-link :to="{ path: '/dashboard/proximamente' }" class="route">
                  Iniciar proceso
                </router-link>
              </div>
            </template>
          </card>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 services">
          <card class="cardServicio">
            <template slot="header">
              <h4 class="card-title">Firma Digital de Documentos</h4>
            </template>
            <div class="text-center long">
              <img src="/img/designed/ratifFirmasDigital.png" alt="" class="imgCard" />
            </div>
            <template slot="footer">
              <hr />
              <div class="stats">
                <router-link :to="{ name: 'InformationInDashboard', params: { type: 'ratificacion-firmas-digital' } }">
                  Más información
                </router-link>
                <br />
                <!-- <router-link :to="{ path: '/dashboard/procesos/addratificaciondigital/' }" class="route">
                  Iniciar proceso
                </router-link> -->
                <router-link :to="{ path: '/dashboard/proximamente' }" class="route">
                  Iniciar proceso
                </router-link>
              </div>
            </template>
          </card>
        </div>
        <div class="col-md-3 services">
          <card class="cardServicio">
            <template slot="header">
              <h4 class="card-title">Formalización de actas</h4>
            </template>
            <div class="text-center long">
              <img src="/img/designed/formActas.PNG" alt="" class="imgCard" />
            </div>
            <template slot="footer">
              <hr />
              <div class="stats">
                <router-link :to="{ name: 'InformationInDashboard', params: { type: 'formalizacion-actas' } }">
                  Más información
                </router-link>
                <br />
                <!-- <router-link :to="{ path: '/dashboard/procesos/addformalizacion/' }" class="route">
                  Iniciar proceso
                </router-link> -->
                <router-link :to="{ path: '/dashboard/proximamente' }" class="route">
                  Iniciar proceso
                </router-link>
              </div>
            </template>
          </card>
        </div>
        <div class="col-md-3 services">
          <card class="cardServicio">
            <template slot="header">
              <h4 class="card-title">Fes de hechos</h4>
            </template>
            <div class="text-center long">
              <img src="/img/designed/fesHechos.PNG" alt="" class="imgCard" />
            </div>
            <template slot="footer">
              <hr />
              <div class="stats">
                <!-- <router-link :to="{ name: 'InformationInDashboard', params: { type: 'fe-hechos' } }"> -->
                <router-link :to="{ path: '/dashboard/proximamente' }" class="route">
                  Más información
                </router-link>
                <br />
                <router-link :to="{ path: '/dashboard/proximamente' }" class="route">
                  Iniciar proceso
                </router-link>
              </div>
            </template>
          </card>
        </div>
        <div class="col-md-3 services">
          <card class="cardServicio">
            <template slot="header">
              <h4 class="card-title">Avalúos de mejoras</h4>
            </template>
            <div class="text-center long">
              <img src="/img/designed/avaluosMej.PNG" alt="" class="imgCard" />
            </div>
            <template slot="footer">
              <hr />
              <div class="stats">
                <!-- <router-link :to="{ name: 'InformationInDashboard', params: { type: 'avaluos' } }"> -->
                <router-link :to="{ path: '/dashboard/proximamente' }" class="route">
                  Más información
                </router-link>
                <br />
                <router-link :to="{ path: '/dashboard/proximamente' }" class="route">
                  Iniciar proceso
                </router-link>
              </div>
            </template>
          </card>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";

export default {
  name: "Overview",

  components: {
    Card
  },

  data() {
    return {
      urlTest: "/img/designed/constSocie.PNG",
    }
  }
};
</script>

<style>
.imgCard {
  max-width: 100%;
  max-height: 100%;
}

.long {
  height: 20vh;
}

.cardServicio:hover {
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  /* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 4px 16px;
}

.cardServicio {
  height: 100%;
}

.services {
  height: 40vh;
  margin-bottom: 4%;
}

.route {
  color: #ea640b !important;
}

.route:hover {
  color: #6c757d !important;
}
</style>
