<template>
  <div class="container">
    <b-modal no-close-on-backdrop centered ref="modal-confirm-fad" hide-footer id="modal-confirm-fad">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>

      <h4 class="text-center">
        ¿Está seguro de enviar a FAD? El cliente será notificado.
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-fad')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="sendToFad()">OK</b-button>
    </b-modal>

    <h4 class="cardD">Firma Digital</h4>

    <div class="mt-4">
      <div class="row ">
        <div class="col-12">
          <b-button type="button" block class="btn-fill orange-btn bottom2" size="m" @click="openSendToFadModal()"
            :disabled="notificacion.id_estatus == 11 || notificacion.id_estatus == 12 || notificacion.id_estatus == 13
              || notificacion.id_estatus == 23">
            Enviar a FAD
          </b-button>
        </div>
      </div>
    </div>

    <div class="mt-4" v-if="notificacion.staff">
      <div class="">
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m" :disabled="notificacion.id_estatus == 11
          || notificacion.id_estatus == 12 || notificacion.id_estatus == 13 || notificacion.id_estatus == 23">
          Verificar firma
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { FuncionesApi } from "@/api";

export default {
  name: "Fad",

  created() {
  },

  props: {
    notificacion: { type: Object },
  },

  data() {
    return {
      loading: false,
    }
  },

  methods: {
    async sendToFad() {
      try {
        this.loading = true;

        const dataFad = {
          proceso_id: this.notificacion.id,
          cat_servicios_id: 2
        }

        await FuncionesApi.sendKycsFad(dataFad);

        this.$toast.success("Documentos enviados a firma", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } catch (error) {
        console.log(error);

        this.$toast.error("Sucedió un error con el servicio de FAD.", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });

      } finally {
        this.hideSendToFadModal();
        this.loading = false;
      }
    },

    openSendToFadModal() {
      this.$refs["modal-confirm-fad"].show();
    },

    hideSendToFadModal() {
      this.$refs["modal-confirm-fad"].hide();
    },
  }
}
</script>
