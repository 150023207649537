var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"catDocumentos_id","rules":"required","custom-messages":{
        required: 'El tipo de documento es obligatorio',
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{attrs:{"for":""}},[_vm._v("Tipo de documento")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.currentDocumento.catDocumentos_id),expression:"currentDocumento.catDocumentos_id"}],staticClass:"custom-select",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.currentDocumento, "catDocumentos_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.options_principal_documento),function(option){return _c('option',{key:option.value,domProps:{"value":option.value}},[_vm._v(" "+_vm._s(option.text)+" ")])}),0),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('br')],1)]),(_vm.currentDocumento.catDocumentos_id == _vm.id_otro)?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"descripcion","rules":"required","custom-messages":{
        required: 'La descripción del documento es obligatoria',
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"text","label":"Descripción","placeholder":"Descripción"},model:{value:(_vm.currentDocumento.descripcion),callback:function ($$v) {_vm.$set(_vm.currentDocumento, "descripcion", $$v)},expression:"currentDocumento.descripcion"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2706138744)})],1)]):_vm._e(),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"descripcion","rules":"required","custom-messages":{
        required: 'La cantidad de copias es obligatoria',
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{attrs:{"for":""}},[_vm._v("Número de copias certificadas")]),_c('base-input',{staticClass:"custom-number",attrs:{"type":"number","required":"","placeholder":"1","min":0},model:{value:(_vm.currentDocumento.copias_certificadas),callback:function ($$v) {_vm.$set(_vm.currentDocumento, "copias_certificadas", $$v)},expression:"currentDocumento.copias_certificadas"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"descripcion","rules":"required","custom-messages":{
        required: 'La cantidad de páginas es obligatoria',
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{attrs:{"for":""}},[_vm._v("Número de páginas del documento sin anexos")]),_c('base-input',{staticClass:"custom-number",attrs:{"type":"number","required":"","placeholder":"1","min":0},model:{value:(_vm.currentDocumento.paginas),callback:function ($$v) {_vm.$set(_vm.currentDocumento, "paginas", $$v)},expression:"currentDocumento.paginas"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"anexo"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-form-checkbox',{model:{value:(_vm.currentDocumento.tiene_anexo),callback:function ($$v) {_vm.$set(_vm.currentDocumento, "tiene_anexo", $$v)},expression:"currentDocumento.tiene_anexo"}},[_c('span',[_vm._v("El documento contiene anexo(s)")])]),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),(_vm.currentDocumento.tiene_anexo)?_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"descripcion","rules":"required","custom-messages":{
        required: 'La cantidad de páginas es obligatoria',
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{attrs:{"for":""}},[_vm._v("Número total de páginas de todos los anexos")]),_c('base-input',{staticClass:"custom-number",attrs:{"type":"number","required":"","placeholder":"1","min":1},model:{value:(_vm.currentDocumento.paginas_anexo),callback:function ($$v) {_vm.$set(_vm.currentDocumento, "paginas_anexo", $$v)},expression:"currentDocumento.paginas_anexo"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2264327751)})],1)]):_vm._e(),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4 text-center"},[_c('button',{staticClass:"btn btn-fill float-right orange-btn",attrs:{"type":"submit"}},[_vm._v(" Guardar ")])])
}]

export { render, staticRenderFns }