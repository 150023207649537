<template>
  <div>
    <div v-if="user === 'Client'">
      <div class="row">
        <div class="col-12 text-center mb-3">
          <label>Entrega de información base</label>
        </div>
      </div>


      <div class="row flex align-items-center p-2 pointer">
        <div class="ml-3">
          <div :class="['circle', notificacion.currentComponentValues.Solicitantes ? 'completado' : '']">
            <span class="number">
              <i v-if="notificacion.currentComponentValues.Solicitantes" class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i>
            </span>
          </div>
        </div>
        <div class="ml-3">
          <a :class="selectedComponent === 'Solicitantes' ? 'active-menu' : 'text-menu'"
            @click="selected('Solicitantes')">
            Solicitantes
          </a>
        </div>
      </div>


      <div class="row flex align-items-center p-2 pointer">
        <div class="ml-3">
          <div :class="['circle', notificacion.currentComponentValues.Ubicacion ? 'completado' : '']">
            <span class="number">
              <i v-if="notificacion.currentComponentValues.Ubicacion" class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i>
            </span>
          </div>
        </div>
        <div class="ml-3">
          <a :class="selectedComponent === 'Ubicacion' ? 'active-menu' : 'text-menu'" @click="selected('Ubicacion')">
            Ubicación
          </a>
        </div>
      </div>

      <div class="row flex align-items-center p-2 pointer">
        <div class="ml-3">
          <div :class="['circle', notificacion.currentComponentValues.Documentos ? 'completado' : '']">
            <span class="number">
              <i v-if="notificacion.currentComponentValues.Documentos" class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i>
            </span>
          </div>
        </div>
        <div class="ml-3">
          <a :class="selectedComponent === 'Documentos' ? 'active-menu' : 'text-menu'" @click="selected('Documentos')">
            Documentos
          </a>
        </div>
      </div>

      <div class="row flex align-items-center p-2 pointer">
        <div class="ml-3">
          <div :class="['circle', notificacion.currentComponentValues.Cotizacion ? 'completado' : '']">
            <span class="number">
              <i v-if="notificacion.currentComponentValues.Cotizacion" class="fa fa-check"></i>
              <i v-else class="fa fa-minus"></i>
            </span>
          </div>
        </div>
        <div class="ml-3">
          <a :class="selectedComponent === 'Cotizacion' ? 'active-menu' : 'text-menu'" @click="selected('Cotizacion')">
            Cotización
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "TreeNotificaciones",

  props: {
    notificacion: { type: Object },
    user: { type: String },
    selectedComponent: { type: String }
  },

  methods: {
    selected(node) {
      this.$emit("node", node);
    }
  }
};
</script>

<style scoped>
.completado {
  background-color: yellowgreen !important;
}

.icon-title {
  height: 20px;
  width: 20px;
  margin-top: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.icon-title-fa {
  font-size: 11px;
  color: black;
}

.number {
  font-size: 11px;
  color: white;
}

.title-text {
  font-weight: 600;
  color: black !important;

}

.complete-text {
  color: black !important;
}

.blue-btn {
  padding-left: 0px;
  padding-right: 0px;
  background-color: transparent !important;
  border-color: transparent !important;
  color: rgb(154, 154, 154);
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: -10px;
  text-align: left !important;

}

.blue-btn:hover {
  color: rgb(154, 154, 154);
  background-color: transparent !important;
  border-color: transparent !important;
  background-color: #effbff !important;
  border-color: #effbff !important;
}

.blue-btn:active {
  background-color: #effbff !important;
  border-color: #effbff !important;

}


.led-green {
  margin: 10px auto;
  width: 10px;
  height: 10px;
  background-color: #43eb34;
  border-radius: 50%;
  box-shadow: #257a1d 0 -1px 7px 1px, inset #460 0 -1px 9px, #7d0 0 2px 12px;
}

.led-red {
  margin: 10px auto;
  width: 10px;
  height: 10px;
  background-color: #e60505;
  border-radius: 50%;
  box-shadow: #a60903 0 -1px 7px 1px, inset #600 0 -1px 9px, #f00 0 2px 12px;
}

.led-yellow {
  margin: 10px auto;
  width: 10px;
  height: 10px;
  background-color: #f7e00a;
  border-radius: 50%;
  box-shadow: #d1ca04 0 -1px 7px 1px, inset #c4b206 0 -1px 9px, #dd0 0 2px 12px;
}
</style>
