<template>
  <div class="container">
    <b-modal no-close-on-backdrop id="confirmar-facturacion-modal" ref="confirmar-facturacion-modal" centered
      hide-footer>
      <h4 class="text-center">¿Desea facturar su pago?</h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="confirmGenerarFactura(false)">No en este momento</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="confirmGenerarFactura(true)">Sí</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop id="datos-fiscales-modal" ref="datos-fiscales-modal" centered hide-footer hide-header
      size="lg" @close.prevent>
      <FacturacionComponent :datosFacturacion="datosFacturacion" @closedFacturacionEvent="closedFacturacion($event)"
        @canceledFacturacionEvent="closedFacturacion($event)" />
    </b-modal>

    <h4 class="cardD">Pago</h4>

    <b-tabs fill>
      <b-tab title="Tarjeta de crédito o débito" active>
        <Openpay :cotizacion_id="cotejo.id_cotizaciones" :precio_total="cotejo.precio_total" @submit.prevent
          @payCotizacion="payCotizacion($event)" />
      </b-tab>
      <b-tab title="SPEI/Transferencia" class="modalPay">
        <Spei :cotizacion_id="cotejo.id_cotizaciones" :precio_total="cotejo.precio_total" :proceso_id="cotejo.id"
          :tipo="'cotejo'" @submit.prevent @payCotizacionSpei="payCotizacionSpei($event)" />
      </b-tab>
      <b-tab title="Monedero" class="modalPay">
        <Monedero :cotizacion_id="cotejo.id_cotizaciones" :precio_total="cotejo.precio_total"
          :clientes_id="cotejo.id_clientes" :servicio="'Cotejo ' + cotejo.id" @submit.prevent
          @payCotizacion="payCotizacionMonedero($event)" />
      </b-tab>
    </b-tabs>

    <!-- <div>
      <p>
        Su pago será procesado a través de <a href="https://www.openpay.mx" target="_blank">Openpay</a>, una plataforma
        segura y confiable para realizar transacciones electrónicas.
        <a href="https://www.openpay.mx" target="_blank">Openpay</a> garantiza la protección de sus datos y la
        integridad de la transacción. Para más información sobre Openpay y sus servicios, puede visitar su
        <a href="https://www.openpay.mx/servicios" target="_blank">página de servicios</a> o leer más sobre su
        <a href="https://www.openpay.mx/nosotros" target="_blank">empresa</a>.
      </p>
      <div>
        <div class="">
          <b-button class="btn-fill orange-btn bottom2" size="m" @click="openPagoModal()">
            Continuar al pago
          </b-button>
        </div>
      </div>
    </div>
    <div>
      <div v-if="forma_pago == 2">
        <p>
          Se envío un correo con la información de pago a la dirección de correo electrónico registrada en el sistema.
          Por favor revise su bandeja de entrada y siga las instrucciones para completar el pago.
        </p>
      </div>
    </div> -->
  </div>
</template>

<script>
import Monedero from "@/views/openpay/Monedero.vue";
import Openpay from "@/views/openpay/Openpay.vue";
import Spei from "@/views/openpay/Spei.vue";
import FacturacionComponent from "../../../openpay/FacturacionComponent.vue";

import { CotejosApi } from "@/api";
import loadingMixin from "@/mixins/loadingMixin";

export default ({
  name: "PagoCopiasCertificadasComponent",

  components: {
    Openpay,
    Spei,
    Monedero,
    FacturacionComponent
  },

  mixins: [loadingMixin],

  props: {
    cotejo: { type: Object },
    forma_pago: { type: Number, default: null },
  },

  created() {
  },

  data() {
    return {
      datosFacturacion: {
        cotizacionId: 0,
        alreadyInvoiced: false,
      },
    }
  },

  methods: {
    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    openPagoModal() {
      this.$refs['pago-modal'].show()
    },

    async payCotizacion(payed) {
      if (payed === true) {
        this.toggleOnLoading();
        this.generaPago()
          .then(() => this.emitPago())
          .catch((error) => {
            this.showErrorToast('Sucedió un error, intente más tarde');
            console.log(error);
          })
          .finally(this.toggleOffLoading);
      }
    },

    async payCotizacionMonedero(payed) {
      if (payed === true) {
        this.toggleOnLoading();
        this.generaPago()
          .then(() => this.emitPago())
          .catch((error) => {
            this.showErrorToast('Sucedió un error, intente más tarde');
            console.log(error);
          })
          .finally(this.toggleOffLoading);
      }
    },

    async payCotizacionSpei(payed) {
      if (payed === true) {
        this.toggleOnLoading();
        this.$toast.warning("Recibirá la información para la transferencia en su correo electrónico", {
          timeout: 10000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } else {
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        this.toggleOffLoading();
      }
    },

    async generaPago() {
      const estatus = this.cotejo.envio_domicilio === 1 ? 44 : 15;
      this.cotejo.id_estatus = estatus;

      delete this.cotejo.actualizado;

      this.cotejo = {
        ...this.cotejo,
        ...await CotejosApi.edit(this.cotejo.id, this.cotejo)
      };
    },

    emitPago(esPagoPorMonedero = false) {

      if (esPagoPorMonedero) {
        this.$emit('allChecksPassed');

        return;
      }

      this.$refs['confirmar-facturacion-modal'].show();
    },

    confirmGenerarFactura(crearFactura) {
      this.$refs['confirmar-facturacion-modal'].hide();

      if (!crearFactura) {
        this.$emit('allChecksPassed');

        return;
      }

      this.datosFacturacion.cotizacionId = this.cotejo.id_cotizaciones;

      this.$refs["datos-fiscales-modal"].show();
    },

    closedFacturacion(event) {
      this.$emit('allChecksPassed');
    },
  }
})
</script>
