<template>
  <div class="container">
    <h4 class="cardD">Complementa Información</h4>

    <div>
      <b-table ref="table" hover :fields="fieldsCuadro" :items="cotejo.solicitantes">
        <template #cell(acciones)="{ item }">
          <b-button v-if="cotejo.validaSolicitantes != 1" variant="warning" size="sm" v-b-tooltip.hover title="Editar"
            class="editBtn" @click="editCompareciente(item)">
            <b-icon-pencil />
          </b-button>
        </template>
      </b-table>
    </div>

    <div class="mt-4">
      <div class="">
        <b-button class="btn-fill orange-btn bottom2" size="m" @click="checkComparecientes()"
          :disabled="cotejo.validaSolicitantes == 1">Continuar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default ({
  name: "ComplementaSolicitante",

  components: {
  },

  props: {
    cotejo: { type: Object }
  },

  computed: {
    fieldsCuadro() {
      return [
        { key: "index", label: "", class: "text-center" },
        { key: "nombreCompleto", label: "Nombre", class: "text-center" },
        { key: "persona", label: "Persona", class: "text-center" },
        // { key: "representante", label: "Representante", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" },
      ];
    }
  },

  data() {
    return {
      currentCompareciente: null
    }
  },

  methods: {
    editCompareciente(item) {
      this.$router.push({
        path: `/dashboard/comparecientes/editcomparecientes/${item.id}`,
        query: {
          proceso: 4,
          procesoId: this.cotejo.id,
          accion: "editcotejo",
        },
      });
    },

    checkComparecientes() {
      this.$emit('allChecksPassed');
    }
  }
})
</script>
