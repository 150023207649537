<template>
  <div>
    <!-- <card class="cardErrors"> -->
    <!-- <h4 slot="header" class="card-title">Editar usuario</h4> -->
    <!-- <form> -->

    <b-modal no-close-on-backdrop centered hide-footer hide-header-close ref="modal-confirm-eliminar-usuarios-secundarios"
      id="modal-confirm-eliminar-usuarios-secundarios">
      <h4 class="text-center">¿Seguro que quiere eliminar los usuarios secundarios?</h4>
      <h5 class="text-center">Se eliminarán los datos de los usuarios secundarios al guardar los cambios.</h5>
      <b-button class="mt-3" variant="danger"
        @click="mayDeleteUsuariosSecundarios(false)">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="mayDeleteUsuariosSecundarios(true)">OK</b-button>
    </b-modal>

    <card>
      <div class="row">
        <div class="col-md-4">
          <ValidationProvider name="firstName" rules="required" v-slot="{ errors }"
            :custom-messages="{ required: 'El nombre es obligatorio' }">
            <base-input type="text" label="Nombre(s)" placeholder="Nombre(s)" v-model="user.firstName">
            </base-input>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-md-4">
          <ValidationProvider name="lastNameP" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'El apellido paterno es obligatorio',
          }">
            <base-input type="text" label="Apellido Paterno" placeholder="Apellido Paterno" v-model="user.lastNameP">
            </base-input>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-md-4">
          <!-- <ValidationProvider
            name="lastNameM"
            rules="required"
            v-slot="{ errors }"
            :custom-messages="{
              required: 'El apellido materno es obligatorio',
            }"
          > -->
          <base-input type="text" label="Apellido Materno" placeholder="Apellido Materno" v-model="user.lastNameM">
          </base-input>
          <!-- <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider> -->
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <ValidationProvider name="nombre_comercial" rules="required" v-slot="{ errors }"
            :custom-messages="{ required: 'El nombre comercial es obligatorio. Es su nombre completo si es persona Física.' }">
            <base-input type="text" label="Nombre comercial" placeholder="Nombre comercial"
              v-model="user.nombre_comercial">
            </base-input>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-md-6">
          <ValidationProvider name="email" rules="required|email" v-slot="{ errors }" :custom-messages="{
            required: 'El email es obligatorio',
            email: 'Debe ser un email válido',
          }">
            <base-input type="email" label="Email" placeholder="Email" v-model="user.email">
            </base-input>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>

      <!-- <div class="col-md-6">
        <ValidationProvider
          name="telefono"
          rules="required|length:10|numeric"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El teléfono es obligatorio',
            length: 'El número debe tener 10 dígitos',
            numeric: 'Solo puede contener números',
          }"
        >
          <base-input
            type="number"
            label="Teléfono"
            placeholder="Teléfono o celular"
            v-model="user.telefono"
          >
          </base-input>
         <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div> -->

      <div class="row">
        <div class="col-md-4">
          <ValidationProvider name="rol" rules="required" v-slot="{ errors }"
            :custom-messages="{ required: 'El rol es obligatorio' }">
            <label for="">Rol</label>
            <select disabled v-model="user.rol" class="custom-select">
              <option value="Cliente Administrador">
                Cliente Administrador
              </option>
              <option value="Cliente Usuario">Cliente Usuario</option>
            </select>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-md-4">
          <ValidationProvider name="rfc" rules="required|length:13" v-slot="{ errors }" :custom-messages="{
            required: 'El RFC es obligatorio',
            length: 'Debe tener 13 caracteres',
          }">
            <base-input type="text" label="RFC" placeholder="RFC" v-model="user.rfc">
            </base-input>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-md-4" v-if="this.roleUsr === 'Cliente Administrador'">
          <ValidationProvider name="persona" rules="required" v-slot="{ errors }"
            :custom-messages="{ required: 'La persona es obligatoria' }">
            <label for="">Agregar usuarios secundarios</label>
            <select v-model="user.persona" class="custom-select">
              <option value="Física">No</option>
              <option value="Moral">Si</option>
            </select>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </card>

    <card>
      <div v-if="user.persona === 'Moral'">
        <h4 class="cardD">
          <!-- Datos de usuarios -->

          <div v-if="this.roleUsr === 'Cliente'">
            <div v-if="
              second_users.length < 3 &&
              user.rol === 'Cliente Administrador' &&
              user.persona === 'Moral'
            ">
              Datos de usuarios
              <b-button variant="success" class="btn-fill" size="sm" @click="showFormDialog('x')">+</b-button>
            </div>
            <div v-else>Datos de usuarios</div>
          </div>

          <div v-else-if="this.roleUsr === 'Staff'">
            Datos de usuarios
            <b-button variant="success" class="btn-fill" size="sm" @click="showFormDialog('x')">+</b-button>
          </div>

          <!-- <div >
             <b-button
            variant="success"
            class="btn-fill"
            size="sm"
            @click="showFormDialog"
            >+</b-button
          >
          </div> -->
        </h4>
        <!--
        <div v-for="item in second_users" :key="item.id">
          <div class="mb-4">
            <div class="row">
              <div class="col-md-12 text-right">
                <b-button
                  variant="danger"
                  size="sm"
                  v-b-tooltip.hover
                  title="Eliminar"
                  @click="predeleteUsuarioSecundario(item.id)"
                >
                  <b-icon-trash />
                </b-button>
              </div>
              <div class="col-md-4">
                <ValidationProvider
                  name="nombre"
                  rules="required"
                  v-slot="{ errors }"
                  :custom-messages="{ required: 'El nombre es obligatorio' }"
                >
                  <base-input
                    type="text"
                    label="Nombre(s)"
                    placeholder="Nombre(s)"
                    v-model="item.nombre"
                  >
                  </base-input>
                  <span class="cardErrors">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-md-4">
                <ValidationProvider
                  name="paterno"
                  rules="required"
                  v-slot="{ errors }"
                  :custom-messages="{
                    required: 'El apellido paterno es obligatorio',
                  }"
                >
                  <base-input
                    type="text"
                    label="Apellido Paterno"
                    placeholder="Apellido Paterno"
                    v-model="item.paterno"
                  >
                  </base-input>
                  <span class="cardErrors">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-md-4">
                <ValidationProvider
                  name="materno"
                  rules="required"
                  v-slot="{ errors }"
                  :custom-messages="{
                    required: 'El apellido materno es obligatorio',
                  }"
                >
                  <base-input
                    type="text"
                    label="Apellido Materno"
                    placeholder="Apellido Materno"
                    v-model="item.materno"
                  >
                  </base-input>
                  <span class="cardErrors">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <ValidationProvider
                  name="email"
                  rules="required|email"
                  v-slot="{ errors }"
                  :custom-messages="{
                    required: 'El email es obligatorio',
                    email: 'Debe ser un email válido',
                  }"
                >
                  <base-input
                    type="email"
                    label="Email"
                    placeholder="Email"
                    v-model="item.email"
                  >
                  </base-input>
                  <span class="cardErrors">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider
                  name="rol"
                  rules="required"
                  v-slot="{ errors }"
                  :custom-messages="{
                    required: 'El rol es obligatorio',
                  }"
                >
                  <label for="">Rol</label>
                  <select disabled v-model="item.rol" class="custom-select">
                    <option value="Cliente Administrador">
                      Cliente Administrador
                    </option>
                    <option value="Cliente Usuario">Cliente Usuario</option>
                  </select>
                  <span class="cardErrors">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div> -->
        <span class="warningData">Los usuarios deberan aceptar la invitacion antes de aparecer en el listado</span>
        <b-table :fields="fieldsSecondUsers" :items="second_users" hover>
          <template #cell(index)="{ index }">
            {{ getIndex(index + 1) }}
          </template>
          <template #cell(acciones)="{ item }">
            <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn"
              @click="editUsuarioSecundario(item)">
              <b-icon-pencil />
            </b-button>
            <b-button variant="danger" @click="predeleteUsuarioSecundario(item.id)" size="sm" v-b-tooltip.hover
              title="Eliminar">
              <b-icon-trash />
            </b-button>
          </template>
        </b-table>
      </div>
    </card>

    <div>
      <b-button type="submit" class="btn-fill orange-btn bottom" size="lg">Guardar</b-button>
    </div>
    <!-- <div class="clearfix"></div> -->
    <!-- </card> -->
  </div>
</template>
<script>
import Card from "src/components/Cards/Card.vue";
import Storage from "@/utils/storage";
const storage = new Storage();

export default {
  components: {
    Card,
  },

  computed: {
    fieldsSecondUsers() {
      let fieldsSecondUsers = [
        // { key: "index", label: "", class: "text-center" },
        { key: "nombre", label: "Nombre(s)", class: "text-center" },
        { key: "paterno", label: "Apellido paterno", class: "text-center" },
        { key: "materno", label: "Apellido materno", class: "text-center" },
        { key: "email", label: "Email", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" },
      ];
      return fieldsSecondUsers;
    },
  },

  props: {
    user: { type: Object },
    empresa: { type: Object },
    second_users: { type: Array }, // Array//[Object],
  },

  watch: {
    "user.rfc": function (value) {
      if (!value) {
        this.user.rfc = "";

        return;
      }

      this.user.rfc = this.user.rfc.toUpperCase();
    },

    "user.persona": function (val, oldVal) {
      this.lastTipoPersona = oldVal;

      if (oldVal === "Moral" && val === "Física") {
        this.$refs["modal-confirm-eliminar-usuarios-secundarios"].show();
      }
    },
  },

  data() {
    return {
      roleUsr: null,

      form: {
        //user
        firstName: null,
        lastNameP: null,
        lastNameM: null,
        email: null,
        password: null,
        rol: null, //"Cliente Administrador"
        roles_id: null,
        telefono: null,
        persona: null,
        rfc: null,
        admin_id: null,
        admin_cliente_nombre: null,
        nombre_comercial: null,

        nombre: null,
        rfc: null,

        second_users: [
          // {
          //   firstName: null,
          //   lastNameP: null,
          //   lastNameM: null,
          //   email: null,
          //   password: null,
          //   rol: null,
          //   telefono: null,
          // },
        ],
      },

      lastTipoPersona: "",
    };
  },

  created() {
    this.getRoleUsr();
  },

  methods: {
    getIndex(index) {
      // return index + (1 - 1) * this.second_users.length;
      return index + (this.currentPage - 1) * this.perPage;
    },

    getRoleUsr() {
      var role = storage.getItem("role");
      if (role === "Staff Administrador" || role === "Staff Usuario") {
        role = "Staff";
      } else if (
        role === "Cliente Administrador" ||
        role === "Cliente Usuario"
      ) {
        role = "Cliente";
      }
      this.roleUsr = role;
    },

    predeleteUsuarioSecundario(id) {
      this.$emit("id", id);
    },

    showFormDialog(dialog) {
      this.$emit("dialog", dialog);
    },

    editUsuarioSecundario(usuariosecundario) {
      this.$emit("usuariosecundario", usuariosecundario);
    },

    mayDeleteUsuariosSecundarios(shouldDelete) {
      if (!shouldDelete) {
        this.user.persona = this.lastTipoPersona;
      }

      this.$refs["modal-confirm-eliminar-usuarios-secundarios"].hide();
    },
  },
};
</script>

<style scoped>
.bottom {
  position: fixed;
  right: 2%;
  bottom: 5%;
  overflow: visible;
  z-index: 100;
}
</style>
