var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"password","rules":{ required: true, min: 8, max: 20, regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).+$/ },"custom-messages":{
          required: 'La contraseña es obligatoria',
          min: 'La contraseña debe tener al menos 8 caracteres',
          max: 'La contraseña debe tener máximo 20 caracteres',
          regex: 'La contraseña debe tener al menos una letra mayúscula, una minúscula, un número y un caracter especial',
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"password","label":"Contraseña","placeholder":"Contraseña"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"confirm_password","rules":"required|confirmed:password","custom-messages":{
          required: 'La confirmación de contraseña es obligatoria',
          confirmed: 'La contraseña no coincide',
        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"type":"password","label":"Confirmar Contraseña","placeholder":"Confirmar Contraseña"},model:{value:(_vm.user.confirm_password),callback:function ($$v) {_vm.$set(_vm.user, "confirm_password", $$v)},expression:"user.confirm_password"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('button',{staticClass:"btn orange-btn float-right",attrs:{"type":"submit"}},[_vm._v("Cambiar")])])
}
var staticRenderFns = []

export { render, staticRenderFns }