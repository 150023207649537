<template>
  <div class="content text-center">
    <div class="container-fluid mt-5 mb-5">
      <ModalPreviewVideo :current-documento="videoObject"></ModalPreviewVideo>
    </div>
  </div>
</template>

<script>
import ModalPreviewVideo from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewVideo.vue";

import config from "@/config";

export default {
  components: {
    ModalPreviewVideo
  },

  data() {
    return {
      videoObject: {
        documento: config.STG_BASE_URL + "/assets/videos/correduria_digital_landing_page.webm" + config.STG_ACCESS_KEY,
        fileType: "video/webm",
        poster: "/img/cp_logooficial_fondoNEGRO.png"
      }
    }
  },
};
</script>

<style>
.descripcion {
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  /* color: #9a9a9a; */
  /* color: #ea640b; */
  font-size: 18px;
}
</style>
