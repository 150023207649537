<template>
  <div class="content">
    <div class="container-fluid mt-5 mb-5">
      <div class="row">
        <div class="col-md-4">
          <h3 class="text-warning">Testimonios y reseñas</h3>
        </div>
      </div>

      <div class="mb-3"></div>

      <div class="row justify-content-around">
        <div class="col-md-3">
          <b-card>
            <template slot="header">
              <h4 class="card-title text-center">John Doe</h4>
            </template>
            <div class="text-center long">
              <div class="star-rating d-flex justify-content-center mb-3">
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
              </div>
              <p>
                "This service is fantastic! The team was incredibly professional and exceeded all my expectations."
              </p>
            </div>
            <!-- <template slot="footer">
              <hr />
              <a class="route" style="cursor: pointer;" @click="openModalVideo('constitutiva')">Más información</a>
            </template> -->
          </b-card>
        </div>
        <div class="col-md-3">
          <b-card>
            <template slot="header">
              <h4 class="card-title text-center">John Doe</h4>
            </template>
            <div class="text-center long">
              <div class="star-rating d-flex justify-content-center mb-3">
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
              </div>
              <p>
                "This service is fantastic! The team was incredibly professional and exceeded all my expectations."
              </p>
            </div>
            <!-- <template slot="footer">
              <hr />
              <a class="route" style="cursor: pointer;" @click="openModalVideo('constitutiva')">Más información</a>
            </template> -->
          </b-card>
        </div>
        <div class="col-md-3">
          <b-card>
            <template slot="header">
              <h4 class="card-title text-center">John Doe</h4>
            </template>
            <div class="text-center long">
              <div class="star-rating d-flex justify-content-center mb-3">
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
                <span class="star">&#9733;</span>
              </div>
              <p>
                "This service is fantastic! The team was incredibly professional and exceeded all my expectations."
              </p>
            </div>
            <!-- <template slot="footer">
              <hr />
              <a class="route" style="cursor: pointer;" @click="openModalVideo('constitutiva')">Más información</a>
            </template> -->
          </b-card>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'TestimoniosComponent',
  data() {
    return {
      testimonials: [
        { id: 1, message: 'This product is amazing!', client: 'John Doe' },
        { id: 2, message: 'Excellent service and support.', client: 'Jane Smith' },
        { id: 3, message: 'Highly recommend to everyone.', client: 'Sam Wilson' }
      ]
    };
  }
};
</script>

<style scoped>
.testimonials {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.testimonial {
  margin-bottom: 20px;
}

.message {
  font-style: italic;
}

.client {
  font-weight: bold;
  text-align: right;
}
</style>
