<template>
  <div class="container">
    <h4 class="cardD">Finalización de proceso</h4>

    <div class="mt-4">
      <div class="row ">
        <div class="col-12">
          <b-button type="submit" block class="btn-fill orange-btn bottom2" size="m"
            :disabled="ratificacion.estatus_id != 55">Dar proceso por terminado</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Terminado",

  props: {
    ratificacion: { type: Object },
  },
}
</script>
