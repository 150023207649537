<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <ValidationProvider name="firstName" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El nombre es obligatorio' }">
          <base-input type="text" label="Nombre(s)" placeholder="Nombre(s)" v-model="currentRepresentante.nombre">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="lastNameP" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El apellido paterno es obligatorio'
        }">
          <base-input type="text" label="Apellido Paterno" placeholder="Apellido Paterno"
            v-model="currentRepresentante.paterno">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <!-- <ValidationProvider
          name="lastNameM"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El apellido materno es obligatorio',
          }"
        > -->
        <base-input type="text" label="Apellido Materno" placeholder="Apellido Materno"
          v-model="currentRepresentante.materno">
        </base-input>
        <!-- <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider> -->
      </div>
    </div>

    <div class="row">
      <div class="col-md-5">
        <ValidationProvider name="email" rules="required|email" v-slot="{ errors }" :custom-messages="{
          required: 'El email es obligatorio',
          email: 'Debe ser un email válido'
        }">
          <base-input type="email" label="Email" placeholder="Email" v-model="currentRepresentante.email">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-3">
        <ValidationProvider name="rfc" rules="required|length:13" v-slot="{ errors }" :custom-messages="{
          required: 'El RFC es obligatorio',
          length: 'Debe tener 13 caracteres'
        }">
          <base-input type="text" label="RFC" placeholder="RFC" v-model="currentRepresentante.rfc">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="curp" rules="required|length:18" v-slot="{ errors }" :custom-messages="{
          required: 'La CURP es obligatorio',
          length: 'Debe tener 18 caracteres'
        }">
          <base-input type="text" label="CURP" placeholder="CURP" v-model="currentRepresentante.curp">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <ValidationProvider name="fecha_nacimiento" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La fecha de nacimiento es obligatoria'
        }">
          <base-input type="date" label="Fecha de nacimiento" placeholder="Fecha de nacimiento"
            v-model="currentRepresentante.fecha_nacimiento">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-5">
        <!-- TODO: Refactorizar este select para utilizar las nacionalidades de la api -->
        <ValidationProvider name="nacionalidad" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La nacionalidad es obligatoria.'
        }">
          <label>Nacionalidad</label>
          <!-- <v-select v-model="currentRepresentante.nacionalidad" :options="optionsNacionalidades"
            :selectable="option => !option.includes('____________________________________________________')" /> -->
          <v-select multiple v-model="currentRepresentante.nacionalidades" label="nacionalidad"
            :options="optionsNacionalidades" />
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row">
      <!-- <div class="col-md-4">
        <ValidationProvider name="lugar_nacim_pais" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El país es obligatorio' }">
          <label>Lugar de nacimiento: País</label>
          <select v-model="currentRepresentante.lugar_nacim_pais" class="custom-select">
            <option v-for="option in options" :key="option.value">
              {{ option.text }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="lugar_nacim_estado" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El estado es obligatorio'
        }">
          <base-input type="text" label="Estado" placeholder="Estado" v-model="currentRepresentante.lugar_nacim_estado">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="lugar_nacim_ciudad" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La ciudad de nacimiento es obligatoria'
        }">
          <base-input type="text" label="Ciudad/Alcaldía" placeholder="Ciudad"
            v-model="currentRepresentante.lugar_nacim_ciudad">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div> -->

      <div class="col-md-4">
        <ValidationProvider name="lugar_nacim_pais" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El país es obligatorio' }">
          <label>Lugar de nacimiento: País</label>
          <select @change="fetchStatesByCountry" v-model="selectCountry" class="form-control"
            :disabled="currentRepresentante.disabled">
            <option v-for="option in countries" :key="option.id">
              {{ option.name }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="lugar_nacim_estado" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El estado es obligatorio',
        }">
          <label>Lugar de nacimiento: Estado</label>
          <select @change="fetchCitiesByState" v-model="selectState" class="form-control"
            :disabled="currentRepresentante.disabled">
            <option v-if="isEmptyStates" value="noStates" selected>
              {{ noStatesText }}
            </option>
            <option v-for="option in states" :key="option.id">
              {{ option.name }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="lugar_nacim_ciudad" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La ciudad de nacimiento es obligatoria',
        }">
          <label>Lugar de nacimiento: Municipio / Alcaldía</label>
          <select v-model="selectCity" class="form-control" :disabled="currentRepresentante.disabled">
            <option v-if="isEmptyCities" value="noCities" selected>
              {{ noCitiesText }}
            </option>
            <option v-for="option in cities" :key="option.id">
              {{ option.name }}
            </option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>

    </div>

    <div class="row">
      <div class="col-md-6">
        <ValidationProvider name="ocupacion" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La ocupación es obligatoria'
        }">
          <base-input type="text" label="Ocupación / Actividad / Profesión" placeholder="Ocupación"
            v-model="currentRepresentante.ocupacion">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-6">
        <ValidationProvider name="estado_civil" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El estado civil es obligatorio'
        }">
          <!-- <base-input
            type="text"
            label="Estado civil"
            placeholder="Estado civil"
            v-model="currentRepresentante.estado_civil"
          >
          </base-input> -->
          <label for="">Estado civil</label>
          <select v-model="currentRepresentante.estado_civil" class="custom-select">
            <option value="Soltero (a)">Soltero (a)</option>
            <option value="Casado (a)">Casado (a)</option>
            <option value="Unión Libre">Unión Libre</option>
            <option value="Sociedad de Convivencia">Sociedad de Convivencia</option>
            <option value="Viudo (a)">Viudo (a)</option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <ValidationProvider name="identificacion_tipo" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'El tipo de identificación es obligatorio' }">
          <label>Identificación: Tipo</label>
          <select v-model="currentRepresentante.identificacion_tipo" class="custom-select mb-2"
            :disabled="currentRepresentante.disabled" @change="selectTipoId">
            <option v-for="option in optionsIdentificacionTipo" :key="option.value">
              {{ option.text }}
            </option>
          </select>

          <base-input type="text" label="" placeholder="Tipo" v-model="currentRepresentante.identificacion_tipo_otro"
            v-if="currentRepresentante.identificacion_tipo === 'Otro'" :disabled="currentRepresentante.disabled">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="identificacion_autoridad" rules="required" v-slot="{ errors }"
          :custom-messages="{ required: 'La autoridad es obligatoria' }">

          <label>Autoridad</label>
          <select v-model="currentRepresentante.identificacion_autoridad" class="custom-select mb-2"
            :disabled="currentRepresentante.disabled">
            <option v-for="option in optionsIdentificacionAutoridad" :key="option.value">
              {{ option.text }}
            </option>
          </select>
          <base-input type="text" :disabled="user.disabled" label="" placeholder="Autoridad"
            v-model="currentRepresentante.identificacion_autoridad_otro"
            v-if="currentRepresentante.identificacion_autoridad === 'Otro'">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="identificacion_numero" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El número es obligatorio'
        }">
          <base-input type="text" label="Número de identificación" placeholder="Número"
            v-model="currentRepresentante.identificacion_numero">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row" v-if="currentRepresentante.personaARepresentar === 'Moral'">
      <div class="col-md-3">
        <label for="mismoConstitutivaInput">Mismo que constitutiva</label>
        <b-form-checkbox id="mismoConstitutivaInput" v-model="currentRepresentante.mismo_constitutiva"
          @change="mismaDataConstitutiva(currentRepresentante.mismo_constitutiva)">
        </b-form-checkbox>
      </div>
    </div>

    <h4 class="cardD">
      Datos del poder
    </h4>
    <div class="row">
      <div class="col-md-6">
        <ValidationProvider name="instrumento_numero" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El número de instrumento es obligatorio'
        }">
          <!-- label="Instrumento Constitutivo: Número" -->
          <base-input type="text" label="Número de Instrumento público" placeholder="Número"
            v-model="currentRepresentante.instrumento_numero">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-6">
        <ValidationProvider name="instrumento_numero_fecha" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La fecha es obligatoria'
        }">
          <base-input type="date" label="De Fecha" placeholder="De Fecha"
            v-model="currentRepresentante.instrumento_numero_fecha">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row">
      <div class="col-md-2">
        <ValidationProvider name="instrumento_fedatario" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El tipo de fedatario es obligatorio'
        }">
          <label>Tipo de Fedatario</label>
          <select v-model="currentRepresentante.instrumento_fedatario" class="custom-select">
            <option value="Notario">Notario</option>
            <option value="Corredor Público">Corredor Público</option>
          </select>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="instrumento_fedatario_nombre" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El nombre del fedatario es obligatorio'
        }">
          <base-input type="text" label="Nombre de Fedatario" placeholder="Fedatario"
            v-model="currentRepresentante.instrumento_fedatario_nombre">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-3">
        <ValidationProvider name="instrumento_fedatario_numero" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El número es obligatorio'
        }">
          <base-input type="text" label="Número" placeholder="Número"
            v-model="currentRepresentante.instrumento_fedatario_numero">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-3">
        <ValidationProvider name="instrumento_fedatario_estado" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El estado es obligatorio'
        }">
          <!-- <base-input
            type="text"
            label="Del Estado de"
            placeholder="Estado"
            v-model="currentRepresentante.instrumento_fedatario_estado"
          >
          </base-input> -->
          <label>Del Estado de</label>
          <select v-model="currentRepresentante.instrumento_fedatario_estado" class="custom-select">
            <option v-for="option in optionsEstados" :key="option.value">
              {{ option.text }}
            </option>
          </select>

          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <ValidationProvider name="TipoPoder" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El tipo de poder es obligatorio'
        }">
          <base-input type="text" label="Tipo de Poder" placeholder="Tipo de Poder"
            v-model="currentRepresentante.tipo_poder">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>

      <div class="col-md-4">
        <base-input type="text" label="Vigencia del Poder" placeholder="Vigencia del Poder"
          v-model="currentRepresentante.vigencia_poder">
        </base-input>
      </div>

      <div class="col-md-4">
        <base-input type="text" label="Limitaciones del Poder" placeholder="Limitaciones del Poder"
          v-model="currentRepresentante.limitaciones_poder">
        </base-input>
      </div>
    </div>

    <h4 class="cardD">
      Datos de registro
    </h4>
    <div class="row">
      <div class="col-md-4">
        <ValidationProvider name="instrumento_rpf_folio" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'El Folio mercantil es obligatorio'
        }">
          <base-input type="text" label="Folio mercantil" placeholder="Folio mercantil"
            v-model="currentRepresentante.instrumento_rpf_folio">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="instrumento_rpf_folio_fecha" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La fecha de inscripción es obligatoria'
        }">
          <base-input type="date" label="Fecha de inscripción" placeholder="Fecha de inscripción"
            v-model="currentRepresentante.instrumento_rpf_folio_fecha">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-4">
        <ValidationProvider name="instrumento_rpf_folio_lugar" rules="required" v-slot="{ errors }" :custom-messages="{
          required: 'La oficina del RPC es obligatoria'
        }">
          <base-input type="text" label="Oficina del RPC ubicada en" placeholder="Oficina del RPC ubicada en"
            v-model="currentRepresentante.instrumento_rpf_folio_lugar">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>
    <br />

    <div class="text-center">
      <button type="submit" class="btn btn-fill float-right orange-btn bottomB">
        Guardar
      </button>
      <!-- <b-button type="submit" class="btn-fill orange-btn bottom2" size="lg"
      >Guardar</b-button
    > -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import { CatNacionalidadesApi } from "@/api";

export default {
  props: {
    currentRepresentante: { type: Object }
  },

  computed: {
    ...mapState([
      "countries",
      "states",
      "cities",
      "isEmptyStates",
      "isEmptyCities",
    ]),

    ...mapGetters([
      "getCountries",
      "getStatesByCountryId",
      "getCitiesByStateId",
    ]),
  },

  watch: {
    "currentRepresentante.curp": function (value) {
      if (!value) {
        this.currentRepresentante.curp = "";

        return;
      }

      this.currentRepresentante.curp = this.currentRepresentante.curp.toUpperCase();
    },

    "currentRepresentante.rfc": function (value) {
      if (!value) {
        this.currentRepresentante.rfc = "";

        return;
      }

      this.currentRepresentante.rfc = this.currentRepresentante.rfc.toUpperCase();
    },

    "selectCountry": function (val, oldVal) {
      this.currentRepresentante.lugar_nacim_pais = this.selectCountry;
    },
    "selectState": function (val, oldVal) {
      this.currentRepresentante.lugar_nacim_estado = this.selectState;
    },
    "selectCity": function (val, oldVal) {
      this.currentRepresentante.lugar_nacim_ciudad = this.selectCity;
    }
  },

  async mounted() {
    this.fetchNacionalidades();
    await this.fetchCountries();

    if (this.currentRepresentante.lugar_nacim_pais) {
      this.selectCountry = this.currentRepresentante.lugar_nacim_pais;

      if (this.currentRepresentante.telefonos[0].clave) {
        this.phoneKey = this.currentRepresentante.telefonos[0].clave;
      }
      if (this.currentRepresentante.lugar_nacim_estado) {
        await this.getExistCountry(this.currentRepresentante.lugar_nacim_pais);
      }
      if (this.currentRepresentante.lugar_nacim_estado) {
        await this.getExistState(this.currentRepresentante.lugar_nacim_estado);
      }
      if (this.currentRepresentante.lugar_nacim_ciudad) {
        await this.getExistCity(this.currentRepresentante.lugar_nacim_ciudad);
      }
    }
  },

  methods: {
    ...mapActions(["fetchCountries", "fetchStates", "fetchCities"]),

    async fetchStatesByCountry() {
      /**
       * Esta fx se llama solo si ya se tiene un país seleccionado
       */
      this.selectState = "";
      const pais = this.selectCountry;
      this.selectCity = "";
      // change store state cities to empty
      this.$store.commit("setCities", []);
      // Obtengo el id del país
      const paisId = await this.getIdCountry(pais);
      // obtengo el código de area del país
      const phoneCode = await this.getPhoneCode(pais);
      this.phoneKey = phoneCode;
      // this.currentCompareciente.telefonos[0].clave = phoneCode;
      // obtengo los estados del país
      await this.fetchStates(paisId);
      if (this.isEmptyStates) {
        this.selectState = "noStates";
        this.selectCity = "noCities";
      }
    },

    async fetchCitiesByState() {
      /**
       * Se llama para obtener las ciudades de un estado seleccionado
       */
      this.selectCity = "";
      const estado = this.selectState
      const estadoId = await this.getIdState(estado);
      await this.fetchCities(estadoId);
    },

    async getIdCountry(countryName) {
      /**
       * Obtiene el id del país seleccionado
       */
      const country = this.countries.find((c) => c.name === countryName);
      return country ? country.id : null;
    },

    async getIdState(stateName) {
      /**
       * Obtiene el id del estado seleccionado
       */
      const state = this.states.find((s) => s.name === stateName);
      return state ? state.id : null;
    },

    async getPhoneCode(countryName) {
      /**
       * Obtiene el código de área del país seleccionado
       */
      const country = this.countries.find((c) => c.name === countryName);
      return country ? country.phone_code : null;
    },

    async getExistCountry(countryName) {
      /**
       * Se llama si ya se tiene un país seleccionado
       */
      const currentCountry = countryName === "Mexico" ? "México" : this.currentRepresentante.lugar_nacim_pais;
      this.selectCountry = currentCountry;
    },

    async getExistState(stateName) {
      /**
       * Se llama si ya se tiene un estado seleccionado
       */
      const currentState = stateName
      this.selectState = currentState;
      const paisId = await this.getIdCountry(this.selectCountry);
      await this.fetchStates(paisId);
    },

    async getExistCity(cityName) {
      /**
       * Se llama si ya se tiene una ciudad seleccionada
       */
      const currentCity = cityName;
      this.selectCity = currentCity;
      const estadoId = await this.getIdState(this.selectState);
      await this.fetchCities(estadoId);
    },

    async fetchNacionalidades() {
      const { value } = await CatNacionalidadesApi.list({
        page: 0,
        registersPerPage: 0
      });

      this.optionsNacionalidades = value;
    },

    mismaDataConstitutiva(state) {
      this.$emit("state", state);
    },

    selectTipoId() {
      const identificacionTipo = this.currentRepresentante.identificacion_tipo;

      if (identificacionTipo === "Credencial para votar") {
        this.currentRepresentante.identificacion_autoridad = "Instituto Nacional Electoral";
      } else if (identificacionTipo === "Pasaporte") {
        this.currentRepresentante.identificacion_autoridad = "Secretaría de Relaciones Exteriores";
      } else if (identificacionTipo === "Cédula Profesional") {
        this.currentRepresentante.identificacion_autoridad = "Secretaría de Educación Pública";
      } else if (identificacionTipo === "Licencia de Conducir") {
        this.currentRepresentante.identificacion_autoridad = "Otro";
      } else if (identificacionTipo === "Forma Migratoria") {
        this.currentRepresentante.identificacion_autoridad = "Instituto Nacional de Migración";
      } else if (identificacionTipo === "Otro") {
        this.currentRepresentante.identificacion_autoridad = "Otro";
      }
    },
  },

  data() {
    return {
      optionsNacionalidades: [],
      optionsIdentificacionTipo: [
        { text: "Credencial para votar", value: "Credencial para votar" },
        { text: "Pasaporte", value: "Pasaporte" },
        { text: "Cédula Profesional", value: "Cédula Profesional" },
        { text: "Licencia de Conducir", value: "Licencia de Conducir" },
        { text: "Forma Migratoria", value: "Forma Migratoria" },
        { text: "Otro", value: "Otro" }
      ],
      optionsIdentificacionAutoridad: [
        { text: "Instituto Federal Electoral", value: "Instituto Federal Electoral" },
        { text: "Instituto Nacional Electoral", value: "Instituto Nacional Electoral" },
        { text: "Secretaría de Relaciones Exteriores", value: "Secretaría de Relaciones Exteriores" },
        { text: "Secretaría de Educación Pública", value: "Secretaría de Educación Pública" },
        { text: "Instituto Nacional de Migración", value: "Instituto Nacional de Migración" },
        { text: "Otro", value: "Otro" }
      ],
      options: [
        { text: "México", value: "México" },
        { text: " España", value: " España" },
        { text: " Argentina", value: " Argentina" },
        { text: " Estados Unidos", value: " Estados Unidos" },
        { text: " Afganistán", value: " Afganistán" },
        { text: " Albania", value: " Albania" },
        { text: " Alemania", value: " Alemania" },
        { text: " Andorra", value: " Andorra" },
        { text: " Angola", value: " Angola" },
        { text: " Antigua y Barbuda", value: " Antigua y Barbuda" },
        { text: " Arabia Saudita", value: " Arabia Saudita" },
        { text: " Argelia", value: " Argelia" },
        { text: " Armenia", value: " Armenia" },
        { text: " Australia", value: " Australia" },
        { text: " Austria", value: " Austria" },
        { text: " Azerbaiyán", value: " Azerbaiyán" },
        { text: " Bahamas", value: " Bahamas" },
        { text: " Bangladés", value: " Bangladés" },
        { text: " Barbados", value: " Barbados" },
        { text: " Baréin", value: " Baréin" },
        { text: " Bélgica", value: " Bélgica" },
        { text: " Belice", value: " Belice" },
        { text: " Benín", value: " Benín" },
        { text: " Bielorrusia", value: " Bielorrusia" },
        { text: " Birmania", value: " Birmania" },
        { text: " Bolivia", value: " Bolivia" },
        { text: " Bosnia y Herzegovina", value: " Bosnia y Herzegovina" },
        { text: " Botsuana", value: " Botsuana" },
        { text: " Brasil", value: " Brasil" },
        { text: " Brunéi", value: " Brunéi" },
        { text: " Bulgaria", value: " Bulgaria" },
        { text: " Burkina Faso", value: " Burkina Faso" },
        { text: " Burundi", value: " Burundi" },
        { text: " Bután", value: " Bután" },
        { text: " Cabo Verde", value: " Cabo Verde" },
        { text: " Camboya", value: " Camboya" },
        { text: " Camerún", value: " Camerún" },
        { text: " Canadá", value: " Canadá" },
        { text: " Catar", value: " Catar" },
        { text: " Chad", value: " Chad" },
        { text: " Chile", value: " Chile" },
        { text: " China", value: " China" },
        { text: " Chipre", value: " Chipre" },
        { text: " Ciudad del Vaticano", value: " Ciudad del Vaticano" },
        { text: " Colombia", value: " Colombia" },
        { text: " Comoras", value: " Comoras" },
        { text: " Corea del Norte", value: " Corea del Norte" },
        { text: " Corea del Sur", value: " Corea del Sur" },
        { text: " Costa de Marfil", value: " Costa de Marfil" },
        { text: " Costa Rica", value: " Costa Rica" },
        { text: " Croacia", value: " Croacia" },
        { text: " Cuba", value: " Cuba" },
        { text: " Dinamarca", value: " Dinamarca" },
        { text: " Dominica", value: " Dominica" },
        { text: " Ecuador", value: " Ecuador" },
        { text: " Egipto", value: " Egipto" },
        { text: " El Salvador", value: " El Salvador" },
        { text: " Emiratos Árabes Unidos", value: " Emiratos Árabes Unidos" },
        { text: " Eritrea", value: " Eritrea" },
        { text: " Eslovaquia", value: " Eslovaquia" },
        { text: " Eslovenia", value: " Eslovenia" },
        { text: " Estonia", value: " Estonia" },
        { text: " Etiopía", value: " Etiopía" },
        { text: " Filipinas", value: " Filipinas" },
        { text: " Finlandia", value: " Finlandia" },
        { text: " Fiyi", value: " Fiyi" },
        { text: " Francia", value: " Francia" },
        { text: " Gabón", value: " Gabón" },
        { text: " Gambia", value: " Gambia" },
        { text: " Georgia", value: " Georgia" },
        { text: " Ghana", value: " Ghana" },
        { text: " Granada", value: " Granada" },
        { text: " Grecia", value: " Grecia" },
        { text: " Guatemala", value: " Guatemala" },
        { text: " Guyana", value: " Guyana" },
        { text: " Guinea", value: " Guinea" },
        { text: " Guinea ecuatorial", value: " Guinea ecuatorial" },
        { text: " Guinea-Bisáu", value: " Guinea-Bisáu" },
        { text: " Haití", value: " Haití" },
        { text: " Honduras", value: " Honduras" },
        { text: " Hungría", value: " Hungría" },
        { text: " India", value: " India" },
        { text: " Indonesia", value: " Indonesia" },
        { text: " Irak", value: " Irak" },
        { text: " Irán", value: " Irán" },
        { text: " Irlanda", value: " Irlanda" },
        { text: " Islandia", value: " Islandia" },
        { text: " Islas Marshall", value: " Islas Marshall" },
        { text: " Islas Salomón", value: " Islas Salomón" },
        { text: " Israel", value: " Israel" },
        { text: " Italia", value: " Italia" },
        { text: " Jamaica", value: " Jamaica" },
        { text: " Japón", value: " Japón" },
        { text: " Jordania", value: " Jordania" },
        { text: " Kazajistán", value: " Kazajistán" },
        { text: " Kenia", value: " Kenia" },
        { text: " Kirguistán", value: " Kirguistán" },
        { text: " Kiribati", value: " Kiribati" },
        { text: " Kuwait", value: " Kuwait" },
        { text: " Laos", value: " Laos" },
        { text: " Lesoto", value: " Lesoto" },
        { text: " Letonia", value: " Letonia" },
        { text: " Líbano", value: " Líbano" },
        { text: " Liberia", value: " Liberia" },
        { text: " Libia", value: " Libia" },
        { text: " Liechtenstein", value: " Liechtenstein" },
        { text: " Lituania", value: " Lituania" },
        { text: " Luxemburgo", value: " Luxemburgo" },
        { text: " Macedonia del Norte", value: " Macedonia del Norte" },
        { text: " Madagascar", value: " Madagascar" },
        { text: " Malasia", value: " Malasia" },
        { text: " Malaui", value: " Malaui" },
        { text: " Maldivas", value: " Maldivas" },
        { text: " Malí", value: " Malí" },
        { text: " Malta", value: " Malta" },
        { text: " Marruecos", value: " Marruecos" },
        { text: " Mauricio", value: " Mauricio" },
        { text: " Mauritania", value: " Mauritania" },
        { text: " México", value: " México" },
        { text: " Micronesia", value: " Micronesia" },
        { text: " Moldavia", value: " Moldavia" },
        { text: " Mónaco", value: " Mónaco" },
        { text: " Mongolia", value: " Mongolia" },
        { text: " Montenegro", value: " Montenegro" },
        { text: " Mozambique", value: " Mozambique" },
        { text: " Namibia", value: " Namibia" },
        { text: " Nauru", value: " Nauru" },
        { text: " Nepal", value: " Nepal" },
        { text: " Nicaragua", value: " Nicaragua" },
        { text: " Níger", value: " Níger" },
        { text: " Nigeria", value: " Nigeria" },
        { text: " Noruega", value: " Noruega" },
        { text: " Nueva Zelanda", value: " Nueva Zelanda" },
        { text: " Omán", value: " Omán" },
        { text: " Países Bajos", value: " Países Bajos" },
        { text: " Pakistán", value: " Pakistán" },
        { text: " Palaos", value: " Palaos" },
        { text: " Panamá", value: " Panamá" },
        { text: " Papúa Nueva Guinea", value: " Papúa Nueva Guinea" },
        { text: " Paraguay", value: " Paraguay" },
        { text: " Perú", value: " Perú" },
        { text: " Polonia", value: " Polonia" },
        { text: " Portugal", value: " Portugal" },
        { text: " Reino Unido", value: " Reino Unido" },
        {
          text: " República Centroafricana",
          value: " República Centroafricana"
        },
        { text: " República Checa", value: " República Checa" },
        { text: " República del Congo", value: " República del Congo" },
        {
          text: " República Democrática del Congo",
          value: " República Democrática del Congo"
        },
        { text: " República Dominicana", value: " República Dominicana" },
        { text: " Ruanda", value: " Ruanda" },
        { text: " Rumanía", value: " Rumanía" },
        { text: " Rusia", value: " Rusia" },
        { text: " Samoa", value: " Samoa" },
        { text: " San Cristóbal y Nieves", value: " San Cristóbal y Nieves" },
        { text: " San Marino", value: " San Marino" },
        {
          text: " San Vicente y las Granadinas",
          value: " San Vicente y las Granadinas"
        },
        { text: " Santa Lucía", value: " Santa Lucía" },
        { text: " Santo Tomé y Príncipe", value: " Santo Tomé y Príncipe" },
        { text: " Senegal", value: " Senegal" },
        { text: " Serbia", value: " Serbia" },
        { text: " Seychelles", value: " Seychelles" },
        { text: " Sierra Leona", value: " Sierra Leona" },
        { text: " Singapur", value: " Singapur" },
        { text: " Siria", value: " Siria" },
        { text: " Somalia", value: " Somalia" },
        { text: " Sri Lanka", value: " Sri Lanka" },
        { text: " Suazilandia", value: " Suazilandia" },
        { text: " Sudáfrica", value: " Sudáfrica" },
        { text: " Sudán", value: " Sudán" },
        { text: " Sudán del Sur", value: " Sudán del Sur" },
        { text: " Suecia", value: " Suecia" },
        { text: " Suiza", value: " Suiza" },
        { text: " Surinam", value: " Surinam" },
        { text: " Tailandia", value: " Tailandia" },
        { text: " Tanzania", value: " Tanzania" },
        { text: " Tayikistán", value: " Tayikistán" },
        { text: " Timor Oriental", value: " Timor Oriental" },
        { text: " Togo", value: " Togo" },
        { text: " Tonga", value: " Tonga" },
        { text: " Trinidad y Tobago", value: " Trinidad y Tobago" },
        { text: " Túnez", value: " Túnez" },
        { text: " Turkmenistán", value: " Turkmenistán" },
        { text: " Turquía", value: " Turquía" },
        { text: " Tuvalu", value: " Tuvalu" },
        { text: " Ucrania", value: " Ucrania" },
        { text: " Uganda", value: " Uganda" },
        { text: " Uruguay", value: " Uruguay" },
        { text: " Uzbekistán", value: " Uzbekistán" },
        { text: " Vanuatu", value: " Vanuatu" },
        { text: " Venezuela", value: " Venezuela" },
        { text: " Vietnam", value: " Vietnam" },
        { text: " Yemen", value: " Yemen" },
        { text: " Yibuti", value: " Yibuti" },
        { text: " Zambia", value: " Zambia" },
        { text: " Zimbabue", value: " Zimbabue" }
      ],
      optionsEstados: [
        { text: "Aguascalientes", value: "Aguascalientes" },
        { text: "Baja California", value: "Baja California" },
        { text: "Baja California Sur", value: "Baja California Sur" },
        { text: "Campeche", value: "Campeche" },
        { text: "Chiapas", value: "Chiapas" },
        { text: "Chihuahua", value: "Chihuahua" },
        { text: "Coahuila ", value: "Coahuila " },
        { text: "Colima", value: "Colima" },
        { text: "Ciudad de México", value: "Ciudad de México" },
        { text: "Durango", value: "Durango" },
        { text: "Estado de México", value: "Estado de México" },
        { text: "Guanajuato", value: "Guanajuato" },
        { text: "Guerrero", value: "Guerrero" },
        { text: "Hidalgo", value: "Hidalgo" },
        { text: "Jalisco", value: "Jalisco" },
        { text: "Michoacán ", value: "Michoacán " },
        { text: "Morelos", value: "Morelos" },
        { text: "Nayarit", value: "Nayarit" },
        { text: "Nuevo León", value: "Nuevo León" },
        { text: "Oaxaca", value: "Oaxaca" },
        { text: "Puebla", value: "Puebla" },
        { text: "Querétaro", value: "Querétaro" },
        { text: "Quintana Roo", value: "Quintana Roo" },
        { text: "San Luis Potosí", value: "San Luis Potosí" },
        { text: "Sinaloa", value: "Sinaloa" },
        { text: "Sonora", value: "Sonora" },
        { text: "Tabasco", value: "Tabasco" },
        { text: "Tamaulipas", value: "Tamaulipas" },
        { text: "Tlaxcala", value: "Tlaxcala" },
        { text: "Veracruz ", value: "Veracruz " },
        { text: "Yucatán", value: "Yucatán" },
        { text: "Zacatecas", value: "Zacatecas" }
      ],
      form: {
        personaARepresentar: null,
        nombre: null,
        paterno: null,
        materno: null,
        email: null,
        rfc: null,
        curp: null,
        fecha_nacimiento: null,
        lugar_nacim_ciudad: null,
        lugar_nacim_estado: null,
        lugar_nacim_pais: null,
        nacionalidad: null,
        identificacion_tipo: null,
        identificacion_autoridad: null,
        identificacion_numero: null,
        instrumento_numero: null,
        instrumento_numero_fecha: null,
        instrumento_fedatario: null,
        instrumento_fedatario_nombre: null,
        instrumento_fedatario_numero: null,
        instrumento_fedatario_estado: null,
        instrumento_rpf_folio: null,
        instrumento_rpf_folio_fecha: null,
        instrumento_rpf_folio_lugar: null,
        mismo_constitutiva: null,
        mismo_anterior: null,
        ocupacion: null,
        estado_civil: null

        // options: [
        //   { text: "Uno", value: "A" },
        //   { text: "Dos", value: "B" },
        //   { text: "Tres", value: "C" },
        // ],
      },

      noStatesText: "No hay estados disponibles",
      noCitiesText: "No hay ciudades disponibles",
      selectCountry: "",
      selectState: "",
      selectCity: "",
    };
  }
};
</script>

<style scoped>
.bottomB {
  position: fixed;
  right: 2%;
  bottom: 2%;
  overflow: visible;
  z-index: 100;
}
</style>
