<template>
  <div class="container">
    <h4 class="cardD">Cotización Copias Certificadas</h4>

    <h4 class="cardD mt-1">Resumen de Documentos</h4>

    <div class="row" v-for="doc in documentos_base" :key="doc.id">
      <label class="grey col-md-1">{{ doc.copias_certificadas }}</label>
      <label class="grey col-md-5">{{ doc.tipo }}</label>
      <label class="grey col-md-6">${{ doc.totalDocumento }}</label>
    </div>
    <div class="row mt-2">
      <label class="grey col-md-6"> Subtotal </label>
      <label class="grey col-md-6">${{ subtotal_documentos }}</label>
    </div>

    <h4 class="cardD" v-if="subtotal_extras > 0">Extras</h4>
    <div class="row" v-if="urgencia && subtotal_extras > 0">
      <label class="grey col-md-1"></label>
      <label class="grey col-md-5">Urgencia</label>
      <label class="grey col-md-6">${{ total_urgencia }}</label>
    </div>
    <div class="row" v-if="envioDomicilio && subtotal_extras > 0">
      <label class="grey col-md-1">1</label>
      <label class="grey col-md-5">Envío a Domicilio</label>
      <label class="grey col-md-6">$500</label>
    </div>
    <div class="row mt-2" v-if="subtotal_extras > 0">
      <label class="grey col-md-6"> Subtotal </label>
      <label class="grey col-md-6">${{ subtotal_extras }}</label>
    </div>

    <div class="row mt-1">
      <div class="col-md-6">
        <h4 class="cardD">Total</h4>
      </div>
      <div class="col-md-6">
        <h4 class="cardD">${{ total_previo_descuento }}</h4>
      </div>
    </div>

    <hr v-if="subtotal_descuento > 0">
    <h4 class="cardD" v-if="subtotal_descuento > 0">Descuentos</h4>
    <div class="row" v-if="descuento_documentos > 0">
      <label class="grey col-md-1">{{ documentos_base.length - 3 }}</label>
      <label class="grey col-md-5">Número de Documentos</label>
      <label class="grey col-md-6">-${{ descuento_documentos }}</label>
    </div>
    <div class="row" v-if="envioDomicilio && subtotal_descuento > 0">
      <label class="grey col-md-1">1</label>
      <label class="grey col-md-5">Envío a Domicilio</label>
      <label class="grey col-md-6">-$500</label>
    </div>
    <div class="row mt-2" v-if="subtotal_descuento > 0">
      <label class="grey col-md-6"> Subtotal </label>
      <label class="grey col-md-6">-${{ subtotal_descuento }}</label>
    </div>

    <h4 class="cardD mt-1">Cupones</h4>
    <div class="row">
      <div class="col-12">
        <base-input type="text" label="Si cuenta con un cupón puede aplicarlo." placeholder="Cupón de descuento"
          :disabled="cuponAplicado" v-model="nombreCuponDescuento"></base-input>
      </div>
      <div class="col-12 d-flex">
        <br />
        <b-button class="orange-btn" size="m" :disabled="cuponAplicado"
          @click="validarCupon(nombreCuponDescuento)">Aplicar</b-button>
      </div>
    </div>

    <div class="row mt-1" v-if="total_post_descuento != total_previo_descuento">
      <div class="col-md-6">
        <h4 class="cardD">Total</h4>
      </div>
      <div class="col-md-6">
        <h4 class="cardD">${{ total_post_descuento }}</h4>
      </div>
    </div>

    <hr>

    <div class="row mt-4">
      <div class="col-md-12">
        <p style="font-size: 13px;  text-align: center; ">
          EN TODOS LOS CASOS ES NECESARIO LA PRESENTACIÓN DE LOS DOCUMENTOS ORIGINALES PARA SU COTEJO.
        </p>
        <p style="font-size: 13px;  text-align: center; ">
          NO SE ENTREGARÁ COTEJO ALGUNO SIN LA PRESENTACIÓN DE DOCUMENTOS ORIGINALES
        </p>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="validacionDatos" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }"
          :custom-messages="{required: 'La confirmación es obligatoria'}">
          <div style="display: flex; align-items: center;">
            <b-form-checkbox v-model="validacionDatos" class="orange">
              Confirmo que los documentos son mercantiles y la información proporcionada es correcta.
            </b-form-checkbox>
          </div>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="validacionTerminos" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }"
          :custom-messages="{ required: 'La confirmación es obligatoria' }">
          <div style="display: flex; align-items: center;">
            <b-form-checkbox v-model="validacionTerminos" class="orange">
              Acepto los Términos y condiciones.
            </b-form-checkbox>
          </div>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="validacionCompleto" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }"
          :custom-messages="{ required: 'La confirmación es obligatoria' }">
          <div style="display: flex; align-items: center;">
            <b-form-checkbox v-model="validacionCompleto" class="orange">
              Declaro, bajo protesta de decir la verdad que las páginas corresponden a la totalidad de las páginas que
              conforman el documento COMPLETO.
            </b-form-checkbox>
          </div>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <ValidationProvider name="validacionPdf" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }"
          :custom-messages="{ required: 'La confirmación es obligatoria' }">
          <div style="display: flex; align-items: center;">
            <b-form-checkbox v-model="validacionPdf" class="orange">
              Declaro, bajo protesta de decir la verdad que el PDF o Archivo que enviaré, corresponde al documento
              original que presentaré para cotejo de forma física en las oficinas que me sean indicadas.
            </b-form-checkbox>
          </div>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="mt-4">
      <div class="row">
        <div class="col-12">
          <b-button class="btn-fill orange-btn bottom2" size="m" @click="submitCotizacion()">Pagar</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CatGastosApi, CuponesApi } from "@/api";
import loadingMixin from "@/mixins/loadingMixin";

export default {
  name: "CotizacionCopiasCertificadas",

  props: {
    cotejo: { type: Object }
  },

  mixins: [loadingMixin],

  created() {
    this.toggleOnLoading();
    this.getGastos()
      .then(() => this.setExtraVariables())
      .then(() => this.getDocumentosBase())
      .then(() => this.calculaTotalPaginas())
      .then(() => this.calculaTotalPorCopia())
      .then(() => this.calculaTotalPorDocumento())
      .then(() => this.calculaSubtotalDocumentos())
      .then(() => this.calculaUrgencia())
      .then(() => this.calculaSubtotalExtras())
      .then(() => this.calculaTotalPrevioDescuentos())
      .then(() => this.calculaDescuentoDocumentos())
      .then(() => this.calculaSubtotalDescuento())
      .then(() => this.calculaTotalPostDescuentos())
      .catch((error) => {
        console.log(error);
        this.showErrorToast('No se pudieron obtener los totatles. Intente más tarde.')
      })
      .finally(() => {
        this.costoTotalAntesCupon = this.total_previo_descuento;
        this.cotejo.costo_total = this.costoTotalAntesCupon;

        this.toggleOffLoading();
      });
  },

  data() {
    return {
      // Costos pre-calculados
      cat_gastos: null,
      precio_monto_base: 0,
      precio_pagina_util: 0,
      precio_descuento_3er: 0,
      precio_urgencia: 0,

      // Cantidades
      documentos_base: [],
      paginas_totales: 0,
      descuento_documentos: 0,

      // Subtotales
      subtotal_documentos: 0,
      subtotal_extras: 0,
      subtotal_descuento: 0,

      // Extras
      urgencia: false,
      envioDomicilio: false,

      // Totales
      total_urgencia: 0,
      total_previo_descuento: 0,
      total_post_descuento: 0,

      // Casillas de validacion
      validacionDatos: false,
      validacionTerminos: false,
      validacionCompleto: false,
      validacionPdf: false,
      validacionPaginas: false,

      cuponAplicado: false,
      nombreCuponDescuento: null,
      costoTotalAntesCupon: 0,
    }
  },

  methods: {
    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    async getGastos() {
      const conceptos = ['monto_base', 'pagina_util', 'descuento_3er', 'urgencia'];
      const { value } = await CatGastosApi.filtraProcesos({
        page: 0,
        registersPerPage: 0,
        cat_servicios_id: "4"
      });

      this.cat_gastos = value;

      for (const concepto of conceptos) {
        const matchingItem = this.cat_gastos.find(item => item.clave === concepto);
        this[`precio_${concepto}`] = matchingItem.costo;
      }
    },

    setExtraVariables() {
      return new Promise((resolve, reject) => {
        try {
          this.cotejo.envio_domicilio == 1 ? this.envioDomicilio = true : this.envioDomicilio = false;
          this.cotejo.id_prioridad == 1 ? this.urgencia = true : this.urgencia = false;
          resolve();
        } catch (error) {
          console.log(error);
          reject(error);
        }
      })
    },

    getDocumentosBase() {
      return new Promise((resolve, reject) => {
        try {
          this.documentos_base = this.cotejo.documentos
            .filter(doc => !('anexoDe' in doc));
          resolve(this.documentos_base);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      })
    },

    calculaTotalPaginas() {
      return new Promise((resolve, reject) => {
        try {
          this.documentos_base = this.documentos_base.map(doc => {
            const paginas = parseInt(doc.paginas);
            const paginas_anexo = doc.tiene_anexo ? parseInt(doc.paginas_anexo) : 0;
            return {
              ...doc,
              totalPaginas: paginas + paginas_anexo
            };
          });
          resolve();
        } catch (error) {
          console.log(error);
          reject(error);
        }
      })
    },

    calculaTotalPorCopia() {
      return new Promise((resolve, reject) => {
        try {
          this.documentos_base = this.documentos_base.map(doc => {
            const costo_paginas = doc.totalPaginas * this.precio_pagina_util;
            return {
              ...doc,
              totalPorCopia: this.precio_monto_base + costo_paginas
            };
          });
          resolve();
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },

    calculaTotalPorDocumento() {
      return new Promise((resolve, reject) => {
        try {
          this.documentos_base = this.documentos_base.map(doc => {
            return {
              ...doc,
              totalDocumento: doc.totalPorCopia * parseInt(doc.copias_certificadas)
            };
          });
          resolve();
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },

    calculaSubtotalDocumentos() {
      return new Promise((resolve, reject) => {
        try {
          this.subtotal_documentos = this.documentos_base.reduce((sum, doc) =>
            sum + doc.totalDocumento, 0)
          resolve(this.subtotal_documentos);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },

    calculaUrgencia() {
      return new Promise((resolve, reject) => {
        try {
          this.urgencia ? this.total_urgencia = this.subtotal_documentos * this.precio_urgencia / 100
            : this.total_urgencia = 0;
          resolve(this.total_urgencia);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },

    calculaSubtotalExtras() {
      return new Promise((resolve, reject) => {
        try {
          this.subtotal_extras += this.total_urgencia;
          this.envioDomicilio ? this.subtotal_extras += 500 : this.subtotal_extras;
          resolve(this.subtotal_extras);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },

    calculaTotalPrevioDescuentos() {
      return new Promise((resolve, reject) => {
        try {
          this.total_previo_descuento = this.subtotal_documentos + this.subtotal_extras;
          resolve(this.total_previo_descuento);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },

    calculaDescuentoDocumentos() {
      return new Promise((resolve, reject) => {
        try {
          if (this.documentos_base.length <= 3) {
            resolve();
          }

          let documentos_base_ordenados = this.documentos_base.slice();

          documentos_base_ordenados = documentos_base_ordenados.sort((a, b) => {
            const precioA = a.totalDocumento;
            const precioB = b.totalDocumento;
            return precioB - precioA;
          });

          const subArray = documentos_base_ordenados.slice(3);
          const sum = subArray.reduce((accumulator, current) => {
            return accumulator + current.totalDocumento * this.precio_descuento_3er / 100;
          }, 0);

          this.descuento_documentos = parseFloat(sum.toFixed(2));;

          resolve(this.descuento_documentos);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },

    calculaSubtotalDescuento() {
      return new Promise((resolve, reject) => {
        try {
          this.descuento_documentos > 0 ? this.subtotal_descuento += this.descuento_documentos : this.subtotal_descuento;
          this.envioDomicilio ? this.subtotal_descuento += 500 : this.subtotal_descuento;
          resolve(this.subtotal_descuento);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },

    calculaTotalPostDescuentos() {
      return new Promise((resolve, reject) => {
        try {
          this.total_post_descuento = this.total_previo_descuento - this.subtotal_descuento;
          resolve(this.total_post_descuento);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },

    submitCotizacion() {
      const validadores = ['validacionDatos', 'validacionTerminos', 'validacionCompleto', 'validacionPdf']

      if (validadores.some(validador => !this[validador])) {
        this.showErrorToast('Las validaciones son obligatorias');
        return;
      }

      this.cotejo.envio_domicilio = this.envioDomicilio ? 1 : 0;
      this.urgencia ? this.cotejo.urgencia = 1 : this.cotejo.urgencia = 2;
      this.cotejo.costo_total = this.total_post_descuento;

      this.$emit('allChecksPassed');
    },

    async validarCupon(texto_cupon) {
      try {
        const time_zone = -(new Date().getTimezoneOffset() / 60);
        const data = {
          nombre: texto_cupon,
          timeZone: time_zone
        };
        const { id, isValid, tipo, descuento, message } = await CuponesApi.cuponValido(data);

        if (!isValid) {
          this.$toast.warning(message, {
            timeout: 4000,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });

          return;
        }

        const total = this.cotejo.costo_total;

        let precio_total = 0;

        if (tipo === "Porcentaje" && descuento < 1) {
          precio_total = total * (1 - descuento);
        } else if (tipo == "Monto") {
          precio_total = total - descuento;
        }

        this.cotejo.costo_total = precio_total;
        this.total_post_descuento = precio_total;
        this.cuponAplicado = true;

        if (!this.cotejo.cotizacion) {
          this.cotejo.cotizacion = {};
        }
        this.cotejo.cotizacion.cupones_id = id;

        // En este momento ya se deja al componenete padre actualizar o crear la cotizacion con el nuevo precio
        this.$toast.success("El cupón se aplicó correctamente", {
          timeout: 3000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } catch (error) {
        console.log(error);

        this.$toast.error("Ocurrió un error al aplicar el cupón.", {
          timeout: 3000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      }
    },

    borrarCupon() {
      this.cotejo.costo_total = this.costoTotalAntesCupon;
      this.cotejo.cotizacion.cupones_id = 0;
      this.nombreCuponDescuento = "";
      this.cuponAplicado = false;
    },
  }
}
</script>
