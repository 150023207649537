<template>
  <div class="container">

    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-img" title="" hide-footer id="modaldi"
      size="xl">
      <ModalPreviewImg :currentDocumento="currentDocumento" />
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-pdf" title="" hide-footer id="modaldf"
      size="xl">
      <ModalPreviewPDF :currentDocumento="currentDocumento" />
    </b-modal>

    <h4 class="cardD">Expediente</h4>

    <div>
      <b-table ref="table" hover :fields="fieldsDocumentos" :items="ratificacion.documentos">
        <template #cell(acciones)="{ item }">
          <b-button v-if="item.documento != ''" variant="primary" size="sm" v-b-tooltip.hover title="Vista previa"
            @click="previewDocumento(item)" class="editBtn">
            <b-icon-eye />
          </b-button>
        </template>
      </b-table>
    </div>

    <div class="mt-4">
      <div class="">
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
          :disabled="isButtonDisabled">Continuar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import ModalPreviewImg from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewImg.vue";
import ModalPreviewPDF from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewPDF.vue";

export default {
  name: "Expediente",

  components: {
    ModalPreviewImg,
    ModalPreviewPDF,
  },

  props: {
    ratificacion: { type: Object },
  },

  computed: {
    fieldsDocumentos() {
      let fieldsDocumentos = [
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" },
      ];
      return fieldsDocumentos;
    },

    isButtonDisabled() {
      return (this.ratificacion.estatus_id != 43);
    }
  },

  data() {
    return {
      currentDocumento: {},
    }
  },

  methods: {
    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    previewDocumento(item) {
      this.currentDocumento = item;
      const isCurrentDocumentImage = this.isCurrentDocumentAnImage()
      if (isCurrentDocumentImage) {
        this.$refs["documento-modal-preview-img"].show();
      } else {
        this.$refs["documento-modal-preview-pdf"].show();
      }
    },

    isCurrentDocumentAnImage() {
      let documentoSeparatedByDots = this.currentDocumento.nombre.split('.')
      let extensionDocumento = documentoSeparatedByDots[documentoSeparatedByDots.length - 1]
      const imageExtensions = ["jpg", "JPG", "jpeg", "png", "gif", "bmp", "webp", "svg", "tiff", "avif"]
      if (imageExtensions.includes(extensionDocumento)) {
        return true;
      }
      return false;
    },
  }
}
</script>
