<template>
  <div class="container">
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview" title="" hide-footer id="modaldf" size="xl">
      <ModalPreviewPDF :currentDocumento="currentDocumento" />
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="modal-regenerate" hide-footer id="modal-regenerate">
      <h4 class="text-center">
        ¿Está seguro de regenerar el Acta de Proyecto? En caso de haber una existente, será eliminada.
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-regenerate')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="generaActaProyecto()">OK</b-button>
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="entrega-documento-modal" title="" hide-footer id="modalC" size="xl">
      <div>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(postDocumento)">
            <ModalEntregaDocumento :currentDocumento="currentDocumento" :ratificacion="ratificacion" @submit.prevent />
          </form>
        </ValidationObserver>
      </div>
    </b-modal>
    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-word" title="" hide-footer id="modaldd"
      size="xl">
      <ModalPreviewDocx :currentDocumento="currentDocumento" />
    </b-modal>

    <h4 class="cardD">Actas de Proyecto</h4>

    <b-table ref="table" hover :fields="fieldsCuadro" :items="Actas">
      <template #cell(acciones)="{ item }">
        <b-button variant="primary" size="sm" v-b-tooltip.hover title="Vista previa" @click="openPreviewModal(item)"
          class="editBtn">
          <b-icon-eye />
        </b-button>
        <b-button v-if="[54, 72].includes(parseInt(ratificacion.estatus_id)) && parseInt(item.cat_documentos_id) != 70"
          variant="warning" size="sm" v-b-tooltip.hover title="Editar" @click="openEditDocumentoModal(item)"
          class="editBtn">
          <b-icon-upload />
        </b-button>
      </template>
    </b-table>

    <div class="mt-4">
      <div class="">
        <b-button class="btn-fill green-btn bottom1" size="m" @click="openModalRegenerate()">Regenerar Acta de
          Proyecto</b-button>
      </div>
      <div class="">
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
          :disabled="!documentosCompletos || !([42, 70].includes(parseInt(ratificacion.estatus_id)))">Continuar</b-button>
      </div>
    </div>

    <div hidden>
      <!-- Insertar aqui template cuando exista -->
    </div>
  </div>
</template>

<script>
// Modales
import ModalEntregaDocumento from "@/views/clients/procesos/ratificaciones/infoBase/modales/ModalEntregaDocumento.vue";
import ModalPreviewPDF from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewPDF.vue";
import ModalPreviewDocx from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewDocx.vue";


// API
import { DocumentosXServiciosApi } from "@/api";
import { DocumentosApi } from "@/api";
import { FuncionesApi } from "@/api";

export default {
  name: 'Actas',

  components: {
    ModalPreviewPDF,
    ModalEntregaDocumento,
    ModalPreviewDocx
  },

  props: {
    ratificacion: { type: Object }
  },

  computed: {
    fieldsCuadro() {
      let fieldsCuadro = [
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" },
      ];
      return fieldsCuadro;
    }
  },

  created() {
    this.loading = true;
    this.selectActasFromDocumentos()
      .then(() => this.generaPrimerasActas())
      .then(() => this.areDocumentosCompletos())
      .catch((error) => {
        console.log(error);
        this.showErrorToast('No se pudieron obtener los documentos. Intente más tarde.')
      })
      .finally(() => { this.loading = false; })
  },

  data() {
    return {
      selectedDocumento: null,
      currentDocumento: null,
      currentDocumentoId: null,
      documentosCompletos: false,


      proyectoActa: null,
      datos: {},
      actaKey: 0,

      Actas: [],

      style_pdf: `<style>
        </style>`,
    }
  },

  methods: {
    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    openPreviewModal(item) {
      this.currentDocumento = item;
      this.$refs["documento-modal-preview"].show();
    },

    closePreviewModal() {
      this.$refs["documentos-modal-preview"].hide();
    },

    openModalRegenerate() {
      this.$refs['modal-regenerate'].show();
    },

    closeModalRegenerate() {
      this.$refs['modal-regenerate'].hide();
    },

    openDocxModal() {
      this.$refs["documento-modal-preview-word"].show();
    },

    hideDocxModal() {
      this.$refs["documento-modal-preview-word"].hide();
    },

    selectActasFromDocumentos() {
      return new Promise(resolve => {
        this.Actas = this.ratificacion.documentos
          .filter(doc => parseInt(doc.cat_documentos_id) >= 70 && parseInt(doc.cat_documentos_id) <= 72)
          .sort((a, b) => parseInt(a.cat_documentos_id) - parseInt(b.cat_documentos_id));
        resolve(this.Actas);
      });
    },

    async generaPrimerasActas() {
      let promises = []

      const documents = [
        { id: 70, name: 'Acta Generada' },
        { id: 71, name: 'Acta Word' },
        { id: 72, name: 'Acta Pdf' },
      ];

      documents.forEach(doc => {
        if (!this.ratificacion.documentos.some(item => parseInt(item.cat_documentos_id) === doc.id)) {
          promises.push(this.postDocumento(doc.id, null, null, null, doc.name));
        }
      });

      if (promises.length > 0) {
        await Promise.all(promises);
        location.reload();
      }
      else {
        return;
      }
    },

    async postDocumento(cat_documentos_id, auto_referencia, descripcion, paginas, nombre) {
      const { id } = await DocumentosApi.create({
        documento: "",
        cat_documentos_id: cat_documentos_id,
        auto_referencia: auto_referencia,
        descripcion: descripcion,
        paginas: paginas,
        copias_certificadas: null,
        nombre: nombre
      })
      await DocumentosXServiciosApi.create({
        documentos_id: id,
        cat_servicios_id: 6,
        proceso_id: this.ratificacion.ratificaciones_id
      })
      return id;
    },

    areDocumentosCompletos() {
      if (this.ratificacion.documentos.length === 0) {
        return;
      }

      this.documentosCompletos = this.ratificacion.documentos.every(documento => documento.documento !== "");
    },

    generaActaProyecto() {
      this.closeModalRegenerate();
      console.log('Aqui se regenera el acta del proyecto.');
      return;
    },



  }

}
</script>
