<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <ValidationProvider name="password"
          :rules="{ required: true, min: 8, max: 20, regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).+$/ }"
          v-slot="{ errors }" :custom-messages="{
            required: 'La contraseña es obligatoria',
            min: 'La contraseña debe tener al menos 8 caracteres',
            max: 'La contraseña debe tener máximo 20 caracteres',
            regex: 'La contraseña debe tener al menos una letra mayúscula, una minúscula, un número y un caracter especial',
          }">
          <base-input type="password" label="Contraseña" placeholder="Contraseña" v-model="user.password">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-6">
        <ValidationProvider name="confirm_password" rules="required|confirmed:password" v-slot="{ errors }"
          :custom-messages="{
            required: 'La confirmación de contraseña es obligatoria',
            confirmed: 'La contraseña no coincide',
          }">
          <base-input type="password" label="Confirmar Contraseña" placeholder="Confirmar Contraseña"
            v-model="user.confirm_password">
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <button type="submit" class="btn orange-btn float-right">Cambiar</button>
  </div>
</template>
<script>
export default {
  name: "Form",

  props: {
    user: { type: Object },
  },

  data() {
    return {
      form: {
        password: null,
        confirm_password: null,
      },
    };
  },
};
</script>

<style>
/* .card {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a;
  color: #ea640b;
  font-size: 13px;
} */
</style>
