<template>
  <div class="container">

    <h4 class="cardD">Pago</h4>

    <b-tabs fill>
      <b-tab title="Tarjeta de crédito o débito" active>
        <Openpay :cotizacion_id="notificacion.id_cotizaciones" :precio_total="notificacion.precio_total" @submit.prevent
          @payCotizacion="payCotizacion($event)" />
      </b-tab>
      <b-tab title="SPEI/Transferencia" class="modalPay">
        <Spei :cotizacion_id="notificacion.id_cotizaciones" :precio_total="notificacion.precio_total"
          :proceso_id="notificacion.id" :tipo="'notificacion'" @submit.prevent
          @payCotizacionSpei="payCotizacionSpei($event)" />
      </b-tab>
      <b-tab title="Monedero" class="modalPay">
        <Monedero :cotizacion_id="notificacion.id_cotizaciones" :precio_total="notificacion.precio_total"
          :clientes_id="notificacion.id_clientes" :servicio="'Notificacion ' + notificacion.id" @submit.prevent
          @payCotizacion="payCotizacionMonedero($event)" />
      </b-tab>
    </b-tabs>

    <!-- <div v-if="isPayed">
      <p>
        Su pago será procesado a través de <a href="https://www.openpay.mx" target="_blank">Openpay</a>, una plataforma
        segura y confiable para realizar transacciones electrónicas.
        <a href="https://www.openpay.mx" target="_blank">Openpay</a> garantiza la protección de sus datos y la
        integridad de la transacción. Para más información sobre Openpay y sus servicios, puede visitar su
        <a href="https://www.openpay.mx/servicios" target="_blank">página de servicios</a> o leer más sobre su
        <a href="https://www.openpay.mx/nosotros" target="_blank">empresa</a>.
      </p>
      <div>
        <div class="">
          <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
            :disabled="notificacion.id_estatus != 7">Continuar al pago</b-button>
        </div>
      </div>
    </div>

    <div v-else>
      <div v-if="forma_pago == 2">
        <p>
          Se envío un correo con la información de pago a la dirección de correo electrónico registrada en el sistema.
          Por favor revise su bandeja de entrada y siga las instrucciones para completar el pago.
        </p>
      </div>
    </div> -->

  </div>
</template>

<script>
import Monedero from '@/views/openpay/Monedero.vue';
import Openpay from '@/views/openpay/Openpay.vue';
import Spei from '@/views/openpay/Spei.vue';

export default {
  name: "PagoNotificacionesComponent",

  components: {
    Openpay,
    Spei,
    Monedero
  },

  props: {
    notificacion: { type: Object },
    pagado: { type: Number, default: null },
    forma_pago: { type: Number, default: null },
  },

  computed: {
    isPayed() {
      return this.pagado == null
    }
  },

  methods: {
    payCotizacion() {
      this.$emit('pay-cotizacion', true);
    },
    payCotizacionSpei(payed) {
      this.$emit('pay-cotizacion-spei', payed);
    },
    payCotizacionMonedero() {
      this.$emit('pay-cotizacion-monedero', true);
    }
  }
}
</script>
