<template>
  <div class="content">
    <div class="container-fluid mt-5 mb-5">
      <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-4">
          <h3 class="text-warning">Para mayor información contáctanos</h3>
          <h5>Te apoyaremos de la mejor manera</h5>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-1"></div>
        <div class="col-md-4">
          <div class="mt-3"></div>
          <hr />
          <!-- <p>
            <font-awesome-icon icon="phone" />
            +52 (55) 5555-5555
          </p> -->
          <p>
            <font-awesome-icon icon="envelope" />
            info@correduriadigital.mx
          </p>
          <p>
            <font-awesome-icon icon="building" />
            Av. Paseo de las Palmas, Oficina 204, Lomas de Chapultepec, CDMX, México
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactoComponent",
};
</script>
