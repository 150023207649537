<template>
  <div>
    <ValidationObserver v-slot="{ invalid }">
      <form @submit.prevent="onSubmit">
        <div class="row">
          <div class="col-md-12">
            <ValidationProvider name="persona" rules="required" v-slot="{ errors }"
              :custom-messages="{ required: 'La persona es obligatoria' }">
              <b-form-group id="input-group-1" label="Persona:" label-for="input-1">
                <b-form-select id="input-1" class="custom-select" v-model="tipoPersona" :options="optionsPersona">
                  <template #first>
                    <b-form-select-option :value="null" disabled>-- Seleccione una opción --</b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
              <span class="cardErrors">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div v-if="tipoPersona === 'Física'">
          <div class="row mt-4">
            <div class="col-md-4">
              <ValidationProvider name="nombre" rules="required" v-slot="{ errors }"
                :custom-messages="{ required: 'El nombre es obligatorio' }">
                <b-form-group id="input-group-2" label="Nombre:" label-for="input-2">
                  <b-form-input id="input-2" v-model="solicitantePersonaFisica.nombre" type="text"
                    placeholder="Nombre"></b-form-input>
                </b-form-group>
                <span class="cardErrors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="col-md-4">
              <ValidationProvider name="nombre" rules="required" v-slot="{ errors }"
                :custom-messages="{ required: 'El apellido paterno es obligatorio' }">
                <b-form-group id="input-group-3" label="Apellido paterno:" label-for="input-3">
                  <b-form-input id="input-3" v-model="solicitantePersonaFisica.paterno" type="text"
                    placeholder="Paterno"></b-form-input>
                </b-form-group>
                <span class="cardErrors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="col-md-4">
              <b-form-group id="input-group-4" label="Apellido materno:" label-for="input-4">
                <b-form-input id="input-4" v-model="solicitantePersonaFisica.materno" type="text"
                  placeholder="Materno"></b-form-input>
              </b-form-group>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-4">
              <b-form-group id="input-group-actua-derecho-propio" label="Actúa por derecho propio:"
                label-for="input-actua-derecho-propio">
                <b-form-checkbox id="input-actua-derecho-propio" v-model="actuaDerechoPropio"></b-form-checkbox>
              </b-form-group>
              <!-- <ValidationProvider name="nombre" rules="required" v-slot="{ errors }"
                :custom-messages="{ required: 'Campo obligatorio' }">
                <b-form-group id="input-group-actua-derecho-propio" label="Actúa por derecho propio:"
                  label-for="input-actua-derecho-propio">
                  <b-form-checkbox id="input-actua-derecho-propio" v-model="actuaDerechoPropio"></b-form-checkbox>
                </b-form-group>
                <div class="row">
                  <span class="cardErrors">{{ errors[0] }}</span>
                </div>
              </ValidationProvider> -->
            </div>
          </div>
        </div>

        <div class="mt-4" v-else-if="tipoPersona === 'Moral'">
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider name="denominacion_social" rules="required" v-slot="{ errors }"
                :custom-messages="{ required: 'La denominacion social es obligatoria' }">
                <b-form-group id="input-group-5" :label="denominacionSocialLabel" label-for="input-5">
                  <b-form-input id="input-5" v-model="solicitantePersonaMoral.denominacion_social" type="text"
                    placeholder="Denominación social"></b-form-input>
                </b-form-group>
                <span class="cardErrors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <div class="row mt-4"></div>

        <div class="mt-4 text-center">
          <button class="btn btn-fill float-right orange-btn" type="submit" :disabled="invalid">
            Guardar
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: "AgregaSolicitante",

  props: {
    currentSolicitante: { type: Object },
    proceso: {
      type: Number,
      required: true
    }
  },

  data: function () {
    return ({
      optionsPersona: [{ text: "Persona Física", value: "Física" }, { text: "Persona Moral", value: "Moral" }],
      denominacionSocialLabel: this.proceso === 4 ? "Denominación social (Incluir tipo de Sociedad):" : "Denominación social:",

      tipoPersona: null,
      actuaDerechoPropio: null,

      solicitantePersonaFisica: {
        nombre: "",
        paterno: "",
        materno: "",
        numero: ""
      },
      solicitantePersonaMoral: {
        denominacion_social: "",
      },
    });
  },

  created: function () {
    this.initProperties();
  },

  computed: {
  },

  watch: {
    // "tipoPersona": function (value) {
    //   if (value === "Física") {
    //     this.actuaDerechoPropio = true;
    //   } else if (value === "Moral") {
    //     this.actuaDerechoPropio = false;
    //   } else {
    //     this.actuaDerechoPropio = null;
    //   }
    // }
  },

  methods: {
    initProperties() {
      if (!this.currentSolicitante.tipoPersona) {
        this.tipoPersona = null;
        return;
      }

      if (this.currentSolicitante.tipoPersona === "Física") {
        this.tipoPersona = "Física";
        this.actuaDerechoPropio = this.currentSolicitante.actua_derecho_propio;
        this.solicitantePersonaFisica.nombre = this.currentSolicitante.nombre || "";
        this.solicitantePersonaFisica.paterno = this.currentSolicitante.paterno || "";
        this.solicitantePersonaFisica.materno = this.currentSolicitante.materno || "";

        if (this.currentSolicitante.actua_derecho_propio === false) {
          this.actuaDerechoPropio = false;
        } else {
          this.actuaDerechoPropio = true;
        }
      } else {
        this.tipoPersona = "Moral";
        this.actuaDerechoPropio = false;
        this.solicitantePersonaMoral.denominacion_social = this.currentSolicitante.denominacion_social || "";
      }
    },

    onSubmit() {
      if (!this.tipoPersona) {
        return;
      }

      const solicitante = {
        id: this.currentSolicitante.id,
        tipoPersona: this.tipoPersona,
        actua_derecho_propio: this.actuaDerechoPropio
      };

      if (this.actuaDerechoPropio) {
        solicitante.actua_derecho_propio = true;
      } else {
        solicitante.actua_derecho_propio = false;
      }

      if (this.tipoPersona === "Física") {
        Object.assign(solicitante, this.solicitantePersonaFisica);
      } else if (this.tipoPersona === "Moral") {
        Object.assign(solicitante, this.solicitantePersonaMoral);
      }

      this.$emit('submit-form', solicitante);
    }
  }
}
</script>
