var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"denominacion_social","rules":"required","custom-messages":{
        required: 'La denominación social es obligatoria'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"disabled":_vm.user.disabled,"type":"text","label":"Denominación Social","placeholder":"Denominación Social"},model:{value:(_vm.user.denominacion_social),callback:function ($$v) {_vm.$set(_vm.user, "denominacion_social", $$v)},expression:"user.denominacion_social"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"rfc","rules":"required|length:12","custom-messages":{
        required: 'El RFC es obligatorio',
        length: 'Debe tener 12 caracteres'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"disabled":_vm.user.disabled,"type":"text","label":"RFC","placeholder":"RFC"},model:{value:(_vm.user.rfc),callback:function ($$v) {_vm.$set(_vm.user, "rfc", $$v)},expression:"user.rfc"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"fecha_constitucion","rules":"required","custom-messages":{
        required: 'La fecha de constitución es obligatoria'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"disabled":_vm.user.disabled,"type":"date","label":"Fecha de constitución","placeholder":"Fecha de constitución"},model:{value:(_vm.user.fecha_constitucion),callback:function ($$v) {_vm.$set(_vm.user, "fecha_constitucion", $$v)},expression:"user.fecha_constitucion"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"nacionalidad","rules":"required","custom-messages":{required: 'La nacionalidad es obligatoria'}},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',[_vm._v("Nacionalidades")]),_c('v-select',{attrs:{"disabled":_vm.user.disabled,"multiple":"","label":"nacionalidad","options":_vm.optionsNacionalidades},model:{value:(_vm.user.nacionalidades),callback:function ($$v) {_vm.$set(_vm.user, "nacionalidades", $$v)},expression:"user.nacionalidades"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"actividad","rules":"required","custom-messages":{
        required: 'La actividad es obligatoria'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"disabled":_vm.user.disabled,"type":"text","label":"Actividad","placeholder":"Actividad"},model:{value:(_vm.user.actividad),callback:function ($$v) {_vm.$set(_vm.user, "actividad", $$v)},expression:"user.actividad"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"giro","rules":"required","custom-messages":{ required: 'El giro es obligatorio' }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"disabled":_vm.user.disabled,"type":"text","label":"Giro","placeholder":"Giro"},model:{value:(_vm.user.giro),callback:function ($$v) {_vm.$set(_vm.user, "giro", $$v)},expression:"user.giro"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"objeto_social","rules":"required","custom-messages":{
        required: 'El objeto social es obligatorio'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',[_vm._v("Objeto Social")]),_c('b-form-textarea',{attrs:{"disabled":_vm.user.disabled,"type":"text","placeholder":"Objeto Social"},model:{value:(_vm.user.objeto_social),callback:function ($$v) {_vm.$set(_vm.user, "objeto_social", $$v)},expression:"user.objeto_social"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('br'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"instrumento_numero","rules":"required","custom-messages":{
        required: 'El número de instrumento es obligatorio'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"disabled":_vm.user.disabled,"type":"text","label":"Instrumento Constitutivo: Número","placeholder":"Número"},model:{value:(_vm.user.instrumento_numero),callback:function ($$v) {_vm.$set(_vm.user, "instrumento_numero", $$v)},expression:"user.instrumento_numero"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"instrumento_numero_fecha","rules":"required","custom-messages":{
        required: 'La fecha es obligatoria'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"disabled":_vm.user.disabled,"type":"date","label":"De Fecha","placeholder":"De Fecha"},model:{value:(_vm.user.instrumento_numero_fecha),callback:function ($$v) {_vm.$set(_vm.user, "instrumento_numero_fecha", $$v)},expression:"user.instrumento_numero_fecha"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-2"},[_c('ValidationProvider',{attrs:{"name":"instrumento_fedatario","rules":"required","custom-messages":{
        required: 'El tipo de fedatario es obligatorio'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',[_vm._v("Tipo de Fedatario")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.instrumento_fedatario),expression:"user.instrumento_fedatario"}],staticClass:"custom-select",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.user, "instrumento_fedatario", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"Notario"}},[_vm._v("Notario")]),_c('option',{attrs:{"value":"Corredor Público"}},[_vm._v("Corredor Público")])]),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"instrumento_fedatario_nombre","rules":"required","custom-messages":{
        required: 'El nombre del fedatario es obligatorio'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"disabled":_vm.user.disabled,"type":"text","label":"Nombre de Fedatario","placeholder":"Nombre de Fedatario"},model:{value:(_vm.user.instrumento_fedatario_nombre),callback:function ($$v) {_vm.$set(_vm.user, "instrumento_fedatario_nombre", $$v)},expression:"user.instrumento_fedatario_nombre"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-3"},[_c('ValidationProvider',{attrs:{"name":"instrumento_fedatario_numero","rules":"required","custom-messages":{
        required: 'El número es obligatorio'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"disabled":_vm.user.disabled,"type":"text","label":"Número","placeholder":"Número"},model:{value:(_vm.user.instrumento_fedatario_numero),callback:function ($$v) {_vm.$set(_vm.user, "instrumento_fedatario_numero", $$v)},expression:"user.instrumento_fedatario_numero"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-3"},[_c('ValidationProvider',{attrs:{"name":"instrumento_fedatario_estado","rules":"required","custom-messages":{
        required: 'El estado es obligatorio'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',[_vm._v("Entidad Federativa")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.instrumento_fedatario_estado),expression:"user.instrumento_fedatario_estado"}],staticClass:"custom-select",attrs:{"disabled":_vm.user.disabled},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.user, "instrumento_fedatario_estado", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.optionsEstados),function(option){return _c('option',{key:option.value},[_vm._v(" "+_vm._s(option.text)+" ")])}),0),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"instrumento_rpf_folio","rules":"required","custom-messages":{
        required: 'El RPF Folio es obligatorio'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"disabled":_vm.user.disabled,"type":"text","label":"RPF Folio","placeholder":"RPF Folio"},model:{value:(_vm.user.instrumento_rpf_folio),callback:function ($$v) {_vm.$set(_vm.user, "instrumento_rpf_folio", $$v)},expression:"user.instrumento_rpf_folio"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"instrumento_rpf_folio_fecha","rules":"required","custom-messages":{
        required: 'La fecha es obligatoria'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"disabled":_vm.user.disabled,"type":"date","label":"De Fecha","placeholder":"De Fecha"},model:{value:(_vm.user.instrumento_rpf_folio_fecha),callback:function ($$v) {_vm.$set(_vm.user, "instrumento_rpf_folio_fecha", $$v)},expression:"user.instrumento_rpf_folio_fecha"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"instrumento_rpf_folio_lugar","rules":"required","custom-messages":{
        required: 'El lugar es obligatorio'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"disabled":_vm.user.disabled,"type":"text","label":"Lugar","placeholder":"Lugar"},model:{value:(_vm.user.instrumento_rpf_folio_lugar),callback:function ($$v) {_vm.$set(_vm.user, "instrumento_rpf_folio_lugar", $$v)},expression:"user.instrumento_rpf_folio_lugar"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('h4',{staticClass:"cardD"},[_vm._v("Datos de identificación del Fideicomiso")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"name":"fiduciario","rules":"required","custom-messages":{
        required: 'El fiduciario es obligatorio'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"disabled":_vm.user.disabled,"type":"text","label":"Fiduciario","placeholder":"Fiduciario (denominación social completa)"},model:{value:(_vm.user.fiduciario),callback:function ($$v) {_vm.$set(_vm.user, "fiduciario", $$v)},expression:"user.fiduciario"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"nombre_fideicomiso","rules":"required","custom-messages":{
        required: 'El Nombre o número de identificación es obligatorio'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"disabled":_vm.user.disabled,"type":"text","label":"Nombre o número de identificación","placeholder":"Nombre o número de identificación del fideicomiso"},model:{value:(_vm.user.nombre_fideicomiso),callback:function ($$v) {_vm.$set(_vm.user, "nombre_fideicomiso", $$v)},expression:"user.nombre_fideicomiso"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"fideicomitente","rules":"required","custom-messages":{
        required: 'El fideicomitente principal es obligatorio'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"disabled":_vm.user.disabled,"type":"text","label":"Fideicomitente principal","placeholder":"Fideicomitente principal"},model:{value:(_vm.user.fideicomitente),callback:function ($$v) {_vm.$set(_vm.user, "fideicomitente", $$v)},expression:"user.fideicomitente"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"tipo_fideicomiso","rules":"required","custom-messages":{
        required: 'El tipo de fideicomiso es obligatorio'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('label',{attrs:{"for":""}},[_vm._v("Tipo")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.tipo_fideicomiso),expression:"user.tipo_fideicomiso"}],staticClass:"custom-select",attrs:{"disabled":_vm.user.disabled},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.user, "tipo_fideicomiso", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"Administración"}},[_vm._v("Administración")]),_c('option',{attrs:{"value":"Garantía"}},[_vm._v("Garantía")]),_c('option',{attrs:{"value":"Fuente de Pago"}},[_vm._v("Fuente de Pago")]),_c('option',{attrs:{"value":"Empresarial"}},[_vm._v("Empresarial")]),_c('option',{attrs:{"value":"Otro"}},[_vm._v("Otro")])]),(_vm.user.tipo_fideicomiso === 'Otro')?_c('base-input',{staticClass:"mt-2",attrs:{"type":"text","label":"","placeholder":"Tipo","disabled":_vm.user.disabled},model:{value:(_vm.user.tipo_fideicomiso_otro),callback:function ($$v) {_vm.$set(_vm.user, "tipo_fideicomiso_otro", $$v)},expression:"user.tipo_fideicomiso_otro"}}):_vm._e(),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('ValidationProvider',{attrs:{"name":"fecha_creacion_fideicomiso","rules":"required","custom-messages":{
        required: 'La fecha de creación es obligatoria'
      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('base-input',{attrs:{"disabled":_vm.user.disabled,"type":"date","label":"Fecha de creación","placeholder":"Fecha de creación del fideicomiso"},model:{value:(_vm.user.fecha_creacion_fideicomiso),callback:function ($$v) {_vm.$set(_vm.user, "fecha_creacion_fideicomiso", $$v)},expression:"user.fecha_creacion_fideicomiso"}}),_c('span',{staticClass:"cardErrors"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-4"},[_c('base-input',{attrs:{"disabled":_vm.user.disabled,"type":"text","label":"Folio de inscripción en el RUG","placeholder":"Folio de inscripción en el RUG"},model:{value:(_vm.user.folio_inscripcion),callback:function ($$v) {_vm.$set(_vm.user, "folio_inscripcion", $$v)},expression:"user.folio_inscripcion"}})],1),_c('div',{staticClass:"col-md-4"},[_c('base-input',{attrs:{"disabled":_vm.user.disabled,"type":"text","label":"RFC","placeholder":"RFC del Fideicomiso (en su caso)"},model:{value:(_vm.user.rfc_fideicomiso),callback:function ($$v) {_vm.$set(_vm.user, "rfc_fideicomiso", $$v)},expression:"user.rfc_fideicomiso"}})],1)]),_c('b-button',{staticClass:"btn-fill orange-btn bottom",attrs:{"type":"submit","size":"lg","disabled":_vm.user.disabled}},[_vm._v("Guardar")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }