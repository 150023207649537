<template>
  <div class="content" ref="content">
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ path: '/dashboard' }">Inicio</b-breadcrumb-item>
      <b-breadcrumb-item active>Editar Copias Certificadas</b-breadcrumb-item>
    </b-breadcrumb>

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-route" hide-footer id="modal-confirm-dialog-route">
      <h4 class="text-center">
        ¿Seguro que quiere salir de la página?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog-route')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="beforeLeave(true)">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">
        ¿Seguro que quiere salir sin guardar los cambios?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="pago-modal" title="" hide-footer id="modalC" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(saveCotejo)">
          <ModalPago :cotejo="cotejo" @submit.prevent />
        </form>
      </ValidationObserver>
    </b-modal>

    <b-modal no-close-on-backdrop id="modal-help-cotejos-2" size="xl" centered hide-footer>
      <div>
        <b-carousel id="carousel-cotejos-2" :interval="0" controls indicators background="#ababab">
          <b-carousel-slide :img-src="require('@/assets/img/cotejos/help/2_1.webp')"></b-carousel-slide>
          <b-carousel-slide :img-src="require('@/assets/img/cotejos/help/2_2.webp')"></b-carousel-slide>
          <b-carousel-slide :img-src="require('@/assets/img/cotejos/help/2_3.webp')"></b-carousel-slide>
        </b-carousel>
      </div>
    </b-modal>

    <div class="container-fluid">
      <b-row>
        <b-col class="col-3">
          <b-card>
            <TreeEditCotejo :cotejo="cotejo" :user="'Client'" :selected-component="currentComponentBody"
              @node="selected" />
          </b-card>
          <div>
            <b-img v-if="currentComponentBody === 'Solicitantes'" :src="require('@/assets/img/cotejos/help/1.webp')"
              fluid-grow alt="Descripción del solicitante"></b-img>
            <b-img v-if="currentComponentBody === 'Documentos'" :src="require('@/assets/img/cotejos/help/2.webp')"
              fluid-grow alt="Descripción de los documentos"></b-img>
            <b-button v-if="currentComponentBody === 'Documentos'" class="orange-btn" block
              @click="$bvModal.show('modal-help-cotejos-2')">
              Más información
            </b-button>
          </div>
        </b-col>
        <b-col class="col-9">
          <b-card>
            <b-row>
              <b-col class="col-12">
                <div v-if="currentComponentBody === 'Pago'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="pago" @submit.prevent="handleSubmit(pagoGeneradoHandler())">
                      <Pago :cotejo="cotejo" @submit.prevent @allChecksPassed="submitForm('pago')"
                        @toggleOnLoadingEvent="toggleOnLoadingHandler"
                        @toggleOffLoadingEvent="toggleOffLoadingHandler" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Solicitantes'">
                  <ComplementaSolicitante :cotejo="cotejo" @allChecksPassed="solicitanteCompletoHandler()" />
                </div>
                <div v-if="currentComponentBody === 'Documentos'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="documentos" @submit.prevent="handleSubmit(documentosCompletos())">
                      <EntregaDocumentos :cotejo="cotejo" @submit.prevent @toggleOnLoadingEvent="toggleOnLoadingHandler"
                        @toggleOffLoadingEvent="toggleOffLoadingHandler" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'Domicilio'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="domicilio" @submit.prevent="handleSubmit()">
                      <Domicilio :cotejo="cotejo" @submit.prevent @allChecksPassed="domicilioCompleto()" />
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="currentComponentBody === 'EsperaValidacion'">
                  <EsperaValidacion />
                </div>
                <div v-if="currentComponentBody === 'PagoComplementario'">
                  <ValidationObserver v-slot="{ handleSubmit }">
                    <form ref="pagocomplementario" @submit.prevent="handleSubmit()">
                      <PagoComplementario :cotejo="cotejo" @submit.prevent
                        @toggleOnLoadingEvent="toggleOnLoadingHandler"
                        @toggleOffLoadingEvent="toggleOffLoadingHandler" />
                    </form>
                  </ValidationObserver>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import EsperaValidacion from "@/components/EsperaValidacion.vue";
import Domicilio from "@/views/clients/procesos/cotejo/Domicilio.vue";
import EntregaDocumentos from "@/views/clients/procesos/cotejo/EntregaDocumentos.vue";
import Pago from "@/views/clients/procesos/cotejo/Pago.vue";
import PagoComplementario from "@/views/clients/procesos/cotejo/PagoComplementario.vue";
import TreeEditCotejo from "@/views/clients/procesos/cotejo/TreeEditCotejo.vue";
import ComplementaSolicitante from "@/views/clients/procesos/cotejo/ComplementaSolicitante.vue";

import { CatDocumentosApi, ComparecientesApi, CotejosApi, CotizacionesApi, DocumentosApi, DocumentosXServiciosApi, DomiciliosApi } from "@/api";
import loadingMixin from "@/mixins/loadingMixin";

export default {
  name: "EditCotejo",

  components: {
    TreeEditCotejo,
    Pago,
    EntregaDocumentos,
    PagoComplementario,
    Domicilio,
    EsperaValidacion,
    ComplementaSolicitante
  },

  mixins: [loadingMixin],

  created() {
    this.loading = true;

    this.cotejo.id = this.$route.params.id;

    this.getCotejo()
      .then(() => this.getCotizacion())
      .then(() => this.addSolicitanteFromCompareciente())
      .then(() => this.addRepresentanteIfMoral())
      .then(() => this.fillNombresCompletos())
      .then(() => this.getCatDocumentosCotejos())
      .then(() => this.getDocumentosXServiciosCotejos())
      .then(() => this.getDocumentosCotejos())
      .then(() => this.addTipoToDocumentos())
      .then(() => this.getDomicilio())
      .then(() => this.getCotizacionComplementaria())
      .then(() => this.getEstatus())
      .catch((error) => {
        this.showErrorToast('Sucedió un error, intente más tarde');
        console.log(error);
      })
      .finally(() => { this.loading = false; })
  },

  beforeRouteLeave(to, from, next) {
    if (to.path.includes("editcomparecientes")) {
      next();
      return;
    }

    this.$refs["modal-confirm-dialog-route"].show();
    this.next = next;
  },

  data: () => ({
    next: null,
    prenode: null,

    currentComponentBody: "Pago",

    cotejo: {
      id: null,
      id_estatus: null,
      id_prioridad: null,
      id_cotizaciones: null,
      id_clientes: null,
      id_comparecientes: null,
      pago_complementario: null,
      id_cotizaciones_complementario: null,
      envio_domicilio: null,
      domicilios_id: null,
      corredurias_id: null,
      validaSolicitantes: null,
      validaDomicilio: null,
      validaDocumentos: null,
      id_comparecientes_representante: null,

      solicitantes: [],
      documentos: [],
      precio_total: null,
      domicilio: null,
      liga_pago_complementario: null,

      cartaResponsiva: null,

      currentComponentValues: {
        Pago: false,
        Solicitantes: false,
        Domicilio: false,
        Documentos: false,
        PagoComplementario: false,
        Terminado: false,
        EsperaValidacion: false
      },

      solicitante: null,
    },

    catDocumentos: [],
    documentosXServicios: [],

    datos: {},
    cartaKey: 0,
  }),

  methods: {
    async beforeLeave(answer) {
      if (answer) {
        this.next();
      }
    },

    pagoGeneradoHandler() {
      if (this.cotejo.envio_domicilio) {
        this.changeCurrentComponentBody('Domicilio');

        return;
      }

      this.changeCurrentComponentBody('Solicitantes');
    },

    solicitanteCompletoHandler(){
      this.changeCurrentComponentBody('Documentos');
    },

    async selected(node) {
      const keys = Object.keys(this.cotejo.currentComponentValues);
      const values = Object.values(this.cotejo.currentComponentValues);
      const dest = keys.find(value => value == node);
      const pagoIndex = keys.findIndex(value => value === 'Pago');
      const currentIndex = keys.findIndex(value => value == this.currentComponentBody);
      const destIndex = keys.findIndex(value => value == node);
      const lastCompletedIndex = values.lastIndexOf(true);
      // Ir mas adelante
      if (destIndex > currentIndex) {
        // Dest verde
        if (this.cotejo.currentComponentValues[dest]) {
          this.currentComponentBody = dest;
        }
        // Dest amarillo
        else {
          var lastCompleted = keys[lastCompletedIndex + 1];
          if ((destIndex - lastCompletedIndex) === 1) {
            this.currentComponentBody = dest;
          }
          else {
            if (lastCompletedIndex === -1) {
              lastCompleted = keys[0]
            }
            // this.$toast.warning("Completa la sección " + lastCompleted + " para poder continuar", {
            this.$toast.warning("Completa la sección para poder continuar", {
              timeout: 3000, //false,
              hideProgressBar: false,
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              closeButton: "button",
              icon: true,
            });
          }
        }
      }
      // Ir mas atras
      else {
        if (destIndex > pagoIndex) {
          this.currentComponentBody = dest;
        }
      }
    },


    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    async getCotejo() {
      this.cotejo = {
        ...this.cotejo,
        ...await CotejosApi.detail(this.cotejo.id)
      };
    },

    async getCotizacion() {
      const { precio_total } = await CotizacionesApi.detail(this.cotejo.id_cotizaciones);
      this.cotejo.precio_total = precio_total;
      return;
    },

    async addSolicitanteFromCompareciente() {
      const compareciente = await ComparecientesApi.detail(this.cotejo.id_comparecientes);
      this.cotejo.solicitantes.push(compareciente);
    },

    async addRepresentanteIfMoral() {
      if (this.cotejo.id_comparecientes_representante) {
        const representante = await ComparecientesApi.detail(this.cotejo.id_comparecientes_representante);
        this.cotejo.solicitantes.push(representante);
      }
    },

    async fillNombresCompletos() {
      this.cotejo.solicitantes.forEach(solicitante => {
        if (solicitante.persona === 'Física') {
          solicitante.nombreCompleto = `${solicitante.nombre} ${solicitante.paterno}`;
          solicitante.materno ? solicitante.nombreCompleto += ` ${solicitante.materno}` : solicitante.nombreCompleto;
        } else {
          solicitante.nombreCompleto = solicitante.denominacion_social;
        }
      })
    },

    async getCatDocumentosCotejos() {
      const { value } = await CatDocumentosApi.list({
        page: 0,
        registersPerPage: 0,
        categoria: "cotejos"
      });

      this.catDocumentos = value;
    },

    async getDocumentosXServiciosCotejos() {
      const { value } = await DocumentosXServiciosApi.list({
        page: 0,
        registersPerPage: 0,
        cat_servicios_id: 4,
        id_proceso: this.cotejo.id
      });

      this.documentosXServicios = value;
    },

    async getDocumentosCotejos() {
      this.cotejo.documentos = await Promise.all(
        this.documentosXServicios.map(documentoXServicio =>
          this.getDocumentoDetail(documentoXServicio.documentos_id)
        )
      );
    },

    async getDocumentoDetail(id) {
      return DocumentosApi.detail(id);
    },

    async addTipoToDocumentos() {
      this.cotejo.documentos = await Promise.all(
        this.cotejo.documentos.map(async documento => {
          documento.tipo = this.getCatDocumentoNombreById(documento.cat_documentos_id);
          return documento;
        })
      );
    },

    getCatDocumentoNombreById(cat_documentos_id) {
      const catDocumento = this.catDocumentos.find(doc => doc.id == cat_documentos_id);
      return catDocumento ? catDocumento.documento : null;
    },

    async getDomicilio() {
      this.cotejo.domicilio = await DomiciliosApi.detail(this.cotejo.domicilios_id);
    },

    async getCotizacionComplementaria() {
      if (this.cotejo.id_cotizaciones_complementario == null) {
        return;
      }

      const { liga_pago } = await CotizacionesApi.detail(this.cotejo.id_cotizaciones_complementario);
      this.cotejo.liga_pago_complementario = liga_pago;
    },

    getEstatus() {
      const keys = Object.keys(this.cotejo.currentComponentValues);
      let currentIndex = keys.findIndex(value => value === 'Pago');

      if (this.cotejo.envio_domicilio && this.cotejo.id_estatus == 15) {
        this.currentComponentBody = "Domicilio";
        currentIndex = keys.findIndex(value => value === 'Domicilio')
      }

      if (!this.cotejo.envio_domicilio && this.cotejo.id_estatus === 15) {
        this.currentComponentBody = "Solicitantes";
        currentIndex = keys.findIndex(value => value === 'Solicitantes')
      }
      // if ([44, 16, 17].includes(this.cotejo.id_estatus)) {
      //   this.currentComponentBody = "EsperaValidacion";
      //   currentIndex = keys.findIndex(value => value === 'Documentos')
      // }
      // After staff revision
      if ([16, 17, 19, 20, 21, 74, 75, 76].includes(this.cotejo.id_estatus)) {
        this.currentComponentBody = 'EsperaValidacion';
        currentIndex = keys.findIndex(value => value === 'PagoComplementario');
      }
      // if ([19, 20, 21, 74, 75, 76].includes(this.cotejo.id_estatus)) {
      if ([18].includes(this.cotejo.id_estatus)) {
        this.currentComponentBody = 'PagoComplementario';
        currentIndex = keys.findIndex(value => value === 'Terminado');
      }

      for (let i = 0; i < currentIndex; i++) {
        this.cotejo.currentComponentValues[keys[i]] = true;
      }
    },

    changeCurrentComponentBody(body) {
      this.cotejo.currentComponentValues[this.currentComponentBody] = true;
      this.currentComponentBody = body;
    },

    submitForm(formToSubmit) {
      this.$refs[formToSubmit].dispatchEvent(new Event('submit'));
    },

    async domicilioCompleto() {
      this.loading = true;
      this.cotejo.id_estatus = 44;
      await this.actualizaDomicilio()
        .then(() => this.actualizaCotejo())
        .then(() => { this.changeCurrentComponentBody('Documentos'); });
    },

    documentosCompletos() {
      this.cotejo.id_estatus = 16;

      this.actualizaCotejo()
        .then(() => {
          this.changeCurrentComponentBody('EsperaValidacion');

          this.$toast.success("Información guardada.", {
            timeout: 4000,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true,
          });
        })
        .catch(() => {
          this.showErrorToast("Ocurrió un error al actualizar la información, intente más tarde.");
        });
    },

    async actualizaDomicilio() {
      return await DomiciliosApi.edit(this.cotejo.domicilios_id, {
        calle: this.cotejo.domicilio.calle,
        num_exterior: this.cotejo.domicilio.num_exterior,
        num_interior: this.cotejo.domicilio.num_interior,
        colonia: this.cotejo.domicilio.colonia,
        delegacion_municipio: this.cotejo.domicilio.delegacion_municipio,
        ciudad: this.cotejo.domicilio.ciudad,
        entidad: this.cotejo.domicilio.entidad,
        pais: this.cotejo.domicilio.pais,
        codigo_postal: this.cotejo.domicilio.codigo_postal,
        extranjero: 0,
        tipo: 'Personal',
        tieneExtranjero: null,
        tieneTipo: null,
        lat: this.cotejo.domicilio.lat,
        lng: this.cotejo.domicilio.lng,
        valido: 1,
        comentario: null,
        validador: null
      });
    },

    async actualizaCotejo() {
      try {
        this.loading = true;
        delete this.cotejo.actualizado;
        await CotejosApi.edit(this.cotejo.id, this.cotejo);
      } catch (error) {
        console.log(error);
        this.showErrorToast('No se pudo actualizar la información. Intente más tarde.')
      } finally {
        this.loading = false;
        return;
      }
    },
  }
};
</script>
