<template>
  <div class="content">
    <b-breadcrumb>
      <b-breadcrumb-item :to="{ path: '/dashboard' }">Inicio</b-breadcrumb-item>
      <b-breadcrumb-item active>Editar Notificación</b-breadcrumb-item>
    </b-breadcrumb>

    <!-- Aux -->
    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>
    <!-- Modales -->
    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-route" hide-footer id="modal-confirm-dialog-route">
      <h4 class="text-center">
        ¿Seguro que quiere salir de la página?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog-route')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="beforeLeave(true)">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">
        ¿Seguro que quiere salir sin guardar los cambios?
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-validate" hide-footer id="modal-confirm-validate">
      <h4 class="text-center">
        ¿Está seguro de validar? Los datos no podrán ser modificados posteriormente.
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-validate')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="postMethod()">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-mail" hide-footer id="modal-confirm-mail">
      <h4 class="text-center">
        ¿Está seguro de validar la Diligencia? El cliente será notificado vía email.
      </h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-mail')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="postMethod()">OK</b-button>
    </b-modal>

    <!-- Template -->

    <div class="container-fluid">
      <b-row>
        <b-col class="col-3">
          <b-card>
            <TreeEditNotificaciones :notificacion="notificacion" :user="'Staff'" @node="selected"
              :selectedComponent="currentComponentBody" />
          </b-card>
        </b-col>
        <b-col class="col-9">
          <card v-if="currentComponentBody === 'Ubicacion'">
            <div class="container">
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(setModalValidate('Domicilio'))" @change="getAddressInMap(null)">
                  <ModalDomicilio :currentDomicilio="notificacion.domicilio" @submit.prevent @node="selected" />
                </form>
              </ValidationObserver>
            </div>
          </card>
          <card v-if="currentComponentBody === 'Solicitantes'">
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(setModalValidate('Solicitantes'))">
                <Solicitantes :notificacion="notificacion" :is-staff="true" @submit.prevent />
              </form>
            </ValidationObserver>
          </card>
          <card v-if="currentComponentBody === 'Notificados'">
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(setModalValidate('Notificados'))">
                <Notificado :notificacion="notificacion" :notificado="notificado" @submit.prevent />
              </form>
            </ValidationObserver>
          </card>
          <card v-if="currentComponentBody === 'Documentos'">
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(setModalValidate('Documentos'))">
                <EntregaDocumentos :notificacion="notificacion" @submit.prevent />
              </form>
            </ValidationObserver>
          </card>
          <card v-if="currentComponentBody === 'EnvioDomicilio'">
            <div class="container">
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(setModalValidate('EnvioDomicilio'))"
                  @change="getAddressInMap(null)">
                  <ModalDomicilio :currentDomicilio="notificacion.domicilioEnvio" @submit.prevent @node="selected" />
                </form>
              </ValidationObserver>
            </div>
          </card>
          <card v-if="currentComponentBody === 'Correduria'">
            <div class="container">
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(setModalValidate('Correduria'))">
                  <Correduria :notificacion="notificacion" @submit.prevent @node="selected" />
                </form>
              </ValidationObserver>
            </div>
          </card>
          <card v-if="currentComponentBody === 'Fad'">
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(setModalValidate('Fad'))">
                <Fad :notificacion="notificacion" @submit.prevent @node="selected" />
              </form>
            </ValidationObserver>
          </card>
          <card v-if="currentComponentBody === 'Diligencia'">
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(setModalValidate('Diligencia'))">
                <Diligencia :notificacion="notificacion" @proceedToSubmit="setModalValidate('Diligencia')"
                  @node="selected" />
              </form>
            </ValidationObserver>
          </card>
          <card v-if="currentComponentBody === 'Actas'">
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(setModalValidate('Actas'))">
                <Actas :notificacion="notificacion" @submit.prevent @node="selected" />
              </form>
            </ValidationObserver>
          </card>
          <card v-if="currentComponentBody === 'Expediente'">
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(setModalValidate('Expediente'))">
                <Expediente :notificacion="notificacion" @submit.prevent @node="selected" />
              </form>
            </ValidationObserver>
          </card>
          <card v-if="currentComponentBody === 'Terminado'">
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(setModalValidate('Terminado'))" @change="getAddressInMap(null)">
                <Terminado :notificacion="notificacion" @submit.prevent @node="selected" />
              </form>
            </ValidationObserver>
          </card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";
import ModalDomicilio from "@/views/clients/comparecientes/ModalDomicilio.vue";
import TreeEditNotificaciones from "@/views/clients/procesos/notificaciones/TreeEditNotificaciones.vue";
import Solicitantes from "@/views/clients/procesos/notificaciones/completaInfo/Solicitantes.vue";
import Notificado from "@/views/clients/procesos/notificaciones/completaInfo/Notificado.vue";
import EntregaDocumentos from "@/views/clients/procesos/notificaciones/EntregaDocumentos.vue";
import Correduria from "@/views/clients/procesos/notificaciones/Correduria.vue";
import Fad from "@/views/clients/procesos/notificaciones/Fad.vue";
import Diligencia from "@/views/clients/procesos/notificaciones/Diligencia.vue";
import Actas from "@/views/clients/procesos/notificaciones/Actas.vue";
import Expediente from "@/views/clients/procesos/notificaciones/Expediente.vue";
import Terminado from "@/views/clients/procesos/notificaciones/Terminado.vue";

import { FuncionesApi, ComparecientesApi, DomiciliosApi, NotificacionesApi, NotificadosApi, LayoutsApi } from "@/api";
import { getGoogleMapUrl } from "@/utils/google-maps-utils";

export default {
  name: "ValidNotificaciones",

  components: {
    Card,
    ModalDomicilio,
    TreeEditNotificaciones,
    Correduria,
    Fad,
    Diligencia,
    Solicitantes,
    Notificado,
    EntregaDocumentos,
    Actas,
    Expediente,
    Terminado,
    Card,
  },

  async created() {
    this.loading = true;
    const id = this.$route.params.id;
    this.getNotificacion(id)
      .then(() => this.getNotificados())
      .then(() => this.getDomicilio())
      .then(() => this.getDomicilioEnvio())
      .then(() => this.disableSections())
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        this.showErrorToast('No se pudo obtener la información. Intente más tarde');
        console.log(error);
        // if (error instanceof Error) {
        //   console.log(`Error in ${error.stack.split("\n")[1].trim()}: ${error.message}`);
        // } else {
        //   console.log(error);
        // }
      });
  },

  beforeRouteLeave(to, from, next) {
    if (!to.name === 'EditNotificaciones') {
      this.$refs["modal-confirm-dialog-route"].show();
      this.next = next;
    }
    else {
      this.next = next;
      this.beforeLeave(true);
    }
  },

  data: () => ({
    next: null,
    prenode: null,
    loading: false,

    modalValidateMethod: null,

    currentComponentBody: "",

    notificacion: {
      // Valores de Tree
      currentComponentValues: {
        Ubicacion: false,
        Solicitantes: false,
        Notificados: false,
        Documentos: false,
        EnvioDomicilio: false,
        Correduria: false,
        Fad: false,
        Diligencia: false,
        Actas: false,
        Expediente: false,
        Terminado: false,
        End: false
      },

      // Campos BD
      id: null,
      id_estatus: null,
      id_cotizaciones: null,
      id_clientes: null,
      fad: null,
      acta_express_extra: null,
      envio: null,
      validaSolicitantes: null,
      validaDomicilio: null,
      validaNotificado: null,
      validaDocumentos: null,
      validaExtras: null,
      fecha_diligencia: null,
      contenido_digital: null,
      corredurias_id: null,
      domicilios_id: null,
      hora_llegada: null,
      forma_cerciorarse: null,
      tipo_lugar: null,
      momento_clave_atendido: null,
      varios_lugares: null,
      otro_lugar: null,
      abierto_publico: null,
      ingreso_numeros_interiores: null,
      lugar_final_persona_atendio: null,
      persona_atendio_sexo: null,
      persona_atendio_edad: null,
      persona_atendio_estatura: null,
      persona_atendio_cabello: null,
      persona_atendio_complexion: null,
      momento_clave_persona_atendio: null,
      domicilio_señalado: null,
      domicilio_persona_buscada: null,
      declaracion_domicilio_negativo: null,
      recibio_comunicacion: null,
      instructivo_firmado: null,
      persona_atendio_nombre_proporcionado: null,
      persona_atendio_nombre: null,
      persona_atendio_identificacion: null,
      persona_atendio_identificacion_tipo_documento: null,
      persona_atendio_identificacion_numero: null,
      procedimiento_notificacion: null,
      hora_notificacion: null,
      persona_atendio_manifestaciones_realizadas: null,
      persona_atendio_manifestaciones: null,
      reporte_fotografico: null,
      validaEnvio: null,

      // Domicilio
      domicilio: {
        calle: null,
        num_exterior: null,
        colonia: null,
        delegacion_municipio: null,
        codigo_postal: null,
        ciudad: null,
        entidad: null,
        tieneExtranjero: false,
        tieneTipo: false,
        compareciente: false,
        extranjero: null,
        notificaciones: true,
        lat: null,
        lng: null,
        disabled: false,
        staff: true
      },

      domicilioEnvio: {},

      documentos: [],
      solicitantes: [],
      notificados: [],

      staff: true
    },

    notificado: {},
    headerImgUrl: "https://correduriadigital.blob.core.windows.net/correduria-digital-storage/layouts/cp_logooficial_color.jpg",

  }),

  methods: {
    async beforeLeave(answer) {
      if (answer) {
        this.next();
      }
    },

    async selected(node) {
      const keys = Object.keys(this.notificacion.currentComponentValues);
      const values = Object.values(this.notificacion.currentComponentValues);
      const dest = keys.find(value => value == node);
      this.currentComponentBody = dest;
    },

    async getAddressInMap(direcciones_id) {
      if (direcciones_id === null) {
        const address = 
          `${this.notificacion.domicilio.calle},${this.notificacion.domicilio.num_exterior},
          ${this.notificacion.domicilio.colonia},${this.notificacion.domicilio.delegacion_municipio},
          ${this.notificacion.domicilio.codigo_postal},${this.notificacion.domicilio.ciudad},
          ${this.notificacion.domicilio.entidad}`;
        const mapUrl = await getGoogleMapUrl(address);

        if (!mapUrl) {
          return;
        }

        this.notificacion.domicilio.lat = mapUrl.lat.toString(); //parseFloat(mapUrl.lat);
        this.notificacion.domicilio.lng = mapUrl.lng.toString(); //parseFloat(mapUrl.lng);
        const a = Object.assign({}, this.notificacion.domicilio);
        this.notificacion.domicilio = a;
      }
    },

    showErrorToast(msg) {
      this.$toast.error(msg, {
        timeout: 4000, //false,
        hideProgressBar: false,
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        closeButton: "button",
        icon: true,
      });
    },

    async getNotificacion(id) {
      this.notificacion = {
        ...this.notificacion,
        ...await NotificacionesApi.detail(id)
      }

      this.fillNotificacion();
    },

    fillNotificacion() {
      if (this.notificacion.acta_express_extra == 1) {
        this.notificacion.acta_express = true;
      } else { this.notificacion.acta_express = false; }
      if (this.notificacion.envio == 1) {
        this.notificacion.envio_domicilio = true;
      } else { this.notificacion.envio_domicilio = false; }
    },

    async getNotificados() {
      const { value } = await NotificadosApi.list({
        id_notificaciones: this.notificacion.id
      });
      this.fillNotificados(value)
      return;
    },

    fillNotificados(notificados) {
      notificados.forEach(notificado => {
        notificado.nombreCompleto = this.createNombreCompleto(notificado);
        this.notificacion.notificados.push(notificado);
      })
      this.createObjectNotificado(notificados[0])
    },

    createNombreCompleto(notificado) {
      if (notificado.persona === 'Física') {
        return notificado.nombre + ' ' + notificado.paterno + (notificado.materno || "");
      }

      return notificado.razon_social;
    },

    createObjectNotificado(notificado) {
      notificado.aceptado = false;
      this.notificado = notificado;
    },

    async getDomicilio() {
      const id_domicilios = this.notificacion.notificados[0].id_domicilios;
      const domicilioBD = await DomiciliosApi.detail(id_domicilios);

      this.fillDomicilio(domicilioBD);
    },

    async getDomicilioEnvio() {
      if (this.notificacion.domicilios_id == null) {
        return;
      }
      this.notificacion.domicilioEnvio = {
        ...await DomiciliosApi.detail(this.notificacion.domicilios_id),
        staff: true,
        ratificacion: true
      }
      return;
    },

    fillDomicilio(domicilioDB) {
      this.notificacion.domicilio = Object.assign({}, domicilioDB);
    },

    disableSections() {
      if (this.notificacion.validaDomicilio == 1) {
        this.notificacion.domicilio.disabled = true;
        this.changeCurrentComponentValue('Ubicacion', 'Solicitantes');
        if (this.notificacion.id_estatus == 10) return;
      }
      if (this.notificacion.validaSolicitantes == 1) {
        this.changeCurrentComponentValue('Solicitantes', 'Notificados');
        // return;
      }
      if (this.notificacion.validaNotificado == 1) {
        this.changeCurrentComponentValue('Notificados', 'Documentos');
      }
      if (this.notificacion.validaDocumentos == 1) {
        this.changeCurrentComponentValue('Documentos', 'EnvioDomicilio');
      }
      if (this.notificacion.validaEnvio == 1) {
        this.notificacion.domicilioEnvio.disabled == true;
        this.changeCurrentComponentValue('EnvioDomicilio', 'Fad');
      }
      if (this.notificacion.id_estatus == 23
        || this.notificacion.id_estatus == 58
        || this.notificacion.id_estatus == 11
        || this.notificacion.id_estatus == 12) {
        this.changeCurrentComponentValue('Fad', 'Diligencia');
      }
      if (this.notificacion.id_estatus == 58 || this.notificacion.id_estatus == 12) {
        this.notificacion.diligencia_realizada = true;
        this.changeCurrentComponentValue('Diligencia', 'Actas');
      }
      if (this.notificacion.id_estatus == 12) {
        this.changeCurrentComponentValue('Actas', 'Expediente')
        this.changeCurrentComponentValue('Expediente', 'Terminado')
      }
      if (this.notificacion.id_estatus === 10 || this.notificacion.id_estatus === 22) {
        this.currentComponentBody = "Ubicacion";
      }
      if (this.notificacion.id_estatus == 13) {
        this.changeCurrentComponentValue('End', 'Terminado')
        this.currentComponentBody = "Expediente";
      }

      return;
    },

    setModalValidate(postMethod) {
      this.modalValidateMethod = postMethod;

      if (postMethod == 'Fad') {
        this.postMethod();
      } else if (postMethod == 'Diligencia') {
        this.openSendMailModal();
      } else {
        this.openValidateModal();
      }
    },

    openValidateModal() {
      this.$refs["modal-confirm-validate"].show();
    },

    hideValidateModal() {
      this.$refs["modal-confirm-validate"].hide();
    },

    openSendMailModal() {
      this.$refs["modal-confirm-mail"].show();
    },

    hideSendMailModal() {
      this.$refs["modal-confirm-mail"].hide();
    },

    postMethod() {
      this.hideValidateModal();
      this.hideSendMailModal();

      switch (this.modalValidateMethod) {
        case "Domicilio":
          this.loading = true;
          this.postDomicilio()
            .then(() => this.actualizarNotificacion('validaDomicilio'))
            .then(() => this.changeCurrentComponentValue('Ubicacion', 'Solicitantes'))
            .then(() => { this.notificacion.domicilio.disabled = true; })
            .catch((error) => {
              console.log(error);
              this.showErrorToast('Hubo un error al validar. Intente más tarde.')
            })
            .finally(() => { this.loading = false; });
          break;
        case "Solicitantes":
          this.loading = true;
          this.postSolicitantes()
            .then(() => this.actualizarNotificacion('validaSolicitantes'))
            .then(() => this.changeCurrentComponentValue('Solicitantes', 'Notificados'))
            .catch((error) => {
              console.log(error);
              this.showErrorToast('Hubo un error al validar. Intente más tarde.')
            })
            .finally(() => { this.loading = false; });
          break;
        case 'Notificados':
          this.loading = true;
          this.postNotificados()
            .then(() => this.actualizarNotificacion('validaNotificado'))
            .then(() => this.changeCurrentComponentValue('Notificados', 'Documentos'))
            .catch((error) => {
              console.log(error);
              this.showErrorToast('Hubo un error al validar. Intente más tarde.')
            })
            .finally(() => { this.loading = false; });
          break;
        case 'Documentos':
          this.loading = true;
          this.actualizarNotificacion('validaDocumentos')
            .then(() => this.changeCurrentComponentValue('Documentos', 'Correduria'))
            .catch((error) => {
              console.log(error);
              this.showErrorToast('Hubo un error al validar. Intente más tarde.')
            })
            .finally(() => { this.loading = false; });
          break;
        case 'EnvioDomicilio':
          this.loading = true;
          this.actualizarNotificacion('validaEnvio')
            .then(() => this.changeCurrentComponentValue('EnvioDomicilio', 'Correduria'))
            .catch((error) => {
              console.log(error);
              this.showErrorToast('Hubo un error al validar. Intente más tarde.')
            })
            .finally(() => { this.loading = false; });
          break;
        case 'Correduria':
          this.loading = true;
          this.actualizarNotificacion(null)
            .then(() => this.changeCurrentComponentValue('Correduria', 'Fad'))
            .catch((error) => {
              console.log(error);
              this.showErrorToast('Hubo un error al validar. Intente más tarde.')
            })
            .finally(() => { this.loading = false; });
          break;
        case 'Fad':
          this.loading = true;
          this.checkFad()
            .then(() => this.getNotificacion(this.notificacion.id))
            .then(() => {
              if (this.isFadSigned()) {
                this.notificacion.id_estatus = 11;
                this.actualizarNotificacion(null)
                  .then(() => this.changeCurrentComponentValue('Fad', 'Diligencia'))
              } else {
                this.showErrorToast('El cliente no ha firmado el documento. Intente más tarde.')
              }
            })
            .catch((error) => {
              console.log(error);
              this.showErrorToast('Hubo un error al checar el estatus de FAD. Intente más tarde.')
            })
            .finally(() => { this.loading = false; });
          break;
        case 'Diligencia':
          this.loading = true;
          this.notificacion.id_estatus = 58;
          this.processDiligencia()
            .then(() => this.actualizarNotificacion(null))
            // .then(() => this.mailClientNotificationDone())
            //.then(() => this.changeCurrentComponentValue('Diligencia', 'Actas'))
            .catch((error) => {
              console.log(error);
              this.showErrorToast('Hubo un error al validar. Intente más tarde.')
            })
            .finally(() => { this.loading = false; });
          break;
        case 'Actas':
          this.loading = true;
          this.notificacion.id_estatus = 12;
          this.actualizarNotificacion(null)
            .then(() => this.changeCurrentComponentValue('Actas', 'Expediente'))
            .then(() => this.changeCurrentComponentValue('Expediente', 'Terminado'))
            .catch((error) => {
              console.log(error);
              this.showErrorToast('Hubo un error al validar. Intente más tarde.')
            })
            .finally(() => { this.loading = false; });
          break;
        case 'Terminado':
          this.loading = true;
          this.notificacion.id_estatus = 13;
          this.actualizarNotificacion(null)
            .then(() => this.changeCurrentComponentValue('Terminado', 'End'))
            .then(() => this.changeCurrentComponentValue('End', 'Terminado'))
            .catch((error) => {
              console.log(error);
              this.showErrorToast('Hubo un error al validar. Intente más tarde.')
            })
            .finally(() => { this.loading = false; });
          break;
      }
    },

    async postDomicilio() {
      const domicilio = this.notificacion.domicilio;

      await DomiciliosApi.edit(domicilio.id, {
        calle: domicilio.calle,
        num_exterior: domicilio.num_exterior,
        num_interior: domicilio.num_interior,
        colonia: domicilio.colonia,
        delegacion_municipio: domicilio.delegacion_municipio,
        ciudad: domicilio.ciudad,
        entidad: domicilio.entidad,
        pais: domicilio.pais,
        codigo_postal: domicilio.codigo_postal,
        extranjero: 0,
        tipo: 'Personal',
        tieneExtranjero: null,
        tieneTipo: null,
        lat: domicilio.lat,
        lng: domicilio.lng,
        valido: 1,
        comentario: null,
        validador: null
      })
      return;
    },

    async actualizarNotificacion(campoValidacion) {
      let envio = 0;
      let acta_express_extra = 0;

      if (this.notificacion.acta_express) {
        acta_express_extra = 1;
      }

      if (this.notificacion.envio_domicilio) {
        envio = 1;
      } else {
        this.notificacion.envio_domicilio = false;
      }

      if (campoValidacion) {
        this.notificacion[campoValidacion] = 1;
      }

      await NotificacionesApi.edit(this.notificacion.id, {
        id_estatus: this.notificacion.id_estatus,
        id_cotizaciones: this.notificacion.id_cotizaciones,
        id_clientes: this.notificacion.id_clientes,
        fad: this.notificacion.fad,
        acta_express_extra: acta_express_extra,
        envio: envio,
        validaSolicitantes: this.notificacion.validaSolicitantes,
        validaDomicilio: this.notificacion.validaDomicilio,
        validaNotificado: this.notificacion.validaNotificado,
        validaDocumentos: this.notificacion.validaDocumentos,
        validaExtras: this.notificacion.validaExtras,
        activo: 1,
        fecha_diligencia: this.notificacion.fecha_diligencia,
        contenido_digital: this.notificacion.contenido_digital,
        validaEnvio: this.notificacion.validaEnvio,
        domicilios_id: this.notificacion.domicilios_id,
        corredurias_id: this.notificacion.corredurias_id,
        hora_llegada: this.notificacion.hora_llegada,
        forma_cerciorarse: this.notificacion.forma_cerciorarse,
        tipo_lugar: this.notificacion.tipo_lugar,
        momento_clave_atendido: this.notificacion.momento_clave_atendido,
        varios_lugares: this.notificacion.varios_lugares,
        otro_lugar: this.notificacion.otro_lugar,
        abierto_publico: this.notificacion.abierto_publico,
        ingreso_numeros_interiores: this.notificacion.ingreso_numeros_interiores,
        lugar_final_persona_atendio: this.notificacion.lugar_final_persona_atendio,
        persona_atendio_sexo: this.notificacion.persona_atendio_sexo,
        persona_atendio_edad: this.notificacion.persona_atendio_edad,
        persona_atendio_estatura: this.notificacion.persona_atendio_estatura,
        persona_atendio_cabello: this.notificacion.persona_atendio_cabello,
        persona_atendio_complexion: this.notificacion.persona_atendio_complexion,
        momento_clave_persona_atendio: this.notificacion.momento_clave_persona_atendio,
        domicilio_señalado: this.notificacion.domicilio_señalado,
        domicilio_persona_buscada: this.notificacion.domicilio_persona_buscada,
        declaracion_domicilio_negativo: this.notificacion.declaracion_domicilio_negativo,
        recibio_comunicacion: this.notificacion.recibio_comunicacion,
        instructivo_firmado: this.notificacion.instructivo_firmado,
        persona_atendio_nombre_proporcionado: this.notificacion.persona_atendio_nombre_proporcionado,
        persona_atendio_nombre: this.notificacion.persona_atendio_nombre,
        persona_atendio_identificacion: this.notificacion.persona_atendio_identificacion,
        persona_atendio_identificacion_tipo_documento: this.notificacion.persona_atendio_identificacion_tipo_documento,
        persona_atendio_identificacion_numero: this.notificacion.persona_atendio_identificacion_numero,
        procedimiento_notificacion: this.notificacion.procedimiento_notificacion,
        hora_notificacion: this.notificacion.hora_notificacion,
        persona_atendio_manifestaciones_realizadas: this.notificacion.persona_atendio_manifestaciones_realizadas,
        persona_atendio_manifestaciones: this.notificacion.persona_atendio_manifestaciones,
        reporte_fotografico: this.notificacion.reporte_fotografico,
        validaEnvio: this.notificacion.validaEnvio
      });
    },

    changeCurrentComponentValue(origin, destiny) {
      this.notificacion.currentComponentValues[origin] = true;
      this.currentComponentBody = destiny;
      return;
    },

    async postSolicitantes() {
      return Promise.resolve();
      this.notificacion.solicitantes.forEach(compareciente => this.actualizaCompareciente(compareciente))
      return;
    },

    async actualizaCompareciente(compareciente) {
      await ComparecientesApi.edit(compareciente.id, {
        'nombre': compareciente.nombre,
        'paterno': compareciente.paterno,
        'fecha_nacimiento': compareciente.fecha_nacimiento,
        'curp': compareciente.curp,
        'rfc': compareciente.rfc,
        'ocupacion': compareciente.ocupacion,
        'lugar_nacim_ciudad': compareciente.lugar_nacim_ciudad,
        'lugar_nacim_estado': compareciente.lugar_nacim_estado,
        'lugar_nacim_pais': compareciente.lugar_nacim_pais,
        'denominacion_social': compareciente.denominacion_social,
        'fecha_constitucion': compareciente.fecha_constitucion,
        'tipo_validacion': compareciente.tipo_validacion,
        'cliente_id': compareciente.cliente_id,
        'persona': compareciente.persona,
        'telefonos': compareciente.telefonos,
        'completado': true,
        'cat_servicio_id': 2,
        'servicio_id': this.notificacion.id
      });
    },

    async postNotificados() {
      this.notificacion.notificados.forEach(notificado => { this.actualizaNotificado(notificado); });
      return;
    },

    async actualizaNotificado(notificado) {
      await NotificadosApi.edit(notificado.id, {
        "nombre": notificado.nombre,
        "paterno": notificado.paterno,
        "materno": notificado.materno,
        "razon_social": notificado.razon_social,
        "persona": notificado.persona,
        "nombre_notificado": notificado.nombre_notificado,
        "id_notificaciones": notificado.id_notificaciones,
        "id_domicilios": notificado.id_domicilios,
        "activo": 1
      });
      return;
    },

    async checkFad() {
      const id = this.$route.params.id;
      await FuncionesApi.checkFadKycsEstatus({
        proceso_id: parseInt(id),
        cat_servicios_id: 2
      });
      return;
    },

    processDiligencia() {
      return new Promise((resolve, reject) => {
        try {
          for (const key in this.notificacion) {
            if (this.notificacion.hasOwnProperty(key) && this.notificacion[key] == '1') {
              this.notificacion[key] = 1;
            }
            else if (this.notificacion.hasOwnProperty(key) && this.notificacion[key] == '0') {
              this.notificacion[key] = 0;
            }
          }
          resolve();
        } catch (error) {
          reject(error);
        }
      })
    },

    async mailClientNotificationDone() {
      const email = this.notificacion.solicitantes[0]?.email;

      if (email) {
        const layout = await LayoutsApi.detail(23);

        await FuncionesApi.sendMail({
          emailTo: email,
          body: layout.body,
          subject: layout.subject
        });
      }

    },

    isFadSigned() {
      if (parseInt(this.notificacion.id_estatus) == 23) {
        return true;
      }
      return false;
    }

  }
};
</script>

<style scoped>
.content {
  height: max-content;
}
</style>
