<template>
  <div class="content">

    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <b-breadcrumb v-if="rol === 'Cliente'">
      <b-breadcrumb-item :to="{ path: '/dashboard/comparecientes' }">Comparecientes</b-breadcrumb-item>
      <b-breadcrumb-item active>Editar compareciente</b-breadcrumb-item>
    </b-breadcrumb>

    <b-breadcrumb v-if="rol === 'Staff' && corredurias_id === 1">
      <b-breadcrumb-item :to="{ path: '/dashboard/comparecientesStf' }">Comparecientes</b-breadcrumb-item>
      <b-breadcrumb-item active>Editar compareciente</b-breadcrumb-item>
    </b-breadcrumb>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-dom" hide-footer id="modal-confirm-dialog-dom">
      <h4 class="text-center">¿Seguro que quiere eliminar el domicilio?</h4>
      <h5 class="text-center">Se eliminarán todos los datos.</h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog-dom')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteDomicilio()">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-tel" hide-footer id="modal-confirm-dialog-tel">
      <h4 class="text-center">¿Seguro que quiere eliminar el teléfono?</h4>
      <h5 class="text-center">Se eliminarán todos los datos.</h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog-tel')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteTelefono()">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-repre" hide-footer id="modal-confirm-dialog-repre">
      <h4 class="text-center">¿Seguro que quiere eliminar el representante?</h4>
      <h5 class="text-center">Se eliminarán todos los datos.</h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog-repre')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteRepresentante()">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-repre-dom" hide-footer
      id="modal-confirm-dialog-repre-dom">
      <h4 class="text-center">¿Seguro que quiere eliminar el domicilio?</h4>
      <h5 class="text-center">Se eliminarán todos los datos.</h5>
      <b-button class="mt-3" variant="danger"
        @click="$bvModal.hide('modal-confirm-dialog-repre-dom')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteDomicilioRepresentante()">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-repre-tel" hide-footer
      id="modal-confirm-dialog-repre-tel">
      <h4 class="text-center">¿Seguro que quiere eliminar el teléfono?</h4>
      <h5 class="text-center">Se eliminarán todos los datos.</h5>
      <b-button class="mt-3" variant="danger"
        @click="$bvModal.hide('modal-confirm-dialog-repre-tel')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteTelefonoRepresentante()">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="domicilio-modal" title="" hide-footer id="modalD" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(postDomicilio)" @change="getAddressInMapCompar(null)">
          <ModalDomicilio :currentDomicilio="currentDomicilio" @submit.prevent />
          <!-- @pais="changePais(currentDomicilio.pais)" -->
        </form>
      </ValidationObserver>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="telefono-modal" title="" hide-footer id="modalT" size="lg">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(postTelefono)">
          <ModalTelefono :currentTelefono="currentTelefono" @submit.prevent />
        </form>
      </ValidationObserver>
    </b-modal>

    <b-modal no-close-on-backdrop ref="representante-modal" title="" hide-footer size="xl" id="modalR">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(postRepresentante)">
          <ModalRepresentante :currentRepresentante="currentRepresentante" @submit.prevent
            @state="mismaDataConstitutiva" />
        </form>
      </ValidationObserver>

      <b-card>
        <h4 class="cardD">
          Domicilio
          <b-button v-if="currentRepresentante.domicilios.length === 0" variant="success" class="btn-fill" size="sm"
            @click="addDomicilioRepresentante">+</b-button>
        </h4>

        <b-table :fields="fieldsDomicilios" :items="currentRepresentante.domicilios" hover>
          <template #cell(index)="{ index }">
            {{ getIndex(index + 1) }}
          </template>
          <template #cell(mapUrl)="{ item }">
            <DrawGoogleMap @location="updateCoordinates" :lat="parseFloat(item.lat)" :lng="parseFloat(item.lng)"
              style="width:300px;" />
          </template>
          <template #cell(acciones)="{ item }">
            <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn"
              @click="editDomicilioRepresentante(item)">
              <b-icon-pencil />
            </b-button>
            <b-button variant="danger" @click="predeleteDomicilioRepresentante(item.id)" size="sm" v-b-tooltip.hover
              title="Eliminar">
              <b-icon-trash />
            </b-button>
          </template>
        </b-table>
      </b-card>

      <b-card>
        <h4 class="cardD">
          Teléfonos
          <!-- <b-button
            @click="addTelefonoRepresentante"
            class="btn btn-success btn-fill"
            size="sm"
          >
            <i class="nc-icon nc-simple-add"></i>
          </b-button> -->
          <b-button variant="success" class="btn-fill" size="sm" @click="addTelefonoRepresentante">+</b-button>
        </h4>
        <!-- <div v-for="item in currentRepresentante.telefonos" :key="item.id">
          <div class="col-md-12 text-right">
            <b-button
              variant="danger"
              size="sm"
              v-b-tooltip.hover
              title="Eliminar"
              @click="predeleteTelefonoRepresentante(item.id)"
            >
              <b-icon-trash />
            </b-button>
          </div>
          <Telefonos :telefono="item" />
          <br />
        </div> -->
        <b-table :fields="fieldsTelefonos" :items="currentRepresentante.telefonos" hover>
          <template #cell(index)="{ index }">
            {{ getIndex(index + 1) }}
          </template>
          <template #cell(acciones)="{ item }">
            <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn"
              @click="editTelefonoRepresentante(item)">
              <b-icon-pencil />
            </b-button>
            <b-button variant="danger" @click="predeleteTelefonoRepresentante(item.id)" size="sm" v-b-tooltip.hover
              title="Eliminar">
              <b-icon-trash />
            </b-button>
          </template>
        </b-table>
      </b-card>

      <!-- <div class="text-center">
            <button type="submit" class="btn btn-fill float-right orange-btn">
              Guardar
            </button>
          </div> -->
      <!-- </form>
      </ValidationObserver> -->
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="domicilio-modal-representante" title="" hide-footer id="modalDR"
      size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(postDomicilioRepresentante)" @change="getAddressInMapRepre(null)">
          <ModalDomicilio :currentDomicilio="currentDomicilio" @submit.prevent />
        </form>
      </ValidationObserver>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="telefono-modal-representante" title="" hide-footer id="modalTR"
      size="lg">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(postTelefonoRepresentante)">
          <ModalTelefono :currentTelefono="currentTelefono" @submit.prevent />
        </form>
      </ValidationObserver>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog-documento" hide-footer
      id="modal-confirm-dialog-documento">
      <h4 class="text-center">¿Seguro que quiere eliminar el documento?</h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger"
        @click="$bvModal.hide('modal-confirm-dialog-documento')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteDocumento">OK</b-button>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="documento-modal" title="" hide-footer id="modalC" size="xl">
      <div v-if="user.persona === 'Física'">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(postDocumento)">
            <ModalDocumentos :currentDocumento="currentDocumento" :options="optionsDocumentosFisica" @submit.prevent />
          </form>
        </ValidationObserver>
      </div>
      <div v-else>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(postDocumento)">
            <ModalDocumentos :currentDocumento="currentDocumento" :options="optionsDocumentosMorales" @submit.prevent />
          </form>
        </ValidationObserver>
      </div>
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-img" title="" hide-footer id="modaldi"
      size="xl">
      <ModalPreviewImg :currentDocumento="currentDocumento" />
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="documento-modal-preview-pdf" title="" hide-footer id="modaldf"
      size="xl">
      <ModalPreviewPDF :currentDocumento="currentDocumento" />
    </b-modal>

    <div class="container">
      <b-card class="card">
        <div class="mb-4 client">
          Compareciente del cliente: {{ user.cliente }}
        </div>

        <div v-if="![3].includes(proceso)">
          <div class="row">
            <div class="col-md-6">
              <ValidationProvider name="persona" rules="required" v-slot="{ errors }"
                :custom-messages="{ required: 'La persona es obligatoria' }">
                <label for="">Persona</label>
                <select v-model="user.persona" class="custom-select" :disabled="user.disabled">
                  <option value="Física">Física</option>
                  <option value="Moral">Moral</option>
                  <option value="Fideicomiso">Fideicomiso</option>
                </select>
                <span class="cardErrors">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <br />
        </div>

        <h4 class="cardD" v-if="user.persona === 'Fideicomiso'">
          Datos de identificación del Fiduciario
        </h4>
        <h4 class="cardD" v-else>Datos de identificación</h4>

        <div v-if="user.persona === 'Física'">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(editCompareciente)">
              <FormFisica :user="user" :ratificacion="ratificacion" :servicio="proceso" @submit.prevent />
            </form>
          </ValidationObserver>
        </div>

        <div v-if="user.persona === 'Moral'">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(editCompareciente)">
              <FormMoral :user="user" :ratificacion="ratificacion" @submit.prevent />
              <!-- @state="entidadExtranjera" -->
            </form>
          </ValidationObserver>
        </div>

        <div v-if="user.persona === 'Fideicomiso'">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(editCompareciente)">
              <FormFideicomiso :user="user" @submit.prevent />
            </form>
          </ValidationObserver>
        </div>
      </b-card>

      <b-card v-if="proceso !== 4">
        <h4 class="cardD">
          Domicilio
          <b-button v-if="addDomicilioBtn" variant="success" class="btn-fill" size="sm" @click="addDomicilio"
            :disabled="user.disabled">+</b-button>
        </h4>

        <b-table :fields="fieldsDomicilios" :items="domicilios" hover>
          <template #cell(index)="{ index }">
            {{ getIndex(index + 1) }}
          </template>

          <template #cell(mapUrl)="{ item }">
            <DrawGoogleMap @location="updateCoordinates" :lat="parseFloat(item.lat)" :lng="parseFloat(item.lng)"
              style="width:300px;" />
          </template>

          <template #cell(acciones)="{ item }">
            <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn"
              @click="editDomicilio(item)" :disabled="user.disabled">
              <b-icon-pencil />
            </b-button>
            <b-button variant="danger" @click="predeleteDomicilio(item.id)" :disabled="user.disabled" size="sm"
              v-b-tooltip.hover title="Eliminar">
              <b-icon-trash />
            </b-button>
          </template>
        </b-table>

        <!-- <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(postDomicilio)" @change="getAddressInMapCompar(null)">
          <ModalDomicilio :currentDomicilio="currentDomicilio" @submit.prevent />
        </form>
      </ValidationObserver> -->

        <!-- <Domicilios :domicilio="domicilios[0]" />
        <div v-if="sonDomiciliosParticularFiscalDiferentes">
          <Domicilios :domicilio="domicilios[1]" />
        </div> -->
      </b-card>

      <b-card>
        <h4 class="cardD">Datos de contacto</h4>
        <span class="cardErrors mb-2">
          Favor de ingresar un correo electrónico válido así como un número telefónico de celular ya que serán
          necesarios para el proceso de Firma Digital.
        </span>
        <div>
          <ValidationProvider name="email" rules="required|email" v-slot="{ errors }" :custom-messages="{
            required: 'El email es obligatorio',
            email: 'Debe ser un email válido'
          }">
            <base-input :disabled="user.disabled" type="email" label="Email" placeholder="Email" v-model="user.email">
            </base-input>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <h4 class="cardD">
          Teléfonos
          <b-button variant="success" class="btn-fill" size="sm" @click="addTelefono"
            :disabled="user.disabled">+</b-button>
        </h4>

        <!-- <div v-for="item in telefonos" :key="item.id">
          <div class="col-md-12 text-right">
            <b-button
              variant="danger"
              size="sm"
              v-b-tooltip.hover
              title="Eliminar"
              @click="predeleteTelefono(item.id)"
            >
              <b-icon-trash />
            </b-button>
          </div>
          <Telefonos :telefono="item" />
          <br />
        </div> -->
        <b-table :fields="fieldsTelefonos" :items="telefonos" hover>
          <template #cell(index)="{ index }">
            {{ getIndex(index + 1) }}
          </template>
          <template #cell(acciones)="{ item }">
            <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn"
              @click="editTelefono(item)" :disabled="user.disabled">
              <b-icon-pencil />
            </b-button>
            <b-button variant="danger" @click="predeleteTelefono(item.id)" :disabled="user.disabled" size="sm"
              v-b-tooltip.hover title="Eliminar">
              <b-icon-trash />
            </b-button>
          </template>
        </b-table>
      </b-card>

      <b-card v-if="proceso !== 4">
        <div v-if="user.persona === 'Física'">
          <h4 class="cardD">
            Documentos
            <b-button variant="success" class="btn-fill" size="sm" @click="addDocumento"
              :disabled="user.disabled">+</b-button>
            <!-- v-if="user.documentos.length < optionsFisicas.length" -->
          </h4>
          <b-button id="popover-3" variant="primary" size="sm" style="margin-left:10px !important;">
            ?
          </b-button>
          <b-popover target="popover-3" triggers="hover" placement="top">
            <template #title>Obligatorios</template>
            <ul>
              <li>Identificación Oficial</li>
              <li>Comprobante de domicilio</li>
              <li>RFC o CIF</li>
              <li>CURP</li>
            </ul>
          </b-popover>
          <!-- <b-popover target="popover-3" placement="left" title="Obligatorios" triggers="hover focus" :content="`Identificación Oficial,
              Comprobante de domicilio,
              RFC o CIF,
              CURP`
            "></b-popover> -->
        </div>
        <div v-else>
          <h4 class="cardD">
            Documentos
            <b-button variant="success" class="btn-fill" size="sm" @click="addDocumento"
              :disabled="user.disabled">+</b-button>
            <!-- v-if="user.documentos.length < optionsMorales.length" -->
          </h4>
        </div>
        <b-table hover :fields="fieldsDocus" :items="user.documentos">
          <template #cell(tipo)="{ item }">
            <div v-if="item.tipo === 'Otro'">
              <div v-b-tooltip.hover :title="item.descripcion">
                {{ item.tipo }}
              </div>
            </div>
            <div v-else>
              {{ item.tipo }}
            </div>
          </template>
          <template #cell(acciones)="{ item }">
            <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" @click="editDocumento(item)"
              class="editBtn" :disabled="user.disabled">
              <b-icon-pencil />
            </b-button>
            <b-button variant="primary" size="sm" v-b-tooltip.hover title="Vista previa" @click="previewDocumento(item)"
              class="editBtn">
              <b-icon-eye />
            </b-button>
            <b-button variant="danger" size="sm" v-b-tooltip.hover title="Eliminar" @click="predeleteDocumento(item.id)"
              class="editBtn" :disabled="user.disabled">
              <b-icon-trash />
            </b-button>
          </template>
        </b-table>
      </b-card>

      <b-card>
        <div v-if="user.persona === 'Física'">
          <b-form-group id="input-group-actua-derecho-propio" label="Actúa por derecho propio:"
            label-for="input-actua-derecho-propio">
            <b-form-checkbox id="input-actua-derecho-propio" v-model="user.actua_derecho_propio"></b-form-checkbox>
          </b-form-group>
        </div>

        <div v-if="!user.actua_derecho_propio">
          <h4 class="cardD">
            Apoderados o Representantes Legales
            <!-- <button @click="addRepresentante" class="btn btn-success btn-fill">
            <i class="nc-icon nc-simple-add"></i>
          </button> -->
            <b-button variant="success" class="btn-fill" size="sm" @click="addRepresentante"
              :disabled="user.disabled">+</b-button>
          </h4>
          <div class="text-center">
            <b-table :fields="fields" :items="representantes" hover>
              <template #cell(index)="{ index }">
                {{ getIndex(index + 1) }}
              </template>
              <template #cell(acciones)="{ item }">
                <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn"
                  @click="editRepresentante(item)" :disabled="user.disabled">
                  <b-icon-pencil />
                </b-button>
                <b-button variant="danger" @click="predeleteRepresentantes(item.id)" size="sm" v-b-tooltip.hover
                  title="Eliminar" :disabled="user.disabled">
                  <b-icon-trash />
                </b-button>
              </template>
            </b-table>
          </div>
        </div>

      </b-card>

      <b-card v-if="proceso !== 4 && this.user.persona === 'Física'">
        <h4 class="cardD">Redes Sociales</h4>
        <RedesSociales :user="user" />
      </b-card>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>
import FormFideicomiso from "@/views/clients/comparecientes/FormFideicomiso.vue";
import FormFisica from "@/views/clients/comparecientes/FormFisica.vue";
import FormMoral from "@/views/clients/comparecientes/FormMoral.vue";
// import Domicilios from "@/views/clients/comparecientes/Domicilios.vue";
import ModalDomicilio from "@/views/clients/comparecientes/ModalDomicilio.vue";
// import Telefonos from "@/views/clients/comparecientes/Telefonos.vue";
import RedesSociales from "@/views/clients/comparecientes/RedesSociales.vue";
// import Representantes from "@/views/clients/comparecientes/Representantes.vue";
import { CambiosConstitutivaApi, CatDocumentosApi, CatNacionalidadesApi, ClientesApi, ComparecientesApi, FuncionesApi, SolicitantesApi } from "@/api";
import Storage from "@/utils/storage";
import DrawGoogleMap from "@/views/clients/comparecientes/DrawGoogleMap.vue";
import ModalRepresentante from "@/views/clients/comparecientes/ModalRepresentante.vue";
import ModalTelefono from "@/views/clients/comparecientes/ModalTelefono.vue";
import ModalDocumentos from "@/views/clients/procesos/constitucion/entregadocumentos/ModalDocumentos.vue";
import ModalPreviewImg from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewImg.vue";
import ModalPreviewPDF from "@/views/clients/procesos/constitucion/entregadocumentos/ModalPreviewPDF.vue";

const storage = new Storage();

import { getGoogleMapUrl } from "@/utils/google-maps-utils";

export default {
  name: "EditCompareciente",

  components: {
    FormFisica,
    FormMoral,
    // Domicilios,
    // Telefonos,
    ModalDomicilio,
    ModalTelefono,
    ModalRepresentante,
    FormFideicomiso,
    RedesSociales,
    // Representantes,
    DrawGoogleMap,
    ModalDocumentos,
    ModalPreviewImg,
    ModalPreviewPDF
  },

  computed: {
    fields() {
      let fields = [
        { key: "index", label: "", class: "text-center" },
        // { key: "representante", label: "Representante", class: "text-center" },
        { key: "nombre", label: "Nombre", class: "text-center" },
        { key: "paterno", label: "Paterno", class: "text-center" },
        { key: "materno", label: "Materno", class: "text-center" },
        { key: "email", label: "Email", class: "text-center" },
        { key: "rfc", label: "RFC", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fields;
    },
    fieldsDomicilios() {
      let fieldsDomicilios = [
        { key: "index", label: "", class: "text-center" },
        { key: "tipo", label: "Tipo", class: "text-center" },
        { key: "calle", label: "Calle", class: "text-center" },
        { key: "num_exterior", label: "Número", class: "text-center" },
        { key: "colonia", label: "Colonia", class: "text-center" },
        { key: "entidad", label: "Entidad", class: "text-center" },
        { key: "pais", label: "País", class: "text-center" },
        { key: "mapUrl", label: "Mapa", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fieldsDomicilios;
    },
    fieldsTelefonos() {
      let fieldsTelefonos = [
        { key: "index", label: "", class: "text-center" },
        { key: "tipo", label: "Tipo", class: "text-center" },
        { key: "clave", label: "Clave", class: "text-center" },
        { key: "numero", label: "Número", class: "text-center" },
        { key: "extension", label: "Extension", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fieldsTelefonos;
    },
    fieldsDocus() {
      let fieldsDocus = [
        // { key: "index", label: "", class: "text-center" },
        { key: "nombreFile", label: "Nombre", class: "text-center" },
        { key: "tipo", label: "Tipo", class: "text-center" },
        // { key: "nombre", label: "Persona Física", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" }
      ];
      return fieldsDocus;
    },
    optionsDocumentosFisica() {
      let arr = [...this.optionsFisicas];

      if (this.user.documentos.length > 0) {
        arr = arr.filter(d => !this.user.documentos.some(doc => doc.cat_documentos_id == d.value));
      }

      if (this.user.actua_derecho_propio) {
        arr = arr.filter(d => d.id != 97);
      }

      return arr;
    },
    optionsDocumentosMorales() {
      let arr = [...this.optionsMorales];

      if (this.user.documentos.length > 0) {
        arr = arr.filter(d => !this.user.documentos.some(doc => doc.cat_documentos_id == d.value));
      }

      return arr;
    },
  },

  watch: {
    "currentDomicilio.pais": "changePais",
    "currentDomicilio.tipo": "tipoDomicilio",
    // "domicilios": {
    //   deep: true,
    //   handler: function (val, oldVal) {
    //     this.domicilios.forEach(d => d._showDetails = true);

    //       if (this.domicilios[0].tipo === "Particular y Fiscal") {
    //         this.sonDomiciliosParticularFiscalDiferentes = false;

    //         this.domicilios.splice(1);
    //         return;
    //       }

    //       this.sonDomiciliosParticularFiscalDiferentes = true;

    //       if ((this.domicilios[0].tipo === "Particular" || this.domicilios[0].tipo === "Particular")
    //         && this.domicilios.length < 2) {
    //         this.domicilios.push({
    //           calle: "",
    //           num_exterior: "",
    //           num_interior: "",
    //           colonia: "",
    //           codigo_postal: "",
    //           delegacion_municipio: "",
    //           entidad: "",
    //           pais: "",
    //           tieneTipo: true,
    //           tipo: "",
    //           lat: null,
    //           lng: null
    //         });

    //         return;
    //       }
    //   }
    // }
  },

  created() {
    this.ratificacion = this.$route.params.ratificacion;
    this.proceso = Number(this.$route.query.proceso);

    this.getCatDocumentosPF();
    this.getCatDocumentosPM();
    this.getCatNacionalidades();
    this.getCompareciente();
    // this.initProperties();
  },

  data: () => ({
    // sonDomiciliosParticularFiscalDiferentes: true,
    proceso: 0,
    ratificacion: false,

    addDomicilioBtn: true,
    id: 0,
    loading: false,
    rol: null,
    corredurias_id: null,

    optionsFisicas: [],
    optionsMorales: [],
    currentDocumento: null,
    currentDocumentoId: null,

    user: {
      disabled: false,

      cliente_id: null,
      persona: null,
      nombre: null,
      paterno: null,
      materno: null,
      email: null,
      rfc: null,
      facebook: null,
      twitter: null,
      instagram: null,
      linkedin: null,

      fecha_nacimiento: null,
      lugar_nacim_ciudad: null,
      lugar_nacim_estado: null,
      lugar_nacim_pais: null,
      // nacionalidad: null,
      curp: null,
      ocupacion: null,
      giro_negocios: null,
      alias: null,
      sexo: null,
      pais_residencia: null,
      estado_civil: null,
      identificacion_conyuge: null,
      regimen_patrimonial: null,
      identificacion_tipo: null,
      identificacion_autoridad: null,
      identificacion_numero: null,

      identificacion_tipo_otro: null,
      identificacion_autoridad_otro: null,

      denominacion_social: null,
      fecha_constitucion: null,
      // nacionalidad: null,
      actividad: null,
      giro: null,
      objeto_social: null,
      domicilio_social: null,

      instrumento_numero: null,
      instrumento_numero_fecha: null,
      instrumento_fedatario: null,
      instrumento_fedatario_nombre: null,
      instrumento_fedatario_numero: null,
      instrumento_fedatario_estado: null,
      instrumento_rpf_folio: null,
      instrumento_rpf_folio_fecha: null,
      instrumento_rpf_folio_lugar: null,

      entidad_extranjera: null,
      datos_prueba_constitucion: null,

      estatus_migratorio: null,
      fecha_ingreso_pais: null,

      fiduciario: null,
      nombre_fideicomiso: null,
      fideicomitente: null,
      tipo_fideicomiso: null,
      tipo_fideicomiso_otro: null,
      fecha_creacion_fideicomiso: null,
      folio_inscripcion: null,
      rfc_fideicomiso: null,
      email_fideicomiso: null,
      tel_fideicomiso: null,

      nacionalidades: [],
      documentos: [],

      completado: false,
      cat_servicio_id: null,
      servicio_id: null,

      tipo_poder: null,
      limitaciones_poder: null,
      vigencia_poder: null,
      actua_derecho_propio: null
    },

    domicilio: { mapUrl: null },
    domicilios: [],
    telefono: {},
    telefonos: [],
    representante: {},
    representantes: [],

    // nacionalidades: [],
    cat_nacionalidades: [],

    currentDomicilio: {
      tieneExtranjero: true,
      tieneTipo: true,
      compareciente: true,
      extranjero: null,
      mapUrl: null
    },

    currentTelefono: {},
    currentRepresentante: {
      domicilios: [],
      telefonos: [],
      instrumento_numero: null,
      instrumento_numero_fecha: null,
      instrumento_fedatario: null,
      instrumento_fedatario_nombre: null,
      instrumento_fedatario_numero: null,
      instrumento_fedatario_estado: null,
      instrumento_rpf_folio: null,
      instrumento_rpf_folio_fecha: null,
      instrumento_rpf_folio_lugar: null
    },

    currentPage: 1,
    perPage: 10,
    current_representantes_id: null,

    currentDomicilioId: null,
    currentTelefonoId: null,
    currentRepresentanteId: null,
    currentRepresentanteDomicilioId: null,
    currentRepresentanteTelefonoId: null,

    compareciente_original: {},
    cambios: [],
    domicilios_originales: [],
    telefonos_originales: [],
    representantes_originales: [],
    documentos_originales: []
  }),

  methods: {
    // initProperties() {
    // if (!this.domicilios || !Array.isArray(this.domicilios)) {
    //   this.domicilios = [];
    // }

    // this.domicilios.forEach(d => d._showDetails: true);

    // if (!this.domicilios.length === 0) {
    //   this.domicilios.push({
    //     calle: "",
    //     num_exterior: "",
    //     num_interior: "",
    //     colonia: "",
    //     codigo_postal: "",
    //     delegacion_municipio: "",
    //     entidad: "",
    //     pais: "",
    //     tieneTipo: true,
    //     tipo: "",
    //     lat: null,
    //     lng: null
    //   });
    // }
    // },

    async getCatDocumentosPF() {
      try {
        const { value } = await CatDocumentosApi.list({
          page: 0,
          registersPerPage: 0,
          categoria: "compareciente_pf"
        });
        value.forEach(element => {
          const obj = { text: element.documento, value: element.id };
          this.optionsFisicas.push(obj);
        });
      } catch (error) {
        console.log(error);
        // this.loading = false;
      }
    },

    async getCatDocumentosPM() {
      try {
        const { value } = await CatDocumentosApi.list({
          page: 0,
          registersPerPage: 0,
          categoria: "compareciente_pm"
        });

        value.forEach(element => {
          const obj = { text: element.documento, value: element.id };
          this.optionsMorales.push(obj);
        });
      } catch (error) {
        // this.loading = false;
      }
    },

    async getAddressInMapCompar(direcciones_id) {
      if (direcciones_id != null) {
        const index = this.domicilios.findIndex(o => o.id === direcciones_id);
        const address =
          `${this.domicilios[index].calle},${this.domicilios[index].num_exterior},${this.domicilios[index].colonia},
          ${this.domicilios[index].delegacion_municipio},${this.domicilios[index].codigo_postal},
          ${this.domicilios[index].ciudad},${this.domicilios[index].entidad}`;
        const mapUrl = await getGoogleMapUrl(address);
        // this.domicilios[index].mapUrl = mapUrl;
        this.domicilios[index].lat = mapUrl.lat;
        this.domicilios[index].lng = mapUrl.lng;
      } else if (direcciones_id === null) {
        const address =
          `${this.currentDomicilio.calle},${this.currentDomicilio.num_exterior},${this.currentDomicilio.colonia},
          ${this.currentDomicilio.delegacion_municipio},${this.currentDomicilio.codigo_postal},${this.currentDomicilio.ciudad},
          ${this.currentDomicilio.entidad}`;
        const mapUrl = await getGoogleMapUrl(address);
        // console.log(mapUrl.lat);
        // this.currentDomicilio.mapUrl = mapUrl;
        this.currentDomicilio.lat = mapUrl.lat.toString(); //parseFloat(mapUrl.lat);
        this.currentDomicilio.lng = mapUrl.lng.toString(); //parseFloat(mapUrl.lng);
        const a = Object.assign({}, this.currentDomicilio);
        this.currentDomicilio = a;
      }
    },

    async getAddressInMapRepre(direcciones_id) {
      if (direcciones_id != null) {
        const index = this.currentRepresentante.domicilios.findIndex(o => o.id === direcciones_id);
        const address =
          `${this.currentRepresentante.domicilios[index].calle},${this.currentRepresentante.domicilios[index].num_exterior},
          ${this.currentRepresentante.domicilios[index].colonia},
          ${this.currentRepresentante.domicilios[index].delegacion_municipio},
          ${this.currentRepresentante.domicilios[index].codigo_postal},${this.currentRepresentante.domicilios[index].ciudad},
          ${this.currentRepresentante.domicilios[index].entidad}`;

        const mapUrl = await getGoogleMapUrl(address);
        // this.currentRepresentante.domicilios[index].mapUrl = mapUrl;
        this.currentRepresentante.domicilios[index].lat = mapUrl.lat;
        this.currentRepresentante.domicilios[index].lng = mapUrl.lng;
      } else if (direcciones_id === null) {
        const address =
          `${this.currentDomicilio.calle},${this.currentDomicilio.num_exterior},${this.currentDomicilio.colonia},
          ${this.currentDomicilio.delegacion_municipio},${this.currentDomicilio.codigo_postal},${this.currentDomicilio.ciudad},
          ${this.currentDomicilio.entidad}`;
        const mapUrl = await getGoogleMapUrl(address);

        // this.currentDomicilio.mapUrl = mapUrl;
        this.currentDomicilio.lat = mapUrl.lat.toString();
        this.currentDomicilio.lng = mapUrl.lng.toString();
        const a = Object.assign({}, this.currentDomicilio);
        this.currentDomicilio = a;
      }
    },

    updateCoordinates(coordinates) {
      // console.log("updateCoordEditCompar");
      // console.log(coordinates.lat);
      // console.log(coordinates.lng);
      // this.currentDomicilio.lat=coordinates.lat;
      // this.currentDomicilio.lng=coordinates.lng;
      //acá no se hace nada porque está fuera de modal donde se save all
    },

    getIndex(index) {
      return index + (this.currentPage - 1) * this.perPage;
    },

    async getCompareciente() {
      try {
        this.loading = true;

        this.corredurias_id = storage.getItem("corredurias_id");

        const rol = storage.getItem("role");

        if (rol === "Cliente Administrador" || rol === "Cliente Usuario") {
          this.rol = "Cliente";
        } else {
          this.rol = "Staff";
        }

        const id = this.$route.params.id;
        this.id = Number(id);

        const {
          activo,
          cliente_id,
          nombre,
          paterno,
          materno,
          email,
          persona,
          rfc,

          fecha_nacimiento,
          lugar_nacim_ciudad,
          lugar_nacim_estado,
          lugar_nacim_pais,
          curp,
          ocupacion,
          giro_negocios,
          alias,
          sexo,
          pais_residencia,
          estado_civil,
          identificacion_conyuge,
          regimen_patrimonial,
          identificacion_tipo,
          identificacion_autoridad,
          identificacion_numero,

          denominacion_social,
          fecha_constitucion,
          actividad,
          giro,
          objeto_social,
          domicilio_social,

          instrumento_numero,
          instrumento_numero_fecha,
          instrumento_fedatario,
          instrumento_fedatario_nombre,
          instrumento_fedatario_numero,
          instrumento_fedatario_estado,
          instrumento_rpf_folio,
          instrumento_rpf_folio_fecha,
          instrumento_rpf_folio_lugar,

          entidad_extranjera,
          datos_prueba_constitucion,
          facebook,
          twitter,
          instagram,
          linkedin,

          estatus_migratorio,
          fecha_ingreso_pais,

          domicilios,
          telefonos,
          representantes,
          nacionalidades,
          documentos,

          fiduciario,
          nombre_fideicomiso,
          fideicomitente,
          tipo_fideicomiso,
          fecha_creacion_fideicomiso,
          folio_inscripcion,
          rfc_fideicomiso,
          email_fideicomiso,
          tel_fideicomiso,

          completado,
          cat_servicio_id,
          servicio_id,
          tipo_poder,
          limitaciones_poder,
          vigencia_poder,
          actua_derecho_propio
        } = await ComparecientesApi.detail(id);

        if (activo) {
          this.user.disabled = false;
        } else {
          this.user.disabled = true;
        }

        //obtener el nombre del cliente al que "pertenecen"
        this.user.cliente = await this.getClienteName(cliente_id);
        this.user.cliente_id = cliente_id;
        this.user.nombre = nombre;
        this.user.paterno = paterno;
        this.user.materno = materno;
        this.user.email = email;
        this.user.persona = persona;
        this.user.rfc = rfc;
        // this.user.nacionalidad = nacionalidad;
        this.user.facebook = facebook;
        this.user.twitter = twitter;
        this.user.instagram = instagram;
        this.user.linkedin = linkedin;

        this.user.tipo_poder = tipo_poder;
        this.user.limitaciones_poder = limitaciones_poder;
        this.user.vigencia_poder = vigencia_poder;
        this.user.actua_derecho_propio = actua_derecho_propio;

        this.telefonos = telefonos;
        const telefonos_originales = JSON.parse(JSON.stringify(this.telefonos));
        this.telefonos_originales = telefonos_originales;

        if (domicilios.length !== 0) {
          this.addDomicilioBtn = false;

          for (let i = 0; i < domicilios.length; i++) {
            if (domicilios[i].extranjero) {
              domicilios[i].extranjero = true;
            } else if (!domicilios[i].extranjero) {
              domicilios[i].extranjero = false;
            }

            domicilios[i].tieneTipo = true;
            domicilios[i].tieneExtranjero = true;
          }
        }

        this.domicilios = domicilios;
        const domicilios_originales = JSON.parse(JSON.stringify(this.domicilios));
        this.domicilios_originales = domicilios_originales;

        if (persona === "Física") {
          this.user.fecha_nacimiento = fecha_nacimiento?.substring(0, 10) || "";
          this.user.lugar_nacim_ciudad = lugar_nacim_ciudad;
          this.user.lugar_nacim_estado = lugar_nacim_estado;
          this.user.lugar_nacim_pais = lugar_nacim_pais;
          this.user.curp = curp;
          this.user.ocupacion = ocupacion;
          this.user.giro_negocios = giro_negocios;
          this.user.alias = alias;
          this.user.sexo = sexo;
          this.user.pais_residencia = pais_residencia;
          this.user.estado_civil = estado_civil;
          this.user.identificacion_conyuge = identificacion_conyuge;
          this.user.regimen_patrimonial = regimen_patrimonial;
          this.user.identificacion_numero = identificacion_numero;

          if (
            identificacion_tipo === "Credencial para votar" ||
            identificacion_tipo === "Pasaporte" ||
            identificacion_tipo === "Cédula Profesional" ||
            identificacion_tipo === "Licencia de Conducir" ||
            identificacion_tipo === "Forma Migratoria"
          ) {
            this.user.identificacion_tipo = identificacion_tipo;
          } else {
            this.user.identificacion_tipo = "Otro";
            this.user.identificacion_tipo_otro = identificacion_tipo;
          }

          if (
            identificacion_autoridad === "Instituto Federal Electoral" ||
            identificacion_autoridad === "Instituto Nacional Electoral" ||
            identificacion_autoridad === "Secretaría de Relaciones Exteriores" ||
            identificacion_autoridad === "Secretaría de Educación Pública" ||
            identificacion_autoridad === "Instituto Nacional de Migración"
          ) {
            this.user.identificacion_autoridad = identificacion_autoridad;
          } else {
            this.user.identificacion_autoridad = "Otro";
            this.user.identificacion_autoridad_otro = identificacion_autoridad;
          }

          this.user.estatus_migratorio = estatus_migratorio;
          if (fecha_ingreso_pais != null) {
            this.user.fecha_ingreso_pais = fecha_ingreso_pais?.substring(0, 10) || "";
          }
        } else if (persona === "Moral") {
          this.user.denominacion_social = denominacion_social;
          this.user.fecha_constitucion = fecha_constitucion?.substring(0, 10) || "";
          this.user.actividad = actividad;
          this.user.giro = giro;
          this.user.objeto_social = objeto_social;
          this.user.domicilio_social = domicilio_social;
          this.user.instrumento_numero = instrumento_numero;
          this.user.instrumento_numero_fecha = instrumento_numero_fecha?.substring(0, 10) || "";
          this.user.instrumento_fedatario = instrumento_fedatario;
          this.user.instrumento_fedatario_nombre = instrumento_fedatario_nombre;
          this.user.instrumento_fedatario_numero = instrumento_fedatario_numero;
          this.user.instrumento_fedatario_estado = instrumento_fedatario_estado;
          this.user.instrumento_rpf_folio = instrumento_rpf_folio;
          this.user.instrumento_rpf_folio_fecha = instrumento_rpf_folio_fecha?.substring(0, 10) || "";
          this.user.instrumento_rpf_folio_lugar = instrumento_rpf_folio_lugar;

          if (entidad_extranjera === 1) {
            this.user.entidad_extranjera = true;
          } else if (entidad_extranjera === 0) {
            this.user.entidad_extranjera = false;
          }

          this.user.datos_prueba_constitucion = datos_prueba_constitucion;
        } else if (persona === "Fideicomiso") {
          this.user.denominacion_social = denominacion_social;
          this.user.fecha_constitucion = fecha_constitucion?.substring(0, 10) || "";
          this.user.actividad = actividad;
          this.user.giro = giro;
          this.user.objeto_social = objeto_social;
          this.user.instrumento_numero = instrumento_numero;
          this.user.instrumento_numero_fecha = instrumento_numero_fecha?.substring(0, 10) || "";
          this.user.instrumento_fedatario = instrumento_fedatario;
          this.user.instrumento_fedatario_nombre = instrumento_fedatario_nombre;
          this.user.instrumento_fedatario_numero = instrumento_fedatario_numero;
          this.user.instrumento_fedatario_estado = instrumento_fedatario_estado;
          this.user.instrumento_rpf_folio = instrumento_rpf_folio;
          this.user.instrumento_rpf_folio_fecha = instrumento_rpf_folio_fecha?.substring(0, 10) || "";
          this.user.instrumento_rpf_folio_lugar = instrumento_rpf_folio_lugar;

          if (entidad_extranjera === 1) {
            this.user.entidad_extranjera = true;
          } else if (entidad_extranjera === 0) {
            this.user.entidad_extranjera = false;
          }

          this.user.datos_prueba_constitucion = datos_prueba_constitucion;
          this.user.fiduciario = fiduciario;
          this.user.nombre_fideicomiso = nombre_fideicomiso;
          this.user.fideicomitente = fideicomitente;

          // this.user.tipo_fideicomiso = tipo_fideicomiso;
          if (
            tipo_fideicomiso === "Administración" ||
            tipo_fideicomiso === "Garantía" ||
            tipo_fideicomiso === "Fuente de Pago" ||
            tipo_fideicomiso === "Empresarial"
          ) {
            this.user.tipo_fideicomiso = tipo_fideicomiso;
          } else {
            this.user.tipo_fideicomiso = "Otro";
            this.user.tipo_fideicomiso_otro = tipo_fideicomiso;
          }

          this.user.fecha_creacion_fideicomiso = fecha_creacion_fideicomiso?.substring(0, 10) || "";
          this.user.folio_inscripcion = folio_inscripcion;
          this.user.rfc_fideicomiso = rfc_fideicomiso;
          this.user.email_fideicomiso = email_fideicomiso;
          this.user.tel_fideicomiso = tel_fideicomiso;
        }

        if (representantes != null) {
          for (let i2 = 0; i2 < representantes.length; i2++) {
            // representantes[i2].personaARepresentar = persona;
            representantes[i2].representante
              = `${representantes[i2].nombre} ${representantes[i2].paterno} ${representantes[i2].materno}`;
            representantes[i2].fecha_nacimiento = representantes[i2].fecha_nacimiento?.substring(0, 10) || "";
            representantes[i2].instrumento_numero_fecha = representantes[i2].instrumento_numero_fecha?.substring(0, 10) || "";
            representantes[i2].instrumento_rpf_folio_fecha
              = representantes[i2].instrumento_rpf_folio_fecha?.substring(0, 10) || "";

            if (representantes[i2].mismo_constitutiva === 1) {
              representantes[i2].mismo_constitutiva = true;
            } else if (representantes[i2].mismo_constitutiva === 0) {
              representantes[i2].mismo_constitutiva = false;
            }

            if (representantes[i2].domicilios != null) {
              for (let i3 = 0; i3 < representantes[i2].domicilios.length; i3++) {
                if (representantes[i2].domicilios[i3].extranjero === 1) {
                  representantes[i2].domicilios[i3].extranjero = true;
                } else if (
                  representantes[i2].domicilios[i3].extranjero == 0 ||
                  representantes[i2].domicilios[i3].extranjero == null
                ) {
                  representantes[i2].domicilios[i3].extranjero = false;
                }
              }
            }

            if (representantes[i2].nacionalidades != null) {
              const nacionalidadesNamesRepresentantes = [];

              for (let i4 = 0; i4 < representantes[i2].nacionalidades.length; i4++) {
                const index = this.cat_nacionalidades.findIndex(o => o.id === nacionalidades[i].nacionalidades_id);

                if (index !== -1) {
                  const nacionalidad = this.cat_nacionalidades[index];
                  nacionalidadesNamesRepresentantes.push(nacionalidad);
                }
              }

              representantes[i2].nacionalidades = nacionalidadesNamesRepresentantes;
            }


            if (representantes[i2].nacionalidad) {
              const nacionalidadesNamesRepresentantes = [];
              const _nacionnalidades = representantes[i2].nacionalidad.split(" ");

              for (const _nacionalidad of _nacionnalidades) {
                const index = this.cat_nacionalidades.findIndex(o => o.nacionalidad === _nacionalidad);

                if (index !== -1) {
                  const nacionalidad = this.cat_nacionalidades[index];
                  nacionalidadesNamesRepresentantes.push(nacionalidad);
                }
              }

              representantes[i2].nacionalidades = nacionalidadesNamesRepresentantes;
            }

            const domicilios_originales = JSON.parse(JSON.stringify(representantes[i2].domicilios));
            representantes[i2].domicilios_originales = domicilios_originales;
            const telefonos_originales = JSON.parse(JSON.stringify(representantes[i2].telefonos));
            representantes[i2].telefonos_originales = telefonos_originales;
          }
        }

        this.representantes = representantes;
        const representantes_originales = JSON.parse(JSON.stringify(this.representantes));
        this.representantes_originales = representantes_originales;

        //si es tipo otro, pones otro y topoOtro el vlauer guardado
        for (let r = 0; r < documentos.length; r++) {
          documentos[r].nombreFile = documentos[r].documento.substring(documentos[r].documento.lastIndexOf("/") + 1);

          // let tipobd = documentos[r].tipo;
          // if (
          //   tipobd === "Identificación oficial" ||
          //   tipobd === "Comprobante de domicilio" ||
          //   tipobd === "RFC o Cédula de identificación Fiscal" ||
          //   tipobd === "CURP" ||
          //   tipobd === "Constitutiva" ||
          //   tipobd === "Poderes del representante" ||
          //   tipobd === "Cuadro de distribución accionaria"

          // ) {
          //   documentos[r].tipo = tipobd;
          // } else {
          //   documentos[r].tipo = "Otro";
          //   documentos[r].tipoOtro = tipobd;
          // }

          //donde pongo el tipo otro que es el campo descripcion?

          let tipo = this.optionsFisicas.find(x => x.value == documentos[r].cat_documentos_id);

          if (!tipo) {
            tipo = this.optionsMorales.find(x => x.value == documentos[r].cat_documentos_id);
          }

          documentos[r].tipo = tipo?.text;
        }

        const documentosFiltrados = documentos.filter(d => !d.documento.includes("kyc"));
        this.user.documentos = documentosFiltrados;
        const documentos_originales = JSON.parse(JSON.stringify(this.user.documentos));
        this.documentos_originales = documentos_originales;

        const nacionalidadesNames = [];

        if (nacionalidades != null) {
          for (let i = 0; i < nacionalidades.length; i++) {
            const index = this.cat_nacionalidades.findIndex(o => o.id === nacionalidades[i].nacionalidades_id);

            if (index !== -1) {
              const nacionalidad = this.cat_nacionalidades[index];

              nacionalidadesNames.push(nacionalidad);
            }
          }

          this.user.nacionalidades = nacionalidadesNames;
        }

        // const original_user = JSON.parse(JSON.stringify(this.user));
        // this.compareciente_original = original_user;
        this.compareciente_original = structuredClone(this.user);

        this.user.completado = completado;
        this.user.cat_servicio_id = cat_servicio_id;
        this.user.servicio_id = servicio_id;

        // if (cat_servicio_id === 2) {
        //   if (persona === "Moral") {
        //     const solicitantes = await SolicitantesApi.list({ id_notificaciones: servicio_id });
        //     const solicitanteRepresentante = solicitantes.value.find(s => s.id_comparecientes !== this.id);
        //     const representante = await ComparecientesApi.detail(solicitanteRepresentante.id_comparecientes);

        //     const nacionalidadesRepresentanteNames = [];

        //     for (const nacionalidadRepresentante of representante.nacionalidades) {
        //       const index = this.cat_nacionalidades.findIndex(o => o.id === nacionalidadRepresentante.nacionalidades_id);

        //       if (index !== -1) {
        //         const nacionalidad = this.cat_nacionalidades[index];

        //         nacionalidadesRepresentanteNames.push(nacionalidad);
        //       }
        //     }

        //     representante.nacionalidades = nacionalidadesRepresentanteNames;

        //     this.representantes.push(representante);
        //   }
        // }
      } catch (error) {
        console.log(error);
        this.$toast.error("Error al cargar el compareciente", {
          timeout: 4000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } finally {
        this.loading = false;
      }
    },

    async getCatNacionalidades() {
      const { value } = await CatNacionalidadesApi.list({
        page: 0,
        registersPerPage: 0
      });
      this.cat_nacionalidades = value;
    },

    addDomicilio() {
      this.$refs["domicilio-modal"].show();

      this.currentDomicilio = {};
      this.currentDomicilio.tieneExtranjero = true;
      this.currentDomicilio.tieneTipo = true;
      this.currentDomicilio.compareciente = true;
      this.currentDomicilio.extranjero = false;
      // this.currentDomicilio.mapUrl =
      //   "https://www.google.com/maps/embed/v1/place?q=place_id:El5Bdi4gSnXDoXJleiwgQ2VudHJvIEhpc3TDs3JpY28gZGUgbGEgQ2RhZC4gZGUgTcOpeGljbywgQ2VudHJvLCBDaXVkYWQgZGUgTcOpeGljbywgQ0RNWCwgTWV4aWNvIi4qLAoUChIJe7aYkCr50YUR0gAJ5MV3QdUSFAoSCb1NDi4t-dGFET2JxECndFWQ&zoom=12&key=AIzaSyC1LrDy2Gk5QhV8SjD7k4S6b4zg-wF2YEA";
      this.currentDomicilio.lat = 19.4320605;
      this.currentDomicilio.lng = -99.1335938;
      // if (this.currentDomicilio.id === undefined) {
      // this.currentDomicilio.id = this.domicilios.length + 1;
      // }
    },

    addTelefono() {
      this.$refs["telefono-modal"].show();
      this.currentTelefono = {};
    },

    addRepresentante() {
      this.$refs["representante-modal"].show();
      this.currentRepresentante = {
        domicilios: [],
        telefonos: [],
        instrumento_numero: null,
        instrumento_numero_fecha: null,
        instrumento_fedatario: null,
        instrumento_fedatario_nombre: null,
        instrumento_fedatario_numero: null,
        instrumento_fedatario_estado: null,
        instrumento_rpf_folio: null,
        instrumento_rpf_folio_fecha: null,
        instrumento_rpf_folio_lugar: null,
        personaARepresentar: this.user.persona
      };
    },

    editRepresentante(representante) {
      this.$refs["representante-modal"].show();
      this.currentRepresentante = representante;
      this.current_representantes_id = representante.id;
      this.currentRepresentante.personaARepresentar = this.user.persona;
    },

    editDomicilio(domicilio) {
      this.$refs["domicilio-modal"].show();
      this.currentDomicilio = domicilio;
    },

    editTelefono(telefono) {
      this.$refs["telefono-modal"].show();
      this.currentTelefono = telefono;
    },

    changePais() {
      if (this.currentDomicilio.pais === "México") {
        this.currentDomicilio.extranjero = false;
      } else if (this.currentDomicilio.pais != "México") {
        this.currentDomicilio.extranjero = true;
      }

      const a = Object.assign({}, this.currentDomicilio);
      this.currentDomicilio = a;
    },

    tipoDomicilio() {
      if (this.currentDomicilio.tipo != "Personal") {
        if (this.currentDomicilio.tipo === "Particular") {
          this.addDomicilioBtn = false;
        } else if (this.currentDomicilio.tipo === "Extranjero") {
          this.addDomicilioBtn = false;
        }
        // else if (this.domicilios.length > 3) {
        //   this.addDomicilioBtn = false;
        //   //borra las otras direcciones?
        // }
      }

      if (this.domicilios.length >= 3) {
        this.addDomicilioBtn = false;
      }
    },

    async postDomicilio() {
      this.currentDomicilio.tieneExtranjero = true;
      this.currentDomicilio.tieneTipo = true;

      if (
        this.currentDomicilio.tipo === "Extranjero" ||
        this.currentDomicilio.tipo === "Particular" ||
        this.currentDomicilio.tipo === "Fiscal"
      ) {
        this.addDomicilioBtn = true;
      } else if (this.currentDomicilio.tipo === "Particular y Fiscal") {
        this.addDomicilioBtn = false;
      }

      //ver si es post o put
      if (this.currentDomicilio.id === undefined) {
        this.currentDomicilio.id = this.domicilios.length + 1;
        const a = Object.assign({}, this.currentDomicilio);
        this.domicilios.push(a);
      } else if (this.currentDomicilio.id != undefined) {
        const index = this.domicilios.findIndex(o => o.id === this.currentDomicilio.id);
        const a = Object.assign({}, this.currentDomicilio);
        this.domicilios.splice([index], 1, a);
      }

      this.$refs["domicilio-modal"].hide();
      this.currentDomicilio = {};

      if (this.domicilios.length === 2) {
        let count = 0;
        for (let u = 0; u < this.domicilios.length; u++) {
          if (this.domicilios[u].tipo === "Particular") {
            count++;
          } else if (this.domicilios[u].tipo === "Fiscal") {
            count++;
          }
        }
        if (count === 2) {
          this.addDomicilioBtn = false;
        }
      }

      // if (this.currentDomicilio.id === undefined) {
      //   this.currentDomicilio.id = this.domicilios.length + 1;
      // }
      // var a = Object.assign({}, this.currentDomicilio);
      // this.domicilios.push(a);
      //añade otro domicilio(?)
      // if (this.currentDomicilio.extranjero === true) {
      //   var newDom = {};
      //   newDom.pais = "México";
      //   newDom.tieneExtranjero = false;
      //   newDom.tieneTipo = true;
      //   newDom.mapUrl =
      //     "https://www.google.com/maps/embed/v1/place?q=place_id:El5Bdi4gSnXDoXJleiwgQ2VudHJvIEhpc3TDs3JpY28gZGUgbGEgQ2RhZC4gZGUgTcOpeGljbywgQ2VudHJvLCBDaXVkYWQgZGUgTcOpeGljbywgQ0RNWCwgTWV4aWNvIi4qLAoUChIJe7aYkCr50YUR0gAJ5MV3QdUSFAoSCb1NDi4t-dGFET2JxECndFWQ&zoom=12&key=AIzaSyC1LrDy2Gk5QhV8SjD7k4S6b4zg-wF2YEA";
      //   newDom.id = this.domicilios.length + 1;

      //   this.domicilios.push(newDom);
      // }
      // if (this.currentDomicilio.tipo === "Particular") {
      //   var newDom = {};
      //   newDom.tieneExtranjero = false;
      //   newDom.tieneTipo = true;
      //   newDom.tipo = "Fiscal";
      //   newDom.pais = "México";
      //   newDom.extranjero = false;
      //   newDom.mapUrl =
      //     "https://www.google.com/maps/embed/v1/place?q=place_id:El5Bdi4gSnXDoXJleiwgQ2VudHJvIEhpc3TDs3JpY28gZGUgbGEgQ2RhZC4gZGUgTcOpeGljbywgQ2VudHJvLCBDaXVkYWQgZGUgTcOpeGljbywgQ0RNWCwgTWV4aWNvIi4qLAoUChIJe7aYkCr50YUR0gAJ5MV3QdUSFAoSCb1NDi4t-dGFET2JxECndFWQ&zoom=12&key=AIzaSyC1LrDy2Gk5QhV8SjD7k4S6b4zg-wF2YEA";
      //   newDom.id = this.domicilios.length + 1;
      //   this.domicilios.push(newDom);
      // }
    },

    async postTelefono() {
      if (this.currentTelefono.id === undefined) {
        this.currentTelefono.id = this.telefonos.length + 1;
        const a = Object.assign({}, this.currentTelefono);
        this.telefonos.push(a);
      } else if (this.currentTelefono.id != undefined) {
        const index = this.telefonos.findIndex(o => o.id === this.currentTelefono.id);
        const a = Object.assign({}, this.currentTelefono);
        this.telefonos.splice([index], 1, a);
      }

      // let new_telefono = {
      //   tipo: this.currentTelefono.tipo,
      //   clave: this.currentTelefono.clave,
      //   numero: this.currentTelefono.numero,
      //   extension: this.currentTelefono.extension,
      // };
      // this.telefonos.push(new_telefono);
      this.$refs["telefono-modal"].hide();
    },

    async postRepresentante() {
      if (this.currentRepresentante.id === undefined) {
        if (this.representantes != undefined) {
          this.currentRepresentante.id = this.representantes.length;
        } else {
          this.currentRepresentante.id = 0;
        }
        this.representantes.push(this.currentRepresentante);
      } else if (this.currentRepresentante.id != undefined) {
        const index = this.representantes.findIndex(o => o.id === this.currentRepresentante.id);
        this.representantes[index] = this.currentRepresentante;
      }

      this.$refs["representante-modal"].hide();
    },

    predeleteDomicilio(id) {
      this.currentDomicilioId = id;
      this.$refs["modal-confirm-dialog-dom"].show();
      // this.$confirm({
      //   title: "¿Seguro que quiere eliminar el domicilio?",
      //   message: "Se eliminarán todos los datos.",
      //   button: {
      //     yes: "OK",
      //     no: "Cancelar",
      //   },
      //   callback: (confirm) => {
      //     if (confirm) {
      //       this.deleteDomicilio(id);
      //     }
      //   },
      // });
    },
    async deleteDomicilio() {
      let id = this.currentDomicilioId;

      const index = this.domicilios.findIndex(o => o.id == id);
      this.domicilios.splice(index, 1);
      // if (this.domicilios.length === 0) {
      //   this.addDomicilioBtn = true;
      // }
      if (this.domicilios.length > 1) {
        for (let i = 0; i < this.domicilios.length; i++) {
          if (this.domicilios[i].tipo != "Particular y Fiscal") {
            this.addDomicilioBtn = true;
          } else {
            this.addDomicilioBtn = false;
          }
        }
      } else {
        this.addDomicilioBtn = true;
      }
      this.$refs["modal-confirm-dialog-dom"].hide();
    },

    predeleteTelefono(id) {
      this.currentTelefonoId = id;
      this.$refs["modal-confirm-dialog-tel"].show();
      // this.$confirm({
      //   title: "¿Seguro que quiere eliminar el teléfono?",
      //   message: "Se eliminarán todos los datos.",
      //   button: {
      //     yes: "OK",
      //     no: "Cancelar",
      //   },
      //   callback: (confirm) => {
      //     if (confirm) {
      //       this.deleteTelefono(id);
      //     }
      //   },
      // });
    },

    async deleteTelefono() {
      const id = this.currentTelefonoId;
      const index = this.telefonos.findIndex(o => o.id == id);
      this.telefonos.splice(index, 1);
      this.$refs["modal-confirm-dialog-tel"].hide();
    },

    predeleteRepresentantes(id) {
      this.currentRepresentanteId = id;
      this.$refs["modal-confirm-dialog-repre"].show();
    },

    async deleteRepresentante() {
      const id = this.currentRepresentanteId;
      const index = this.representantes.findIndex(o => o.id == id);

      this.representantes.splice(index, 1);
      this.$refs["modal-confirm-dialog-repre"].hide();
    },

    async getClienteName(cliente_id) {
      const { nombre, paterno, materno, email } = await ClientesApi.detail(cliente_id);
      const datos_cliente = `${nombre} ${paterno} ${materno} ${email}`;
      return datos_cliente;
    },

    addDomicilioRepresentante() {
      this.$refs["domicilio-modal-representante"].show();
      this.currentDomicilio = {};
      this.currentDomicilio.tieneExtranjero = false;
      this.currentDomicilio.tieneTipo = false;
      this.currentDomicilio.tipo = "Personal";
      this.currentDomicilio.compareciente = false;
      // this.currentDomicilio.mapUrl =
      //   "https://www.google.com/maps/embed/v1/place?q=place_id:El5Bdi4gSnXDoXJleiwgQ2VudHJvIEhpc3TDs3JpY28gZGUgbGEgQ2RhZC4gZGUgTcOpeGljbywgQ2VudHJvLCBDaXVkYWQgZGUgTcOpeGljbywgQ0RNWCwgTWV4aWNvIi4qLAoUChIJe7aYkCr50YUR0gAJ5MV3QdUSFAoSCb1NDi4t-dGFET2JxECndFWQ&zoom=12&key=AIzaSyC1LrDy2Gk5QhV8SjD7k4S6b4zg-wF2YEA";
      this.currentDomicilio.lat = 19.4320605;
      this.currentDomicilio.lng = -99.1335938;
      // if (this.currentDomicilio.id === undefined) {
      // this.currentDomicilio.id =
      //   this.currentRepresentante.domicilios.length + 1;
      // }
    },

    addTelefonoRepresentante() {
      // this.current_representantes_id = representantes_id;
      this.$refs["telefono-modal-representante"].show();
      this.currentTelefono = {};
    },

    editDomicilioRepresentante(domicilio) {
      this.$refs["domicilio-modal-representante"].show();
      this.currentDomicilio = domicilio;
    },

    editTelefonoRepresentante(telefono) {
      this.$refs["telefono-modal-representante"].show();
      this.currentTelefono = telefono;
    },

    async postDomicilioRepresentante() {
      this.currentRepresentante.domicilios.tieneExtranjero = false;
      this.currentRepresentante.domicilios.tieneTipo = false;

      if (this.currentDomicilio.id === undefined) {
        this.currentDomicilio.id = this.currentRepresentante.domicilios.length + 1;
        const a = Object.assign({}, this.currentDomicilio);
        this.currentRepresentante.domicilios.push(a);
      } else if (this.currentDomicilio.id != undefined) {
        const index = this.currentRepresentante.domicilios.findIndex(o => o.id === this.currentDomicilio.id);
        const a = Object.assign({}, this.currentDomicilio);
        // this.currentRepresentante.domicilios[index] = this.currentDomicilio;
        this.currentRepresentante.domicilios.splice([index], 1, a);
      }
      this.$refs["domicilio-modal-representante"].hide();
    },

    async postTelefonoRepresentante() {
      // if (this.currentTelefono.id === undefined) {
      //   this.currentTelefono.id = this.currentRepresentante.telefonos.length;
      // }
      // this.currentRepresentante.telefonos.push(this.currentTelefono);
      //
      if (this.currentTelefono.id === undefined) {
        this.currentTelefono.id = this.currentRepresentante.telefonos.length;
        const a = Object.assign({}, this.currentTelefono);
        this.currentRepresentante.telefonos.push(a);
      } else if (this.currentTelefono.id != undefined) {
        const index = this.currentRepresentante.telefonos.findIndex(o => o.id === this.currentTelefono.id);
        const a = Object.assign({}, this.currentTelefono);
        this.currentRepresentante.telefonos.splice([index], 1, a);
      }
      this.$refs["telefono-modal-representante"].hide();
    },

    predeleteDomicilioRepresentante(domicilio_id) {
      this.currentRepresentanteDomicilioId = domicilio_id;
      this.$refs["modal-confirm-dialog-repre-dom"].show();
      // this.$confirm({
      //   title: "¿Seguro que quiere eliminar el domicilio?",
      //   message: "Se eliminarán todos los datos.",
      //   button: {
      //     yes: "OK",
      //     no: "Cancelar",
      //   },
      //   callback: (confirm) => {
      //     if (confirm) {
      //       this.deleteDomicilioRepresentante(domicilio_id);
      //     }
      //   },
      // });
    },

    async deleteDomicilioRepresentante() {
      const domicilio_id = this.currentRepresentanteDomicilioId;
      // const index2 = this.representantes.findIndex(
      //   (o) => o.id == this.current_representantes_id
      // );
      // const index = this.representantes[index2].domicilios.findIndex(
      //   (o) => o.id == domicilio_id
      // );
      // this.representantes[index2].domicilios.splice(index, 1);
      const index = this.currentRepresentante.domicilios.findIndex(o => o.id == domicilio_id);

      this.currentRepresentante.domicilios.splice(index, 1);
      this.$refs["modal-confirm-dialog-repre-dom"].hide();
    },

    predeleteTelefonoRepresentante(telefono_id) {
      this.currentRepresentanteTelefonoId = telefono_id;
      this.$refs["modal-confirm-dialog-repre-tel"].show();
      // this.$confirm({
      //   title: "¿Seguro que quiere eliminar el teléfono?",
      //   message: "Se eliminarán todos los datos.",
      //   button: {
      //     yes: "OK",
      //     no: "Cancelar",
      //   },
      //   callback: (confirm) => {
      //     if (confirm) {
      //       this.deleteTelefonoRepresentante(telefono_id);
      //     }
      //   },
      // });
    },

    async deleteTelefonoRepresentante() {
      const telefono_id = this.currentRepresentanteTelefonoId;

      // const index2 = this.representantes.findIndex(
      //   (o) => o.id == this.current_representantes_id
      // );
      // const index = this.representantes[index2].telefonos.findIndex(
      //   (o) => o.id == telefono_id
      // );
      // this.representantes[index2].telefonos.splice(index, 1);
      const index = this.currentRepresentante.telefonos.findIndex(o => o.id == telefono_id);

      this.currentRepresentante.telefonos.splice(index, 1);
      this.$refs["modal-confirm-dialog-repre-tel"].hide();
    },

    async mismaDataConstitutiva(state) {
      if (state === true) {
        this.currentRepresentante.instrumento_numero = this.user.instrumento_numero;
        this.currentRepresentante.instrumento_numero_fecha = this.user.instrumento_numero_fecha;
        this.currentRepresentante.instrumento_fedatario = this.user.instrumento_fedatario;
        this.currentRepresentante.instrumento_fedatario_nombre = this.user.instrumento_fedatario_nombre;
        this.currentRepresentante.instrumento_fedatario_numero = this.user.instrumento_fedatario_numero;
        this.currentRepresentante.instrumento_fedatario_estado = this.user.instrumento_fedatario_estado;
        this.currentRepresentante.instrumento_rpf_folio = this.user.instrumento_rpf_folio;
        this.currentRepresentante.instrumento_rpf_folio_fecha = this.user.instrumento_rpf_folio_fecha;
        this.currentRepresentante.instrumento_rpf_folio_lugar = this.user.instrumento_rpf_folio_lugar;
      } else if (state === false) {
        this.currentRepresentante.instrumento_numero = null;
        this.currentRepresentante.instrumento_numero_fecha = null;
        this.currentRepresentante.instrumento_fedatario = null;
        this.currentRepresentante.instrumento_fedatario_nombre = null;
        this.currentRepresentante.instrumento_fedatario_numero = null;
        this.currentRepresentante.instrumento_fedatario_estado = null;
        this.currentRepresentante.instrumento_rpf_folio = null;
        this.currentRepresentante.instrumento_rpf_folio_fecha = null;
        this.currentRepresentante.instrumento_rpf_folio_lugar = null;
      }
    },

    addDocumento() {
      this.$refs["documento-modal"].show();
      this.currentDocumento = {};
    },

    async postDocumento() {
      this.$refs["documento-modal"].hide();
      this.loading = true;

      await this.addFile();

      const index_s = this.optionsFisicas.findIndex(o => o.text === this.currentDocumento.tipo);
      //si el index_s no esta definido, busca en optionsMorales
      if (index_s === -1) {
        const index_s = this.optionsMorales.findIndex(o => o.text === this.currentDocumento.tipo);
        this.currentDocumento.cat_documentos_id = this.optionsMorales[index_s].value;
      } else {
        this.currentDocumento.cat_documentos_id = this.optionsFisicas[index_s].value;
      }

      if (!this.currentDocumento.id) {
        const id = this.user.documentos.length + 1;
        this.currentDocumento.id = id;

        const a = Object.assign({}, this.currentDocumento);
        this.user.documentos.push(a);
      } else if (this.currentDocumento.id != undefined) {
        const index = this.user.documentos.findIndex(o => o.id === this.currentDocumento.id);
        const a = Object.assign({}, this.currentDocumento);
        this.user.documentos.splice([index], 1, a);
      }

      this.loading = false;
    },

    async addFile() {
      // this.loading = true;
      try {
        const formData = new FormData();
        this.currentDocumento.nombreFile = this.currentDocumento.file.name;
        formData.append("file", this.currentDocumento.file);
        const value = await FuncionesApi.file(this.id, "documentosComparecientes", formData, {});
        this.currentDocumento.documento = value;
      } catch (error) {
        console.log(error);
      }
    },

    previewDocumento(item) {
      this.currentDocumento = item;

      let pretipoDocumento = item.nombreFile.split(".");
      let tipoDocumento = pretipoDocumento[pretipoDocumento.length - 1];

      if (tipoDocumento === "pdf") {
        this.$refs["documento-modal-preview-pdf"].show();
      }
      if (
        tipoDocumento === "jpg" ||
        tipoDocumento === "JPG" ||
        tipoDocumento === "jpeg" ||
        tipoDocumento === "png" ||
        tipoDocumento === "gif" ||
        tipoDocumento === "bmp" ||
        tipoDocumento === "webp" ||
        tipoDocumento === "svg" ||
        tipoDocumento === "tiff" ||
        tipoDocumento === "avif"
      ) {
        this.$refs["documento-modal-preview-img"].show();
      }
    },

    editDocumento(documento) {
      this.$refs["documento-modal"].show();
      // console.log(documento);
      this.currentDocumento = documento;
    },

    predeleteDocumento(id) {
      this.currentDocumentoId = id;
      this.$refs["modal-confirm-dialog-documento"].show();
    },

    async deleteDocumento() {
      const index2 = this.user.documentos.findIndex(o => o.id === this.currentDocumentoId);
      this.currentDocumento = this.user.documentos[index2];
      // await this.deleteFile(this.currentDocumentoId);
      await this.deleteFile(this.user.id);

      this.user.documentos.splice(index2, 1);
      this.$refs["modal-confirm-dialog-documento"].hide();
    },

    async deleteFile(id) {
      try {
        this.loading = true;

        const data = {
          url: this.currentDocumento.documento,
          id: this.id
        };

        await FuncionesApi.deleteFile("documentosComparecientes", data, {});
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async editCompareciente() {
      if (!this.tieneDocumentosRequeridos()) {
        this.$toast.error("Documentos incompletos.", {
          timeout: 4000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });

        return;
      }

      this.loading = true;

      if (this.user.email == null) {
        this.$toast.warning("Por favor escriba su Email en la seccion Datos de contacto", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
        this.loading = false;
        return;
      }


      const nacionalidadesIds = [];

      if (this.user.nacionalidades != null) {
        for (let i = 0; i < this.user.nacionalidades.length; i++) {
          const index = this.cat_nacionalidades.findIndex(o => o.nacionalidad === this.user.nacionalidades[i].nacionalidad);

          if (index !== -1) {
            const nacionalidadId = this.cat_nacionalidades[index].id;
            const obj = { nacionalidades_id: nacionalidadId };
            nacionalidadesIds.push(obj);
          }
        }
      }

      const data = {
        cliente_id: this.user.cliente_id,
        email: this.user.email,
        rfc: this.user.rfc,
        persona: this.user.persona,
        activo: 1,
        // nacionalidad: this.user.nacionalidad,
        facebook: this.user.facebook,
        twitter: this.user.twitter,
        instagram: this.user.instagram,
        linkedin: this.user.linkedin,
        nacionalidades: nacionalidadesIds,
        // documentos: this.user.documentos,
        cat_servicio_id: this.user.cat_servicio_id,
        servicio_id: this.user.servicio_id,
      };

      // const documentos = JSON.parse(JSON.stringify(this.user.documentos));
      // for (let u = 0; u < documentos.length; u++) {
      //   if (documentos[u].cat_documentos_id === "Otro") {
      //     documentos[u].descripcion = documentos[u].tipoOtro;
      //   }
      // }
      // data["documentos"] = documentos;
      data["documentos"] = structuredClone(this.user.documentos);

      if (this.user.persona === "Física") {
        data["nombre"] = this.user.nombre;
        data["paterno"] = this.user.paterno;
        data["materno"] = this.user.materno;
        data["fecha_nacimiento"] = this.user.fecha_nacimiento;
        data["lugar_nacim_ciudad"] = this.user.lugar_nacim_ciudad;
        data["lugar_nacim_estado"] = this.user.lugar_nacim_estado;
        data["lugar_nacim_pais"] = this.user.lugar_nacim_pais;
        data["curp"] = this.user.curp;
        data["ocupacion"] = this.user.ocupacion;
        data["giro_negocios"] = this.user.giro_negocios;
        data["alias"] = this.user.alias;
        data["sexo"] = this.user.sexo;
        data["pais_residencia"] = this.user.pais_residencia;
        data["estado_civil"] = this.user.estado_civil;
        data["identificacion_conyuge"] = this.user.identificacion_conyuge;
        data["regimen_patrimonial"] = this.user.regimen_patrimonial;
        data["identificacion_numero"] = this.user.identificacion_numero;

        data["estatus_migratorio"] = this.user.estatus_migratorio;
        data["fecha_ingreso_pais"] = this.user.fecha_ingreso_pais;

        if (this.user.identificacion_tipo === "Otro") {
          data["identificacion_tipo"] = this.user.identificacion_tipo_otro;
        } else {
          data["identificacion_tipo"] = this.user.identificacion_tipo;
        }

        if (this.user.identificacion_autoridad === "Otro") {
          data["identificacion_autoridad"] = this.user.identificacion_autoridad_otro;
        } else {
          data["identificacion_autoridad"] = this.user.identificacion_autoridad;
        }
      }
      if (this.user.persona === "Moral") {
        data["denominacion_social"] = this.user.denominacion_social;
        data["fecha_constitucion"] = this.user.fecha_constitucion;
        data["actividad"] = this.user.actividad;
        data["giro"] = this.user.giro;
        data["objeto_social"] = this.user.objeto_social;
        data["domicilio_social"] = this.user.domicilio_social;
        data["instrumento_numero"] = this.user.instrumento_numero;
        data["instrumento_numero_fecha"] = this.user.instrumento_numero_fecha;
        data["instrumento_fedatario"] = this.user.instrumento_fedatario;
        data["instrumento_fedatario_nombre"] = this.user.instrumento_fedatario_nombre;
        data["instrumento_fedatario_numero"] = this.user.instrumento_fedatario_numero;
        data["instrumento_fedatario_estado"] = this.user.instrumento_fedatario_estado;
        data["instrumento_rpf_folio"] = this.user.instrumento_rpf_folio;
        data["instrumento_rpf_folio_fecha"] = this.user.instrumento_rpf_folio_fecha;
        data["instrumento_rpf_folio_lugar"] = this.user.instrumento_rpf_folio_lugar;

        if (this.user.entidad_extranjera === true || this.user.entidad_extranjera === 1) {
          data["entidad_extranjera"] = 1;
        } else if (this.user.entidad_extranjera === false || this.user.entidad_extranjera === 0
        ) {
          data["entidad_extranjera"] = 0;
        }

        data["datos_prueba_constitucion"] = this.user.datos_prueba_constitucion;
      }

      if (this.user.persona === "Fideicomiso") {
        data["denominacion_social"] = this.user.denominacion_social;
        data["fecha_constitucion"] = this.user.fecha_constitucion;
        data["actividad"] = this.user.actividad;
        data["giro"] = this.user.giro;
        data["objeto_social"] = this.user.objeto_social;
        data["instrumento_numero"] = this.user.instrumento_numero;
        data["instrumento_numero_fecha"] = this.user.instrumento_numero_fecha;
        data["instrumento_fedatario"] = this.user.instrumento_fedatario;
        data["instrumento_fedatario_numero"] = this.user.instrumento_fedatario_numero;
        data["instrumento_fedatario_estado"] = this.user.instrumento_fedatario_estado;
        data["instrumento_rpf_folio"] = this.user.instrumento_rpf_folio;
        data["instrumento_rpf_folio_fecha"] = this.user.instrumento_rpf_folio_fecha;
        data["instrumento_rpf_folio_lugar"] = this.user.instrumento_rpf_folio_lugar;

        if (this.user.entidad_extranjera === true || this.user.entidad_extranjera === 1) {
          data["entidad_extranjera"] = 1;
        } else if (this.user.entidad_extranjera === false || this.user.entidad_extranjera === 0
        ) {
          data["entidad_extranjera"] = 0;
        }

        data["datos_prueba_constitucion"] = this.user.datos_prueba_constitucion;
        data["fiduciario"] = this.user.fiduciario;
        data["nombre_fideicomiso"] = this.user.nombre_fideicomiso;
        data["fideicomitente"] = this.user.fideicomitente;
        // data["tipo_fideicomiso"] = this.user.tipo_fideicomiso;
        if (this.user.identificacion_tipo === "Otro") {
          data["tipo_fideicomiso"] = this.user.tipo_fideicomiso;
        } else {
          data["tipo_fideicomiso"] = this.user.tipo_fideicomiso;
        }

        data["fecha_creacion_fideicomiso"] = this.user.fecha_creacion_fideicomiso;
        data["folio_inscripcion"] = this.user.folio_inscripcion;
        data["rfc_fideicomiso"] = this.user.rfc_fideicomiso;
        data["email_fideicomiso"] = this.user.email_fideicomiso;
        data["tel_fideicomiso"] = this.user.tel_fideicomiso;
      }

      if (this.domicilios != null) {
        for (let i = 0; i < this.domicilios.length; i++) {
          if (
            this.domicilios[i].extranjero === true ||
            this.domicilios[i].extranjero === 1
          ) {
            this.domicilios[i].extranjero = 1;
          } else if (
            this.domicilios[i].extranjero === false ||
            this.domicilios[i].extranjero === 0
          ) {
            this.domicilios[i].extranjero = 0;
          }
        }
      }
      data["domicilios"] = this.domicilios;
      data["telefonos"] = this.telefonos;

      for (let i = 0; i < this.representantes.length; i++) {
        data.actua_derecho_propio = false;

        if (
          this.representantes[i].mismo_constitutiva === true ||
          this.representantes[i].mismo_constitutiva === 1
        ) {
          this.representantes[i].mismo_constitutiva = 1;
        } else if (
          this.representantes[i].mismo_constitutiva === false ||
          this.representantes[i].mismo_constitutiva === 0
        ) {
          this.representantes[i].mismo_constitutiva = 0;
        }

        // if (
        //   this.representantes[i].mismo_anterior === true ||
        //   this.representantes[i].mismo_anterior === 1
        // ) {
        //   this.representantes[i].mismo_anterior = 1;
        // } else if (
        //   this.representantes[i].mismo_anterior === false ||
        //   this.representantes[i].mismo_anterior === 0
        // ) {
        //   this.representantes[i].mismo_anterior = 0;
        // }

        if (this.representantes[i].domicilios != null) {
          for (let k = 0; k < this.representantes[i].domicilios.length; k++) {
            if (this.representantes[i].domicilios[k].extranjero) {
              this.representantes[i].domicilios[k].extranjero = 1;
            } else {
              this.representantes[i].domicilios[k].extranjero = 0;
            }
          }
        }

        const nacionalidadesRepresentantesIds = [];
        let nacionalidadesNames = "";

        if (this.representantes[i].nacionalidades != null) {
          for (let l = 0; l < this.representantes[i].nacionalidades.length; l++) {
            const index =
              this.cat_nacionalidades.findIndex(o => o.nacionalidad === this.representantes[i].nacionalidades[l].nacionalidad);

            if (index !== -1) {
              const nacionalidadId = this.cat_nacionalidades[index].id;
              nacionalidadesRepresentantesIds.push({ nacionalidades_id: nacionalidadId });
            }

            nacionalidadesNames += `${this.representantes[i].nacionalidades[l].nacionalidad} `;
          }
        }

        this.representantes[i].nacionalidades = nacionalidadesRepresentantesIds;
        this.representantes[i].nacionalidad = nacionalidadesNames.trim();
      }

      // if (this.proceso !== 2) {
      // } else {
      //   for (const representante of this.representantes) {
      //     representante.tipo_validacion = "notificacion_pf";

      //     await ComparecientesApi.edit(representante.id, representante);
      //   }

      //   this.representantes = [];
      // }

      data["representantes"] = this.representantes;

      let tipo_validacion = "";
      //de constitutivas
      const query = this.$route.query;
      const accion = query.accion;

      if (accion === "nuevo" || accion === "edit" || accion === "valid") {
        if (this.user.persona === "Física") {
          tipo_validacion = "constitucion_pf";
        } else if (this.user.persona === "Moral") {
          tipo_validacion = "constitucion_pm";
        }
      }

      if (accion == "newFormalizacion") {
        tipo_validacion = "new_formalizacion";
      }

      if (accion == "validFormalizacion") {
        tipo_validacion = "formalizacion_pf"; //solo hay pf delegados
      }

      if (accion == "editRatificacion") {
        if (this.user.persona === "Física") {
          tipo_validacion = "ratificacion_pf";
        } else if (this.user.persona === "Moral") {
          tipo_validacion = "ratificacion_pm";
        }
      }

      if (this.proceso === 2) {
        if (this.user.persona === "Física") {
          tipo_validacion = "notificacion_pf";
        } else if (this.user.persona === "Moral") {
          tipo_validacion = "notificacion_pm";
        }
      }

      if (accion == "editcotejo" || accion == "validcotejo") {
        // tipo_validacion = "cotejo_representante";
        if (this.user.persona === "Física") {
          tipo_validacion = "notificacion_pf";
        } else if (this.user.persona === "Moral") {
          tipo_validacion = "notificacion_pm";
        }
      }

      if (!accion) {
        if (this.user.persona === "Física") {
          tipo_validacion = "individual_pf";
          if (this.ratificacion) {
            tipo_validacion = "ratificacion_pf";
          }
        } else if (this.user.persona === "Moral") {
          tipo_validacion = "individual_pm";
          if (this.ratificacion) {
            tipo_validacion = "ratificacion_pm";
          }
        } else if (this.user.persona === "Fideicomiso") {
          tipo_validacion = "individual_fid";
        }
      }

      data["tipo_validacion"] = tipo_validacion;

      try {
        await ComparecientesApi.edit(this.id, data);

        const query = Object.assign({}, this.$route.query);
        if (query) {
          let procesoAccion = query.procesoAccion;
          let procesoId = query.procesoId;

          //solo para staff
          if (procesoAccion === "validconstitucion") {
            await this.setCambios();
            //post en cambios
            for (let r = 0; r < this.cambios.length; r++) {
              let data = {
                constitucion_sociedades_id: procesoId,
                formulario: this.cambios[r].formulario,
                campo: this.cambios[r].campo,
                valor_original: this.cambios[r].valor_original,
                valor_cambio: this.cambios[r].valor_cambio
              };
              await CambiosConstitutivaApi.create(data);
            }
          }

          this.$toast.success("Compareciente actualizado", {
            timeout: 4000, //false,
            hideProgressBar: false,
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            closeButton: "button",
            icon: true
          });

          let accion = query.accion;
          if (accion === "nuevo") {
            this.$router.push({
              path: `/dashboard/procesos/newconstitucion/${procesoId}`,
              query: {
                node: "ComplementaInformacion"
              }
            });
          } else if (accion === "edit") {
            this.$router.push({
              path: `/dashboard/clienttasks/editconstitucion/${procesoId}`,
              query: {
                node: "ComplementaInformacion"
              }
            });
          } else if (accion === "valid") {
            this.$router.push({
              path: `/dashboard/tasks/valid/constitucion/${procesoId}`,
              query: {
                node: "ComplementaInformacion"
              }
            });
          } else if (accion === "newFormalizacion") {
            this.$router.push({
              path: `/dashboard/clienttasks/editformalizacion/${procesoId}`,
              query: {
                node: "ComplementaInformacion"
              }
            });
          }
          // else if (accion === "editFormalizacion") {
          //   this.$router.push({
          //     path: `/dashboard/tasks/procesos/formalizacion/${procesoId}`,
          //     query: {
          //       node: "ComplementaInformacion"
          //     }
          //   });
          // }
          else if (accion === "validFormalizacion") {
            this.$router.push({
              path: `/dashboard/tasks/valid/formalizacion/${procesoId}`,
              query: {
                node: "ComplementaInformacion"
              }
            });
          }
          else if (accion === "editRatificacion") {
            this.$router.push({
              path: `/dashboard/clienttasks/editratificacion/${procesoId}`,
              query: {
                node: "ComplementaInformacion"
              }
            });
          }
          else if (accion === "editnotificacion") {
            this.$router.push({
              path: `/dashboard/clienttasks/editnotificacion/${procesoId}`,
              query: {
                node: "ComplementaInformacion"
              }
            });
          }
          else if (accion === "validnotificacion") {
            this.$router.push({
              path: `/dashboard/tasks/valid/notificacion/${procesoId}`,
              query: {
                node: "ComplementaInformacion"
              }
            });
          }
          else if (accion === "editcotejo") {
            this.$router.push({
              path: `/dashboard/clienttasks/editcotejo/${procesoId}`,
              query: {
                node: "Solicitantes"
              }
            });
          }
          // else if (accion === "validcotejo") {
          //   this.$router.push({
          //     path: `/dashboard/tasks/valid/cotejo/${procesoId}`,
          //     query: {
          //       node: "Solicitantes"
          //     }
          //   });
          // }
        }

      } catch (error) {
        console.log(error);
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });
      } finally {
        this.loading = false;
      }

    },

    async setCambios() {
      if (this.user.persona === "Física" || this.user.persona === "Moral") {
        if (this.user.persona === "Física") {
          if (
            this.user.estatus_migratorio !=
            this.compareciente_original.estatus_migratorio
          ) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_estatus_migratorio`,
              valor_original: this.compareciente_original.estatus_migratorio,
              valor_cambio: this.user.estatus_migratorio
            };
            this.cambios.push(obj);
            this.compareciente_original.estatus_migratorio = this.user.estatus_migratorio;
          }
          if (this.user.nombre != this.compareciente_original.nombre) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_nombre`,
              valor_original: this.compareciente_original.nombre,
              valor_cambio: this.user.nombre
            };
            this.cambios.push(obj);
            this.compareciente_original.nombre = this.user.nombre;
          }
          if (this.user.paterno != this.compareciente_original.paterno) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_paterno`,
              valor_original: this.compareciente_original.paterno,
              valor_cambio: this.user.paterno
            };
            this.cambios.push(obj);
            this.compareciente_original.paterno = this.user.paterno;
          }
          if (this.user.materno != this.compareciente_original.materno) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_materno`,
              valor_original: this.compareciente_original.materno,
              valor_cambio: this.user.materno
            };
            this.cambios.push(obj);
            this.compareciente_original.materno = this.user.materno;
          }

          if (
            this.user.identificacion_tipo !=
            this.compareciente_original.identificacion_tipo
          ) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_identificacion_tipo`,
              valor_original: this.compareciente_original.identificacion_tipo,
              valor_cambio: this.user.identificacion_tipo
            };
            this.cambios.push(obj);
            this.compareciente_original.identificacion_tipo = this.user.identificacion_tipo;
          }
          if (this.user.identificacion_autoridad != this.compareciente_original.identificacion_autoridad) {
            const obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_identificacion_autoridad`,
              valor_original: this.compareciente_original.identificacion_autoridad,
              valor_cambio: this.user.identificacion_autoridad
            };
            this.cambios.push(obj);
            this.compareciente_original.identificacion_autoridad = this.user.identificacion_autoridad;
          }
          if (this.user.identificacion_numero != this.compareciente_original.identificacion_numero) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_identificacion_numero`,
              valor_original: this.compareciente_original.identificacion_numero,
              valor_cambio: this.user.identificacion_numero
            };
            this.cambios.push(obj);
            this.compareciente_original.identificacion_numero = this.user.identificacion_numero;
          }

          if (this.user.rfc != this.compareciente_original.rfc) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_rfc`,
              valor_original: this.compareciente_original.rfc,
              valor_cambio: this.user.rfc
            };
            this.cambios.push(obj);
            this.compareciente_original.rfc = this.user.rfc;
          }
          if (this.user.curp != this.compareciente_original.curp) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_curp`,
              valor_original: this.compareciente_original.curp,
              valor_cambio: this.user.curp
            };
            this.cambios.push(obj);
            this.compareciente_original.curp = this.user.curp;
          }

          if (
            this.user.fecha_nacimiento !=
            this.compareciente_original.fecha_nacimiento
          ) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_fecha_nacimiento`,
              valor_original: this.compareciente_original.fecha_nacimiento,
              valor_cambio: this.user.fecha_nacimiento
            };
            this.cambios.push(obj);
            this.compareciente_original.fecha_nacimiento = this.user.fecha_nacimiento;
          }
          try {
            for (let i = 0; i < this.user.nacionalidades.length; i++) {
              if (this.user.nacionalidades[i] != this.compareciente_original.nacionalidades[i]) {
                const obj = {
                  formulario: "Comparecientes",
                  campo: `compareciente_id_${this.id}_nacionalidades_${i}`,
                  valor_original: this.compareciente_original.nacionalidades[i],
                  valor_cambio: this.user.nacionalidades[i]
                };
                this.cambios.push(obj);
                this.compareciente_original.nacionalidades[i] = this.user.nacionalidades[i];
              }
            }
          } catch (e) {
            console.log(e);
          }
          if (this.user.sexo != this.compareciente_original.sexo) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_sexo`,
              valor_original: this.compareciente_original.sexo,
              valor_cambio: this.user.sexo
            };
            this.cambios.push(obj);
            this.compareciente_original.sexo = this.user.sexo;
          }

          if (
            this.user.lugar_nacim_ciudad !=
            this.compareciente_original.lugar_nacim_ciudad
          ) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_lugar_nacim_ciudad`,
              valor_original: this.compareciente_original.lugar_nacim_ciudad,
              valor_cambio: this.user.lugar_nacim_ciudad
            };
            this.cambios.push(obj);
            this.compareciente_original.lugar_nacim_ciudad = this.user.lugar_nacim_ciudad;
          }
          if (
            this.user.lugar_nacim_estado !=
            this.compareciente_original.lugar_nacim_estado
          ) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_lugar_nacim_estado`,
              valor_original: this.compareciente_original.lugar_nacim_estado,
              valor_cambio: this.user.lugar_nacim_estado
            };
            this.cambios.push(obj);
            this.compareciente_original.lugar_nacim_estado = this.user.lugar_nacim_estado;
          }
          if (
            this.user.lugar_nacim_pais !=
            this.compareciente_original.lugar_nacim_pais
          ) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_lugar_nacim_pais`,
              valor_original: this.compareciente_original.lugar_nacim_pais,
              valor_cambio: this.user.lugar_nacim_pais
            };
            this.cambios.push(obj);
            this.compareciente_original.lugar_nacim_pais = this.user.lugar_nacim_pais;
          }

          if (this.user.ocupacion != this.compareciente_original.ocupacion) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_ocupacion`,
              valor_original: this.compareciente_original.ocupacion,
              valor_cambio: this.user.ocupacion
            };
            this.cambios.push(obj);
            this.compareciente_original.ocupacion = this.user.ocupacion;
          }
          if (
            this.user.giro_negocios != this.compareciente_original.giro_negocios
          ) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_giro_negocios`,
              valor_original: this.compareciente_original.giro_negocios,
              valor_cambio: this.user.giro_negocios
            };
            this.cambios.push(obj);
            this.compareciente_original.giro_negocios = this.user.giro_negocios;
          }
          if (
            this.user.estado_civil != this.compareciente_original.estado_civil
          ) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_estado_civil`,
              valor_original: this.compareciente_original.estado_civil,
              valor_cambio: this.user.estado_civil
            };
            this.cambios.push(obj);
            this.compareciente_original.estado_civil = this.user.estado_civil;
          }

          if (this.user.alias != this.compareciente_original.alias) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_alias`,
              valor_original: this.compareciente_original.alias,
              valor_cambio: this.user.alias
            };
            this.cambios.push(obj);
            this.compareciente_original.alias = this.user.alias;
          }
          if (
            this.user.identificacion_conyuge !=
            this.compareciente_original.identificacion_conyuge
          ) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_identificacion_conyuge`,
              valor_original: this.compareciente_original
                .identificacion_conyuge,
              valor_cambio: this.user.identificacion_conyuge
            };
            this.cambios.push(obj);
            this.compareciente_original.identificacion_conyuge = this.user.identificacion_conyuge;
          }
          if (
            this.user.regimen_patrimonial !=
            this.compareciente_original.regimen_patrimonial
          ) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_regimen_patrimonial`,
              valor_original: this.compareciente_original.regimen_patrimonial,
              valor_cambio: this.user.regimen_patrimonial
            };
            this.cambios.push(obj);
            this.compareciente_original.regimen_patrimonial = this.user.regimen_patrimonial;
          }

          if (this.user.email != this.compareciente_original.email) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_email`,
              valor_original: this.compareciente_original.email,
              valor_cambio: this.user.email
            };
            this.cambios.push(obj);
            this.compareciente_original.email = this.user.email;
          }
          if (this.user.facebook != this.compareciente_original.facebook) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_facebook`,
              valor_original: this.compareciente_original.facebook,
              valor_cambio: this.user.facebook
            };
            this.cambios.push(obj);
            this.compareciente_original.facebook = this.user.facebook;
          }
          if (this.user.twitter != this.compareciente_original.twitter) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_twitter`,
              valor_original: this.compareciente_original.twitter,
              valor_cambio: this.user.twitter
            };
            this.cambios.push(obj);
            this.compareciente_original.twitter = this.user.twitter;
          }
          if (this.user.instagram != this.compareciente_original.instagram) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_instagram`,
              valor_original: this.compareciente_original.instagram,
              valor_cambio: this.user.instagram
            };
            this.cambios.push(obj);
            this.compareciente_original.instagram = this.user.instagram;
          }
          if (this.user.linkedin != this.compareciente_original.linkedin) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_linkedin`,
              valor_original: this.compareciente_original.linkedin,
              valor_cambio: this.user.linkedin
            };
            this.cambios.push(obj);
            this.compareciente_original.linkedin = this.user.linkedin;
          }
        } else if (this.user.persona === "Moral") {
          if (
            this.user.denominacion_social !=
            this.compareciente_original.denominacion_social
          ) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_denominacion_social`,
              valor_original: this.compareciente_original.denominacion_social,
              valor_cambio: this.user.denominacion_social
            };
            this.cambios.push(obj);
            this.compareciente_original.denominacion_social = this.user.denominacion_social;
          }

          if (this.user.actividad != this.compareciente_original.actividad) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_actividad`,
              valor_original: this.compareciente_original.actividad,
              valor_cambio: this.user.actividad
            };
            this.cambios.push(obj);
            this.compareciente_original.actividad = this.user.actividad;
          }
          if (
            this.user.fecha_constitucion !=
            this.compareciente_original.fecha_constitucion
          ) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_fecha_constitucion`,
              valor_original: this.compareciente_original.fecha_constitucion,
              valor_cambio: this.user.fecha_constitucion
            };
            this.cambios.push(obj);
            this.compareciente_original.fecha_constitucion = this.user.fecha_constitucion;
          }
          try {
            for (let i = 0; i < this.user.nacionalidades.length; i++) {
              if (this.user.nacionalidades[i] != this.compareciente_original.nacionalidades[i]) {
                let obj = {
                  formulario: "Comparecientes",
                  campo: `compareciente_id_${this.id}_nacionalidades_${i}`,
                  valor_original: this.compareciente_original.nacionalidades[i],
                  valor_cambio: this.user.nacionalidades[i]
                };
                this.cambios.push(obj);
                this.compareciente_original.nacionalidades[i] = this.user.nacionalidades[i];
              }
            }
          } catch (e) {
            // console.log(e);
          }

          if (
            this.user.objeto_social != this.compareciente_original.objeto_social
          ) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_objeto_social`,
              valor_original: this.compareciente_original.objeto_social,
              valor_cambio: this.user.objeto_social
            };
            this.cambios.push(obj);
            this.compareciente_original.objeto_social = this.user.objeto_social;
          }

          if (
            this.user.instrumento_numero !=
            this.compareciente_original.instrumento_numero
          ) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_instrumento_numero`,
              valor_original: this.compareciente_original.instrumento_numero,
              valor_cambio: this.user.instrumento_numero
            };
            this.cambios.push(obj);
            this.compareciente_original.instrumento_numero = this.user.instrumento_numero;
          }
          if (
            this.user.instrumento_numero_fecha !=
            this.compareciente_original.instrumento_numero_fecha
          ) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_instrumento_numero_fecha`,
              valor_original: this.compareciente_original
                .instrumento_numero_fecha,
              valor_cambio: this.user.instrumento_numero_fecha
            };
            this.cambios.push(obj);
            this.compareciente_original.instrumento_numero_fecha = this.user.instrumento_numero_fecha;
          }

          if (
            this.user.instrumento_fedatario !=
            this.compareciente_original.instrumento_fedatario
          ) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_instrumento_fedatario`,
              valor_original: this.compareciente_original.instrumento_fedatario,
              valor_cambio: this.user.instrumento_fedatario
            };
            this.cambios.push(obj);
            this.compareciente_original.instrumento_fedatario = this.user.instrumento_fedatario;
          }
          if (
            this.user.instrumento_fedatario_nombre !=
            this.compareciente_original.instrumento_fedatario_nombre
          ) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_instrumento_fedatario_nombre`,
              valor_original: this.compareciente_original
                .instrumento_fedatario_nombre,
              valor_cambio: this.user.instrumento_fedatario_nombre
            };
            this.cambios.push(obj);
            this.compareciente_original.instrumento_fedatario_nombre = this.user.instrumento_fedatario_nombre;
          }
          if (
            this.user.instrumento_fedatario_numero !=
            this.compareciente_original.instrumento_fedatario_numero
          ) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_instrumento_fedatario_numero`,
              valor_original: this.compareciente_original
                .instrumento_fedatario_numero,
              valor_cambio: this.user.instrumento_fedatario_numero
            };
            this.cambios.push(obj);
            this.compareciente_original.instrumento_fedatario_numero = this.user.instrumento_fedatario_numero;
          }
          if (
            this.user.instrumento_fedatario_estado !=
            this.compareciente_original.instrumento_fedatario_estado
          ) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_instrumento_fedatario_estado`,
              valor_original: this.compareciente_original
                .instrumento_fedatario_estado,
              valor_cambio: this.user.instrumento_fedatario_estado
            };
            this.cambios.push(obj);
            this.compareciente_original.instrumento_fedatario_estado = this.user.instrumento_fedatario_estado;
          }

          if (
            this.user.instrumento_rpf_folio !=
            this.compareciente_original.instrumento_rpf_folio
          ) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_instrumento_rpf_folio`,
              valor_original: this.compareciente_original.instrumento_rpf_folio,
              valor_cambio: this.user.instrumento_rpf_folio
            };
            this.cambios.push(obj);
            this.compareciente_original.instrumento_rpf_folio = this.user.instrumento_rpf_folio;
          }
          if (
            this.user.instrumento_rpf_folio_fecha !=
            this.compareciente_original.instrumento_rpf_folio_fecha
          ) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_instrumento_rpf_folio_fecha`,
              valor_original: this.compareciente_original
                .instrumento_rpf_folio_fecha,
              valor_cambio: this.user.instrumento_rpf_folio_fecha
            };
            this.cambios.push(obj);
            this.compareciente_original.instrumento_rpf_folio_fecha = this.user.instrumento_rpf_folio_fecha;
          }
          if (
            this.user.instrumento_rpf_folio_lugar !=
            this.compareciente_original.instrumento_rpf_folio_lugar
          ) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_instrumento_rpf_folio_lugar`,
              valor_original: this.compareciente_original
                .instrumento_rpf_folio_lugar,
              valor_cambio: this.user.instrumento_rpf_folio_lugar
            };
            this.cambios.push(obj);
            this.compareciente_original.instrumento_rpf_folio_lugar = this.user.instrumento_rpf_folio_lugar;
          }

          if (
            this.user.entidad_extranjera !=
            this.compareciente_original.entidad_extranjera
          ) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_entidad_extranjera`,
              valor_original: this.compareciente_original.entidad_extranjera,
              valor_cambio: this.user.entidad_extranjera
            };
            this.cambios.push(obj);
            this.compareciente_original.entidad_extranjera = this.user.entidad_extranjera;
          }

          if (this.user.rfc != this.compareciente_original.rfc) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_rfc`,
              valor_original: this.compareciente_original.rfc,
              valor_cambio: this.user.rfc
            };
            this.cambios.push(obj);
            this.compareciente_original.rfc = this.user.rfc;
          }
          if (this.user.email != this.compareciente_original.email) {
            let obj = {
              formulario: "Compareciente",
              campo: `compareciente_id_${this.id}_email`,
              valor_original: this.compareciente_original.email,
              valor_cambio: this.user.email
            };
            this.cambios.push(obj);
            this.compareciente_original.email = this.user.email;
          }
        }

        try {
          for (let i = 0; i < this.domicilios.length; i++) {
            const objKeys = Object.keys(this.domicilios[i]);
            objKeys.forEach(key => {
              if (
                this.domicilios[i][key] != this.domicilios_originales[i][key]
              ) {
                let obj = {
                  formulario: "Compareciente",
                  campo: `compareciente_id_${this.id}_domicilios_${i}_${[key]}`,
                  valor_original: this.domicilios_originales[i][key],
                  valor_cambio: this.domicilios[i][key]
                };
                this.cambios.push(obj);
                this.domicilios_originales[i][key] = this.domicilios[i][key];
              }
            });
          }
        } catch (e) {
          // console.log(e);
        }

        try {
          for (let i = 0; i < this.telefonos.length; i++) {
            const objKeys = Object.keys(this.telefonos[i]);
            objKeys.forEach(key => {
              if (this.telefonos[i][key] != this.telefonos_originales[i][key]) {
                let obj = {
                  formulario: "Compareciente",
                  campo: `compareciente_id_${this.id}_telefono_${i}_${[key]}`,
                  valor_original: this.telefonos_originales[i][key],
                  valor_cambio: this.telefonos[i][key]
                };
                this.cambios.push(obj);
                this.telefonos_originales[i][key] = this.telefonos[i][key];
              }
            });
          }
        } catch (e) {
          // console.log(e);
        }

        try {
          for (let i = 0; i < this.user.documentos.length; i++) {
            if (
              this.user.documentos[i].tipo != this.documentos_originales[i].tipo
            ) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id}_documentos_${i}_tipo`,
                valor_original: this.documentos_originales[i].tipo,
                valor_cambio: this.user.documentos[i].tipo
              };
              this.cambios.push(obj);
              this.documentos_originales[i].tipo = this.user.documentos[i].tipo;
            }

            if (
              this.user.documentos[i].documento !=
              this.documentos_originales[i].documento
            ) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id}_documentos_${i}_documento`,
                valor_original: this.documentos_originales[i].documento,
                valor_cambio: this.user.documentos[i].documento
              };
              this.cambios.push(obj);
              this.documentos_originales[i].documento = this.user.documentos[
                i
              ].documento;
            }
          }
        } catch (e) {
          // console.log(e);
        }

        if (this.representantes_originales.length > 0) {
          for (let i = 0; i < this.representantes.length; i++) {
            if (
              this.representantes[i].nombre !=
              this.representantes_originales[i].nombre
            ) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id}_representante_${i}_nombre`,
                valor_original: this.representantes[i].nombre,
                valor_cambio: this.representantes_originales[i].nombre
              };
              this.cambios.push(obj);
              this.representantes_originales[i].nombre = this.representantes[
                i
              ].nombre;
            }
            if (
              this.representantes[i].paterno !=
              this.representantes_originales[i].paterno
            ) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id}_representante_${i}_paterno`,
                valor_original: this.representantes[i].paterno,
                valor_cambio: this.representantes_originales[i].paterno
              };
              this.cambios.push(obj);
              this.representantes_originales[i].paterno = this.representantes[
                i
              ].paterno;
            }
            if (
              this.representantes[i].materno !=
              this.representantes_originales[i].materno
            ) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id}_representante_${i}_materno`,
                valor_original: this.representantes[i].materno,
                valor_cambio: this.representantes_originales[i].materno
              };
              this.cambios.push(obj);
              this.representantes_originales[i].materno = this.representantes[
                i
              ].materno;
            }

            if (
              this.representantes[i].rfc !=
              this.representantes_originales[i].rfc
            ) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id}_representante_${i}_rfc`,
                valor_original: this.representantes[i].rfc,
                valor_cambio: this.representantes_originales[i].rfc
              };
              this.cambios.push(obj);
              this.representantes_originales[i].rfc = this.representantes[
                i
              ].rfc;
            }
            if (
              this.representantes[i].curp !=
              this.representantes_originales[i].curp
            ) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id}_representante_${i}_curp`,
                valor_original: this.representantes[i].curp,
                valor_cambio: this.representantes_originales[i].curp
              };
              this.cambios.push(obj);
              this.representantes_originales[i].curp = this.representantes[
                i
              ].curp;
            }
            if (
              this.representantes[i].email !=
              this.representantes_originales[i].email
            ) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id}_representante_${i}_email`,
                valor_original: this.representantes[i].email,
                valor_cambio: this.representantes_originales[i].email
              };
              this.cambios.push(obj);
              this.representantes_originales[i].email = this.representantes[
                i
              ].email;
            }

            if (
              this.representantes[i].fecha_nacimiento !=
              this.representantes_originales[i].fecha_nacimiento
            ) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id
                  }_representante_${i}_fecha_nacimiento`,
                valor_original: this.representantes[i].fecha_nacimiento,
                valor_cambio: this.representantes_originales[i].fecha_nacimiento
              };
              this.cambios.push(obj);
              this.representantes_originales[
                i
              ].fecha_nacimiento = this.representantes[i].fecha_nacimiento;
            }
            if (this.representantes[i].nacionalidad != this.representantes_originales[i].nacionalidad) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id
                  }_representante_${i}_nacionalidad`,
                valor_original: this.representantes[i].nacionalidad,
                valor_cambio: this.representantes_originales[i].nacionalidad
              };
              this.cambios.push(obj);
              this.representantes_originales[
                i
              ].nacionalidad = this.representantes[i].nacionalidad;
            }

            if (
              this.representantes[i].lugar_nacim_ciudad !=
              this.representantes_originales[i].lugar_nacim_ciudad
            ) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id
                  }_representante_${i}_lugar_nacim_ciudad`,
                valor_original: this.representantes[i].lugar_nacim_ciudad,
                valor_cambio: this.representantes_originales[i]
                  .lugar_nacim_ciudad
              };
              this.cambios.push(obj);
              this.representantes_originales[
                i
              ].lugar_nacim_ciudad = this.representantes[i].lugar_nacim_ciudad;
            }
            if (
              this.representantes[i].lugar_nacim_estado !=
              this.representantes_originales[i].lugar_nacim_estado
            ) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id
                  }_representante_${i}_lugar_nacim_estado`,
                valor_original: this.representantes[i].lugar_nacim_estado,
                valor_cambio: this.representantes_originales[i]
                  .lugar_nacim_estado
              };
              this.cambios.push(obj);
              this.representantes_originales[
                i
              ].lugar_nacim_estado = this.representantes[i].lugar_nacim_estado;
            }
            if (
              this.representantes[i].lugar_nacim_pais !=
              this.representantes_originales[i].lugar_nacim_pais
            ) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id
                  }_representante_${i}_lugar_nacim_pais`,
                valor_original: this.representantes[i].lugar_nacim_pais,
                valor_cambio: this.representantes_originales[i].lugar_nacim_pais
              };
              this.cambios.push(obj);
              this.representantes_originales[
                i
              ].lugar_nacim_pais = this.representantes[i].lugar_nacim_pais;
            }

            if (
              this.representantes[i].identificacion_tipo !=
              this.representantes_originales[i].identificacion_tipo
            ) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id
                  }_representante_${i}_identificacion_tipo`,
                valor_original: this.representantes[i].identificacion_tipo,
                valor_cambio: this.representantes_originales[i]
                  .identificacion_tipo
              };
              this.cambios.push(obj);
              this.representantes_originales[
                i
              ].identificacion_tipo = this.representantes[
                i
              ].identificacion_tipo;
            }
            if (
              this.representantes[i].identificacion_autoridad !=
              this.representantes_originales[i].identificacion_autoridad
            ) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id
                  }_representante_${i}_identificacion_autoridad`,
                valor_original: this.representantes[i].identificacion_autoridad,
                valor_cambio: this.representantes_originales[i]
                  .identificacion_autoridad
              };
              this.cambios.push(obj);
              this.representantes_originales[
                i
              ].identificacion_autoridad = this.representantes[
                i
              ].identificacion_autoridad;
            }
            if (
              this.representantes[i].identificacion_numero !=
              this.representantes_originales[i].identificacion_numero
            ) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id
                  }_representante_${i}_identificacion_numero`,
                valor_original: this.representantes[i].identificacion_numero,
                valor_cambio: this.representantes_originales[i]
                  .identificacion_numero
              };
              this.cambios.push(obj);
              this.representantes_originales[
                i
              ].identificacion_numero = this.representantes[
                i
              ].identificacion_numero;
            }

            if (
              this.representantes[i].instrumento_numero !=
              this.representantes_originales[i].instrumento_numero
            ) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id
                  }_representante_${i}_instrumento_numero`,
                valor_original: this.representantes[i].instrumento_numero,
                valor_cambio: this.representantes_originales[i]
                  .instrumento_numero
              };
              this.cambios.push(obj);
              this.representantes_originales[
                i
              ].instrumento_numero = this.representantes[i].instrumento_numero;
            }
            if (
              this.representantes[i].instrumento_numero_fecha !=
              this.representantes_originales[i].instrumento_numero_fecha
            ) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id
                  }_representante_${i}_instrumento_numero_fecha`,
                valor_original: this.representantes[i].instrumento_numero_fecha,
                valor_cambio: this.representantes_originales[i]
                  .instrumento_numero_fecha
              };
              this.cambios.push(obj);
              this.representantes_originales[
                i
              ].instrumento_numero_fecha = this.representantes[
                i
              ].instrumento_numero_fecha;
            }

            if (
              this.representantes[i].instrumento_fedatario !=
              this.representantes_originales[i].instrumento_fedatario
            ) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id
                  }_representante_${i}_instrumento_fedatario`,
                valor_original: this.representantes[i].instrumento_fedatario,
                valor_cambio: this.representantes_originales[i]
                  .instrumento_fedatario
              };
              this.cambios.push(obj);
              this.representantes_originales[
                i
              ].instrumento_fedatario = this.representantes[
                i
              ].instrumento_fedatario;
            }
            if (
              this.representantes[i].instrumento_fedatario_nombre !=
              this.representantes_originales[i].instrumento_fedatario_nombre
            ) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id
                  }_representante_${i}_instrumento_fedatario_nombre`,
                valor_original: this.representantes[i]
                  .instrumento_fedatario_nombre,
                valor_cambio: this.representantes_originales[i]
                  .instrumento_fedatario_nombre
              };
              this.cambios.push(obj);
              this.representantes_originales[
                i
              ].instrumento_fedatario_nombre = this.representantes[
                i
              ].instrumento_fedatario_nombre;
            }
            if (
              this.representantes[i].instrumento_fedatario_numero !=
              this.representantes_originales[i].instrumento_fedatario_numero
            ) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id
                  }_representante_${i}_instrumento_fedatario_numero`,
                valor_original: this.representantes[i]
                  .instrumento_fedatario_numero,
                valor_cambio: this.representantes_originales[i]
                  .instrumento_fedatario_numero
              };
              this.cambios.push(obj);
              this.representantes_originales[
                i
              ].instrumento_fedatario_numero = this.representantes[
                i
              ].instrumento_fedatario_numero;
            }
            if (
              this.representantes[i].instrumento_fedatario_estado !=
              this.representantes_originales[i].instrumento_fedatario_estado
            ) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id
                  }_representante_${i}_instrumento_fedatario_estado`,
                valor_original: this.representantes[i]
                  .instrumento_fedatario_estado,
                valor_cambio: this.representantes_originales[i]
                  .instrumento_fedatario_estado
              };
              this.cambios.push(obj);
              this.representantes_originales[
                i
              ].instrumento_fedatario_estado = this.representantes[
                i
              ].instrumento_fedatario_estado;
            }

            if (
              this.representantes[i].instrumento_rpf_folio !=
              this.representantes_originales[i].instrumento_rpf_folio
            ) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id
                  }_representante_${i}_instrumento_rpf_folio`,
                valor_original: this.representantes[i].instrumento_rpf_folio,
                valor_cambio: this.representantes_originales[i]
                  .instrumento_rpf_folio
              };
              this.cambios.push(obj);
              this.representantes_originales[
                i
              ].instrumento_rpf_folio = this.representantes[
                i
              ].instrumento_rpf_folio;
            }
            if (
              this.representantes[i].instrumento_rpf_folio_fecha !=
              this.representantes_originales[i].instrumento_rpf_folio_fecha
            ) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id
                  }_representante_${i}_instrumento_rpf_folio_fecha`,
                valor_original: this.representantes[i]
                  .instrumento_rpf_folio_fecha,
                valor_cambio: this.representantes_originales[i]
                  .instrumento_rpf_folio_fecha
              };
              this.cambios.push(obj);
              this.representantes_originales[
                i
              ].instrumento_rpf_folio_fecha = this.representantes[
                i
              ].instrumento_rpf_folio_fecha;
            }
            if (
              this.representantes[i].instrumento_rpf_folio_lugar !=
              this.representantes_originales[i].instrumento_rpf_folio_lugar
            ) {
              let obj = {
                formulario: "Compareciente",
                campo: `compareciente_id_${this.id
                  }_representante_${i}_instrumento_rpf_folio_lugar`,
                valor_original: this.representantes[i]
                  .instrumento_rpf_folio_lugar,
                valor_cambio: this.representantes_originales[i]
                  .instrumento_rpf_folio_lugar
              };
              this.cambios.push(obj);
              this.representantes_originales[
                i
              ].instrumento_rpf_folio_lugar = this.representantes[
                i
              ].instrumento_rpf_folio_lugar;
            }

            try {
              for (
                let j = 0;
                j < this.representantes[i].domicilios.length;
                j++
              ) {
                const objKeys = Object.keys(
                  this.representantes[i].domicilios[j]
                );
                objKeys.forEach(key => {
                  if (
                    this.representantes[i].domicilios[j][key] !=
                    this.representantes[i].domicilios_originales[j][key]
                  ) {
                    let obj = {
                      formulario: "Compareciente",
                      campo: `compareciente_id_${this.id
                        }_representante_${i}_domicilio_${j}_${[key]}`,
                      valor_original: this.representantes[i]
                        .domicilios_originales[j][key],
                      valor_cambio: this.representantes[i].domicilios[j][key]
                    };
                    this.cambios.push(obj);
                    this.representantes[i].domicilios_originales[j][
                      key
                    ] = this.representantes[i].domicilios[j][key];
                  }
                });
              }
            } catch (e) {
              // console.log(e);
            }

            try {
              for (let j = 0; j < this.representantes[i].telefonos.length; j++
              ) {
                const objKeys = Object.keys(
                  this.representantes[i].telefonos[j]
                );
                objKeys.forEach(key => {
                  if (
                    this.representantes[i].telefonos[j][key] !=
                    this.representantes[i].telefonos_originales[j][key]
                  ) {
                    const obj = {
                      formulario: "Compareciente",
                      campo: `compareciente_id_${this.id}_representante_${i}_telefono_${j}_${[key]}`,
                      valor_original: this.representantes[i].telefonos_originales[j][key],
                      valor_cambio: this.representantes[i].telefonos[j][key]
                    };
                    this.cambios.push(obj);
                    this.representantes[i].telefonos_originales[j][key] = this.representantes[i].telefonos[j][key];
                  }
                });
              }
            } catch (e) {
              // console.log(e);
            }
          }
        }
      }
    },

    tieneDocumentosRequeridos() {
      if (this.proceso === 4) return true;

      if (this.user.persona === "Física") {
        const documentos = this.user.documentos;

        if (documentos.length === 0) {
          return false;
        }

        if (!documentos.some(x => Number(x.cat_documentos_id) === 17)) {
          return false;
        }
      }

      if (this.user.persona === "Moral") {
        const documentos = this.user.documentos;

        if (documentos.length === 0) {
          return false;
        }

        if (!documentos.some(x => Number(x.cat_documentos_id) === 14)) {
          return false;
        }
      }

      return true;
    }
  }
};
</script>

<style scoped>
.client {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}

.btnG {
  margin-bottom: 5%;
}

/* .wider {
  max-width: 500px;
} */
</style>
