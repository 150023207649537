<template>
  <div class="container">
    <div v-if="loading">
      <div id="loader">
        <font-awesome-icon icon="spinner" spin />
      </div>
    </div>

    <b-modal no-close-on-backdrop centered ref="compareciente-modal" title="" hide-footer id="modalC" size="xl">
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(putCompareciente)">
          <ModalCompletaCompareciente :currentCompareciente="currentCompareciente"
            :solicitantes="notificacion.solicitantes" :notificacion="notificacion" @submit.prevent />
        </form>
      </ValidationObserver>
    </b-modal>

    <!-- <b-modal no-close-on-backdrop centered ref="compareciente-modal" title="" hide-footer id="modalC" size="xl">
      <ComplementaInformacionSolicitantes :current-solicitante="currentCompareciente" :max-number-of-representantes="1"
        @submit-form="putCompareciente" />
    </b-modal> -->

    <h4 class="cardD">Información de los Solicitantes</h4>
    <span v-if="!datosCompletos" class="warningData">Favor de completar los datos de los solicitantes.</span>
    <div>
      <b-table ref="table" hover :fields="fieldsCuadro" :items="notificacion.solicitantes">
        <template #cell(acciones)="{ item }">
          <b-button variant="warning" size="sm" v-b-tooltip.hover title="Editar" class="editBtn"
            @click="editCompareciente(item)">
            <b-icon-pencil />
          </b-button>
        </template>
      </b-table>
    </div>

    <div class="row mt-4" v-if="!notificacion.staff">
      <div class="col-md-12 d-flex">
        <ValidationProvider name="validacionDatos" :rules="{ required: { allowFalse: false } }" v-slot="{ errors }"
          :custom-messages="{ required: 'La confirmación es obligatoria' }">
          <b-form-checkbox id="checkbox-validacionDatos" v-model="validacionDatos" @change="validate" class="orange">
            Acepto que la información proporcionada es correcta.
          </b-form-checkbox>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <div class="mt-4" v-if="!notificacion.staff">
      <div class="">
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
          :disabled="!validacionDatos || notificacion.id_estatus != 8 && !datosCompletos">Continuar</b-button>
      </div>
    </div>

    <div class="mt-4" v-if="notificacion.staff">
      <div class="">
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m"
          :disabled="notificacion.validaSolicitantes == 1">Validar</b-button>
      </div>
    </div>

  </div>
</template>

<script>
import ModalCompletaCompareciente from "@/views/clients/procesos/notificaciones/completaInfo/modales/ModalCompletaCompareciente.vue";
import ComplementaInformacionSolicitantes from "./ComplementaInformacionSolicitantes.vue";

import { SolicitantesApi, ComparecientesApi } from "@/api";
import { showToast } from "@/utils/showToast";

export default {
  name: "ComplementaSolicitantes",

  components: {
    ModalCompletaCompareciente,
    ComplementaInformacionSolicitantes
  },

  props: {
    notificacion: { type: Object },
    isStaff: { type: Boolean, default: false }
  },

  computed: {
    fieldsCuadro() {
      return [
        { key: "index", label: "", class: "text-center" },
        { key: "nombreCompleto", label: "Nombre", class: "text-center" },
        { key: "persona", label: "Persona", class: "text-center" },
        { key: "completo", label: "Información Completa", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" },
      ];
    },
    isAvailableForContinue() {
      return this.datosCompletos && !this.notificacion.staff;
    }
  },

  async created() {
    // Obtener ID de notificacion desde la URL
    const id = this.$route.params.id;
    // Inicializar la lista de solicitantes en la notificacion
    this.notificacion.solicitantes = [];
    // Poblar lista de solicitantes
    await this.getSolicitantes(id);
    this.searchMoralRepresentada();
    // console.log(this.notificacion.solicitantes);
  },

  data() {
    return {
      loading: false,

      currentCompareciente: null,
      datosCompletos: false,
      datosFisica: [
        'nombre',
        'paterno',
        'fecha_nacimiento',
        'curp',
        'rfc',
        'ocupacion',
        'lugar_nacim_ciudad',
        'lugar_nacim_estado',
        'lugar_nacim_pais',
        'email'
      ],
      datosMoral: [
        'denominacion_social',
        'fecha_constitucion'
      ],

      validacionDatos: false,
    }
  },

  methods: {
    // TODO: Refactorizar las peticiones
    async getSolicitantes(id) {
      try {
        this.loading = true;

        const { value } = await SolicitantesApi.list({
          id_notificaciones: id
        });

        for (const solicitante of value) {
          let signatario = 0;

          await this.getCompareciente(solicitante.id_comparecientes, signatario, solicitante.id, solicitante.id_notificaciones);
        }
      } catch (error) {
        this.loading = false;
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async getCompareciente(id, signatario, id_solicitante, id_notificaciones) {
      try {
        this.loading = true;

        const compareciente = await ComparecientesApi.detail(id);

        let nombreCompleto = '';
        if (compareciente.persona === 'Física') {
          nombreCompleto = compareciente.nombre + ' ' + compareciente.paterno;
          if (compareciente.materno !== null && compareciente.materno !== undefined) {
            nombreCompleto += ' ' + compareciente.materno;
          }
          compareciente.tipo_validacion = 'notificacion_pf';
          if (compareciente.fecha_nacimiento) {
            compareciente.fecha_nacimiento = compareciente.fecha_nacimiento.substring(0, 10);
          }
        } else {
          nombreCompleto = compareciente.denominacion_social;
          compareciente.tipo_validacion = 'notificacion_pm';
          if (compareciente.fecha_constitucion) {
            compareciente.fecha_constitucion = compareciente.fecha_constitucion.substring(0, 10);
          }
        }

        compareciente.id_comparecientes = id;
        compareciente.id_solicitante = id_solicitante;
        compareciente.id_notificaciones = id_notificaciones;
        compareciente.nombreCompleto = nombreCompleto;
        compareciente.signatario = signatario;
        compareciente.id_estatus = this.notificacion.id_estatus;

        if (compareciente.telefonos.length == 0) {
          compareciente.telefonos.push({ tipo: 'Celular' });
        }

        this.notificacion.solicitantes.push(compareciente);
        this.revisaInformacionCompareciente(compareciente);
      } catch (error) {
        this.loading = false;
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 4000, //false,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
        console.log(error);
      } finally {
        this.revisaInformacionCompleta();
        this.loading = false;
      }
    },

    searchMoralRepresentada() {
      let index = -1;

      for (const solicitante of this.notificacion.solicitantes) {
        if (solicitante.moral_representada_id) {
          index = this.notificacion.solicitantes.findIndex(o => o.id === solicitante.id);
        }
      }

      if (index !== -1) {
        this.notificacion.solicitantes.splice(index, 1);
      }
    },

    editCompareciente(item) {
      // this.$refs["compareciente-modal"].show();
      // this.currentCompareciente = item;

      let accion = "editnotificacion";

      if (this.isStaff) {
        accion = "validnotificacion";
      }

      this.$router.push({
        path: `/dashboard/comparecientes/editcomparecientes/${item.id}`,
        query: {
          proceso: 2,
          procesoId: this.notificacion.id,
          accion: accion,
        },
      });
    },

    putCompareciente() {
      let nombreCompleto = "";

      if (this.currentCompareciente.persona === 'Física') {
        nombreCompleto = this.currentCompareciente.nombre + ' ' + this.currentCompareciente.paterno + ' ' + this.currentCompareciente.materno;
      } else {
        nombreCompleto = this.currentCompareciente.denominacion_social;
      }

      this.revisaInformacionCompareciente(this.currentCompareciente);
      const index = this.notificacion.solicitantes.findIndex(o => o.id === this.currentCompareciente.id);
      this.currentCompareciente.editado = true;
      this.notificacion.solicitantes[index].nombreCompleto = nombreCompleto;
      this.notificacion.solicitantes[index].email = this.currentCompareciente.email;
      this.$refs.table.refresh();
      this.$refs["compareciente-modal"].hide();
      this.revisaInformacionCompleta();
    },

    revisaInformacionCompareciente(compareciente) {
      let campos_a_revisar = [];
      if (compareciente.persona === 'Física') {
        campos_a_revisar = this.datosFisica;
      } else if (compareciente.persona === 'Moral') {
        campos_a_revisar = this.datosMoral;
      }
      campos_a_revisar.forEach(campo => {
        if (!compareciente[campo]) {
          compareciente.completo = null;

          return;
        }

        compareciente.completo = '✓';
      })
    },

    revisaInformacionCompleta() {
      this.notificacion.solicitantes.forEach(solicitante => {
        if (!solicitante.completo) {
          this.datosCompletos = false;

          return;
        }
        this.datosCompletos = true;
      });
    },
    validate() {
      if (!this.isAvailableForContinue) {
        showToast(
          this,
          "Favor de completar los datos de los solicitantes. Puede hacerlo desde el botón 'Acciones'", {
          timeout: 5000,
        },
          "error"
        );
        this.validacionDatos = false;
      } else {
        this.validacionDatos = true;
      }

    }
  }
}
</script>

<style scoped>
.container>>>.control-label {
  margin-left: 0px;
  width: 350px;
  position: absolute;
  vertical-align: middle;
}

.container>>>.form-control {
  width: 300px;
  margin-left: 260px;
  position: absolute;
}

.container>>>.custom-select {
  width: 150px;
  margin-left: 50px;
  position: absolute;
  vertical-align: middle;
}

.container>>>#razonsocial {
  width: 500px !important;
}
</style>
