<template>
  <div class="content">
    <!-- TODO: Quitar lo relacionado a video -->
    <b-modal no-close-on-backdrop id="modal-videos-servicios" size="xl" centered hide-footer>
      <!-- <ModalPreviewVideo :current-documento="videoObject" /> -->
      <p class="info-text">Para mayor información y acceso al contenido Regístrate con nosotros</p>
      <div class="text-center">
        <a class="btn orange-btn" @click="goToRegistro">Regístrate</a>
      </div>
    </b-modal>

    <div class="container-fluid">
      <div class="row">
        <div class="col-md-3">
          <b-card class="cardServicio">
            <template slot="header">
              <h4 class="card-title">Constitución de sociedades</h4>
            </template>
            <div class="text-center long">
              <img src="/img/designed/constSocie.PNG" alt="" class="imgCard" />
            </div>
            <template slot="footer">
              <hr />
              <a class="route" style="cursor: pointer;" @click="openModalVideo('constitutiva')">Más información</a>
            </template>
          </b-card>
        </div>
        <div class="col-md-3">
          <b-card class="cardServicio">
            <template slot="header">
              <h4 class="card-title">Formalización de actas</h4>
            </template>
            <div class="text-center long">
              <img src="/img/designed/formActas.PNG" alt="" class="imgCard" />
            </div>
            <template slot="footer">
              <hr />
              <a class="route" style="cursor: pointer;" @click="openModalVideo('formalizaciones')">Más información</a>
            </template>
          </b-card>
        </div>
        <div class="col-md-3">
          <b-card class="cardServicio">
            <template slot="header">
              <h4 class="card-title">Notificaciones</h4>
            </template>
            <div class="text-center long">
              <img src="/img/designed/notifs.PNG" alt="" class="imgCard" />
            </div>
            <template slot="footer">
              <hr />
              <a class="route" style="cursor: pointer;" @click="openModalVideo('notificaciones')">Más información</a>
            </template>
          </b-card>
        </div>
        <div class="col-md-3">
          <b-card class="cardServicio">
            <template slot="header">
              <h4 class="card-title">Copias certificadas</h4>
            </template>
            <div class="text-center long">
              <img src="/img/designed/cotejoDocs.PNG" alt="" class="imgCard" />
            </div>
            <template slot="footer">
              <hr />
              <a class="route" style="cursor: pointer;" @click="openModalVideo('cotejos')">Más información</a>
            </template>
          </b-card>
        </div>
      </div>

      <div class="row mb-5"></div>

      <div class="row">
        <div class="col-md-3">
          <card class="cardServicio">
            <template slot="header">
              <h4 class="card-title">Fes de hechos</h4>
            </template>
            <div class="text-center long">
              <img src="/img/designed/fesHechos.PNG" alt="" class="imgCard" />
            </div>
            <template slot="footer">
              <hr />
              <a class="route" style="cursor: pointer;" @click="openModalVideo('fe_hechos')">Más información</a>
            </template>
          </card>
        </div>
        <div class="col-md-3">
          <card class="cardServicio">
            <template slot="header">
              <h4 class="card-title">Ratificación de firmas</h4>
            </template>
            <div class="text-center long">
              <img src="/img/designed/ratifFirmas.PNG" alt="" class="imgCard" />
            </div>
            <template slot="footer">
              <hr />
              <a class="route" style="cursor: pointer;" @click="openModalVideo('ratificaciones')">Más información</a>
            </template>
          </card>
        </div>
        <div class="col-md-3">
          <card class="cardServicio">
            <template slot="header">
              <h4 class="card-title">Firma Digital de Documentos</h4>
            </template>
            <div class="text-center long">
              <img src="/img/designed/ratifFirmasDigital.png" alt="" class="imgCard" />
            </div>
            <template slot="footer">
              <hr />
              <a class="route" style="cursor: pointer;" @click="openModalVideo('ratificaciones')">Más información</a>
            </template>
          </card>
        </div>
        <div class="col-md-3">
          <card class="cardServicio">
            <template slot="header">
              <h4 class="card-title">Avalúos de mejoras</h4>
            </template>
            <div class="text-center long">
              <img src="/img/designed/avaluosMej.PNG" alt="" class="imgCard" />
            </div>
            <template slot="footer">
              <hr />
              <a class="route" style="cursor: pointer;" @click="openModalVideo('avaluos')">Más información</a>
            </template>
          </card>
        </div>
        <div class="col-md-1"></div>
      </div>
    </div>

  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";

import config from "@/config";

export default {
  name: "ServiciosComponent",

  components: {
    Card,
  },

  data() {
    return {
      videoObject: {
        documento: "",
        fileType: "video/webm",
        poster: "/img/cp_logooficial_fondoNEGRO.png",
        autoplay: true
      }
    }
  },

  methods: {
    openModalVideo(servicio) {
      switch (servicio) {
        case "constitutiva":
          this.videoObject.documento
            = config.STG_BASE_URL + "/assets/videos/constitutivas_presentacion.webm" + config.STG_ACCESS_KEY;
          break;
        case "formalizaciones":
          this.videoObject.documento
            = config.STG_BASE_URL + "/assets/videos/formalizaciones_presentacion.webm" + config.STG_ACCESS_KEY;
          break;
        case "notificaciones":
          this.videoObject.documento
            = config.STG_BASE_URL + "/assets/videos/notificaciones_presentacion.webm" + config.STG_ACCESS_KEY;
          break;
        case "cotejos":
          this.videoObject.documento
            = config.STG_BASE_URL + "/assets/videos/cotejos_presentacion.webm" + config.STG_ACCESS_KEY;
          break;
        case "ratificaciones":
          this.videoObject.documento
            = config.STG_BASE_URL + "/assets/videos/ratificaciones_presentacion.webm" + config.STG_ACCESS_KEY;
          break;
        case "avaluos":
          this.videoObject.documento
            = config.STG_BASE_URL + "/assets/videos/avaluos_presentacion.webm" + config.STG_ACCESS_KEY;
          break;
        case "fe_hechos":
          this.videoObject.documento
            = config.STG_BASE_URL + "/assets/videos/fe_hechos_presentacion.webm" + config.STG_ACCESS_KEY;
          break;
        default:
          this.videoObject.documento = "";
          break;
      }

      this.$bvModal.show("modal-videos-servicios");
    },

    goToRegistro() {
      this.$bvModal.hide("modal-videos-servicios");
      this.$router.push({ name: 'Home', hash: "#registro" }).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err;
        }
      });
    }
  }
};
</script>

<style>
.imgCard {
  max-width: 100%;
  max-height: 100%;
}

.cardServicio:hover {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px32px;
}
</style>
