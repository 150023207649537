<template>
  <div class="content">

    <b-modal id="videoModal" ref="videoModal" size="xl" centered hide-footer no-close-on-backdrop>
      <video v-if="selectedVideo" :src="selectedVideo.url" controls autoplay style="width: 100%"></video>
    </b-modal>

    <div class="p-4">
      <b-row>
        <b-col v-for="(video, index) in videos" :key="index" cols="6" md="4" lg="3" class="mb-4">
          <b-card :title="video.title" @click="showVideoModal(video)" class="video-card">
            <img :src="video.thumbnail" :alt="video.title" class="img-fluid video-thumbnail" />
          </b-card>
        </b-col>
      </b-row>
    </div>

  </div>
</template>

<script>
import VideotecaVideos from "@/data/videoteca.json";
import config from "@/config";

export default {
  created() {
    this.loadData();
  },

  data() {
    return {
      selectedVideo: null,
      videos: [],
    };
  },

  // computed: {
  //   videos() {
  //     return VideotecaVideos;
  //   },
  // },

  methods: {
    loadData() {
      for (const video of VideotecaVideos) {
        const _video = structuredClone(video);
        
        _video.thumbnail = `${config.STG_BASE_URL}${video.thumbnail}${config.STG_ACCESS_KEY}`;
        _video.url = `${config.STG_BASE_URL}${video.url}${config.STG_ACCESS_KEY}`;

        this.videos.push(_video);
      }
    },

    showVideoModal(video) {
      this.selectedVideo = video;
      this.$refs.videoModal.show();
    },
  },
};
</script>

<style>
.video-thumbnail {
  cursor: pointer;
}

.video-card {
  cursor: pointer;
  text-align: center;
}
</style>
