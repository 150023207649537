<template>
  <div>
    <ValidationObserver>
      <div class="row">
        <div class="col-md-4">
          <ValidationProvider name="firstName" rules="required" v-slot="{ errors }"
            :custom-messages="{ required: 'El nombre es obligatorio' }">
            <base-input type="text" label="Nombre(s)" placeholder="Nombre(s)" v-model="user.firstName">
            </base-input>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-md-5">
          <ValidationProvider name="lastNameP" rules="required" v-slot="{ errors }" :custom-messages="{
            required: 'El apellido paterno es obligatorio',
          }">
            <base-input type="text" label="Apellido Paterno" placeholder="Apellido Paterno" v-model="user.lastNameP">
            </base-input>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div class="col-md-3">
          <base-input type="text" label="Apellido Materno" placeholder="Apellido Materno" v-model="user.lastNameM">
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <ValidationProvider name="email" rules="required|email" v-slot="{ errors }" :custom-messages="{
            required: 'El email es obligatorio',
            email: 'Debe ser un email válido',
          }">
            <base-input type="email" label="Email" placeholder="Email" v-model="user.email">
            </base-input>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <ValidationProvider name="password"
            :rules="{ required: true, min: 8, max: 20, regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d]).+$/ }"
            v-slot="{ errors }" :custom-messages="{
              required: 'La contraseña es obligatoria',
              min: 'La contraseña debe tener al menos 8 caracteres',
              max: 'La contraseña debe tener máximo 20 caracteres',
              regex: 'La contraseña debe tener al menos una letra mayúscula, una minúscula, un número y un caracter especial',
            }">
            <base-input type="password" label="Contraseña" placeholder="Contraseña" v-model="user.password.password">
            </base-input>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div class="col-md-6">
          <ValidationProvider name="confirm_password" rules="required|confirmed:password" v-slot="{ errors }"
            :custom-messages="{
              required: 'La confirmación de contraseña es obligatoria',
              confirmed: 'La contraseña no coincide',
            }">
            <base-input type="password" label="Confirmar Contraseña" placeholder="Confirmar Contraseña"
              v-model="user.password.confirm_password">
            </base-input>
            <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: "Form",

  props: {
    user: { type: Object },
  },

  data() {
    return {
      form: {
        firstName: null,
        lastNameP: null,
        lastNameM: null,
        email: null,
        password: {
          password: null,
          confirm_password: null,
        },
      },
    };
  },
};
</script>
