<template>
  <div class="container">
    <b-modal no-close-on-backdrop centered ref="compareciente-modal" title="" hide-footer id="modalC" size="xl">
      <AgregaSolicitante :current-solicitante="currentCompareciente" :max-number-of-representantes="1" :proceso="2"
        @submit-form="putCompareciente" />
    </b-modal>

    <b-modal no-close-on-backdrop centered ref="modal-confirm-dialog" hide-footer id="modal-confirm-dialog">
      <h4 class="text-center">¿Seguro que quiere remover el solicitante?</h4>
      <h5 class="text-center"></h5>
      <b-button class="mt-3" variant="danger" @click="$bvModal.hide('modal-confirm-dialog')">Cancelar</b-button>
      <b-button class="mt-3 float-right" variant="primary" @click="deleteCompareciente">OK</b-button>
    </b-modal>

    <h4 class="cardD">Información de los Solicitantes</h4>
    <label class="fs-6 text-black-50 text-uppercase">Agregar solicitantes</label>

    <div class="mt-2">
      <label for="btn-add">Nuevo</label>
      <b-button variant="success" class="btn-fill spaceL" size="sm" @click="addCompareciente"
        :disabled="!canAddSolicitante" id="btn-add">
        +
      </b-button>
    </div>

    <div class="mt-2">
      <label>Ó</label>
    </div>

    <div class="mt-2">
      <div>
        <label for="select-comparecientes">Existente (solo aparecerán solicitantes de procesos concluídos)</label>
      </div>
      <div class="col-12">
        <select id="select-comparecientes" :disabled="!canAddSolicitante" class="custom-select"
          v-model="selectedSolicitante" @change="selectSolicitantes($event.target.value)">
          <option v-for="option in comparecientes" :key="option.id" :value="option.id">
            {{ displaytext(option) }}
          </option>
        </select>
      </div>
    </div>

    <div v-if="notificacion.solicitantes.length > 0" class="mt-5">
      <b-table ref="table" hover :fields="fieldsCuadro" :items="notificacion.solicitantes">
        <template #cell(acciones)="{ item }">
          <b-button v-if="!item.existente && !item.representante" variant="warning" size="sm" v-b-tooltip.hover
            title="Editar" class="editBtn" @click="editCompareciente(item)">
            <b-icon-pencil />
          </b-button>
          <b-button v-if="!item.representante" variant="danger" size="sm" v-b-tooltip.hover title="Eliminar"
            @click="predeleteComparreciente(item.index)">
            <b-icon-trash />
          </b-button>
        </template>
      </b-table>
    </div>

    <div class="mt-4">
      <div>
        <b-button type="submit" class="btn-fill orange-btn bottom2" size="m">Continuar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import AgregaSolicitante from "@/components/AgregaSolicitante.vue";

export default {
  name: "SolicitantesNotificaciones",

  components: {
    AgregaSolicitante
  },

  props: {
    notificacion: { type: Object },
    comparecientes: { type: Array }
  },

  data() {
    return {
      selectedSolicitante: null,
      currentCompareciente: null,
      currentComparecienteId: null,

      canAddSolicitante: true,
      solicitanteToAdd: 2, //0: pf, 1: pm, 2: ambos
      comparecienteIsAdded: false,
      moralData: []
    }
  },
  computed: {
    fieldsCuadro() {
      return [
        { key: "index", label: "", class: "text-center" },
        { key: "nombreCompleto", label: "Nombre", class: "text-center" },
        { key: "persona", label: "Persona", class: "text-center" },
        // { key: "principalsymbol", label: "Principal", class: "text-center" },
        // { key: "representadanombre", label: "Representa A:", class: "text-center" },
        { key: "acciones", label: "Acciones", class: "text-center" },
      ];
    },
  },

  created() {
    this.canAddSolicitante = this.comparecientes.length === 0;
  },

  watch: {
    currentCompareciente: function (newVal) {
      this.currentCompareciente.principal = true;
    },
    notificacion: {
      handler: function (newVal, oldVal) {
        const solicitantes = newVal.solicitantes;

        if (solicitantes.length === 0) {
          this.canAddSolicitante = true;
          this.solicitanteToAdd = 2;

          return;
        } else {
          this.canAddSolicitante = false;
        }

        if (solicitantes.length === 1) {
          solicitantes[0].principal = true;
          solicitantes[0].principalsymbol = '✓';

          if (solicitantes[0].persona === 'Física') {
            // this.canAddSolicitante = false;
            this.solicitanteToAdd = 1;
            solicitantes[0].representada = null;
            // solicitantes[0].representante = true;
          } else if (solicitantes[0].persona === 'Moral') {
            // this.canAddSolicitante = true;
            this.solicitanteToAdd = 0;
            solicitantes[0].representada = null;
          }

          return;
        }

        if (solicitantes.length >= 1) {
          const moralIndex = solicitantes.findIndex(s => s.persona === 'Moral');

          if (moralIndex === -1) {
            this.notificacion.solicitantes = [];
            // this.canAddSolicitante = false;
            this.solicitanteToAdd = 2;

            return;
          }

          if (solicitantes.length === 2) {
            // this.canAddSolicitante = true;
            this.solicitanteToAdd = 0;
          } else {
            // this.canAddSolicitante = false;
          }


          solicitantes.forEach(s => {
            if (s.persona === "Física") {
              s.principal = false;
              s.principalsymbol = null;
            }
            if (s.persona === "Moral") {
              s.principal = true;
              s.principalsymbol = '✓';
            }
          });
        }
      },
      deep: true
    },
    comparecienteIsEmpty() {
      return Object.keys(this.currentCompareciente).length === 0;
    }
  },

  methods: {
    displaytext(item) {
      if (item.persona === "Moral") {
        return item.denominacion_social + "(Persona moral)"
      } else if (item.persona === "Física") {
        return item.nombre + ' ' + item.paterno + ' ' + (item.materno || '') + " (Persona física)"
      }
    },

    selectSolicitantes() {
      if (!this.selectedSolicitante) {
        return;
      }

      const index = this.comparecientes.findIndex(o => o.id === this.selectedSolicitante);
      const _solicitante = this.comparecientes[index];
      let nombreCompleto = "";

      if (_solicitante.persona === 'Física') {
        nombreCompleto = `${_solicitante.nombre} ${_solicitante.paterno} ${_solicitante.materno || ''}`.trim();
      } else {
        nombreCompleto = _solicitante.denominacion_social;
      }

      _solicitante.nombreCompleto = nombreCompleto;
      _solicitante.existente = true;

      if (!_solicitante.index) {
        _solicitante.index = this.notificacion.solicitantes.length + 1;
      }

      this.notificacion.solicitantes.push(structuredClone(_solicitante));

      this.selectedSolicitante = null;
    },

    addCompareciente() {
      this.$refs["compareciente-modal"].show(); // TODO: quitar esto
      this.currentCompareciente = {};
    },

    editCompareciente(item) {
      this.$refs["compareciente-modal"].show();
      this.currentCompareciente = item;
    },

    putCompareciente(solicitante) {
      this.currentCompareciente = structuredClone(solicitante);

      this.currentCompareciente.persona = this.currentCompareciente.tipoPersona;

      if (this.currentCompareciente.persona === 'Física') {
        this.currentCompareciente.nombreCompleto = 
          `${this.currentCompareciente.nombre} ${this.currentCompareciente.paterno} ${this.currentCompareciente.materno || ''}`
          .trim();
      } else {
        this.currentCompareciente.nombreCompleto = this.currentCompareciente.denominacion_social;
      }

      this.notificacion.solicitantes = [];
      
      if (!this.currentCompareciente.index) {
        this.currentCompareciente.index = this.notificacion.solicitantes.length + 1;

        this.notificacion.solicitantes.push(this.currentCompareciente);
      }

      this.$refs["compareciente-modal"].hide();
      this.comparecienteIsAdded = true;
    },

    predeleteComparreciente(id) {
      this.currentComparecienteId = id;
      this.$refs["modal-confirm-dialog"].show();
    },

    deleteCompareciente() {
      this.notificacion.solicitantes.length = 0;
      this.canAddSolicitante = true;
      this.$refs.table.refresh();
      this.currentCompareciente = {};
      this.$refs["modal-confirm-dialog"].hide();
    },

    onSubmit() {
      this.$emit("submit-form");
    }
  }
}
</script>

<style scoped>
.icons-size {
  font-size: 20px;
}

/* .container>>>.control-label {
  margin-left: 50px;
  width: 350px;
  position: absolute;
  vertical-align: middle;
} */

.container>>>.custom-control-label {
  margin-left: 50px;
  position: absolute;
  margin-top: 40px;
}

.container>>>.control-label-top {
  margin-top: 0px !important;
  margin-left: 50px;
  text-align: left;
  width: 200px;
}

/* .container>>>.form-control {
  width: 80px;
  margin-left: 400px;
  position: absolute;
  vertical-align: middle;
} */

/* .container>>>.custom-select {
  width: 350px;
  margin-left: 100px;
  vertical-align: middle;
} */

/* .container>>>.long {
  width: 650px !important;
  margin-left: 50px;
} */

.container>>>.form-check-sign {
  margin-top: 15px;
  position: absolute;
}

.check-label-pers {
  margin-left: 45px;
  margin-top: 20px;
  position: absolute;
}

.btn-fill {
  margin-left: 20px;
}

.accordion-container {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.accordion-item {
  border-top: 1px solid #e0e0e0;
}

.accordion-item:first-of-type {
  border-top: none;
}

.accordion-header {
  background-color: #f7f7f7;
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;
}

.accordion-header:hover {
  background-color: #e0e0e0;
}

.accordion-body {
  padding: 20px;
  background-color: #fff;
  border-top: 1px solid #e0e0e0;
}

.select-container {
  margin-top: 20px;
}

.custom-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.custom-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ced4da;
  border-radius: 5px;
}
</style>
