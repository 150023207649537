<template>
  <div>
    <div id="doc">
      <video id="my-player" controls preload="none" :autoplay="videoData.autoplay" :poster="videoData.poster">
        <source :src="videoData.documento" :type="videoData.fileType" />
      </video>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalPreviewVideo",

  props: {
    currentDocumento: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      videoData: {}
    };
  },

  created() {
    this.videoData = {
      autoplay: !!this.currentDocumento.autoplay,
      poster: this.currentDocumento.poster || "/img/cp_logooficial_fondoNEGRO.png",
      documento: this.currentDocumento.documento,
      fileType: this.currentDocumento.fileType || "video/mp4"
    };
  }
};
</script>

<style scoped>
#doc {
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

#my-player {
  text-align: center;
  margin: 0 auto;
  width: 100%;
}
</style>
