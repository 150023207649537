import { render, staticRenderFns } from "./FesHechosInfo.vue?vue&type=template&id=22607970&scoped=true"
import script from "./FesHechosInfo.vue?vue&type=script&lang=js"
export * from "./FesHechosInfo.vue?vue&type=script&lang=js"
import style0 from "./FesHechosInfo.vue?vue&type=style&index=0&id=22607970&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22607970",
  null
  
)

export default component.exports