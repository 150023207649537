<template>
  <div class="contact-us full-screen">
    <div class="container">
      <div v-if="loading">
        <div id="loader">
          <font-awesome-icon icon="spinner" spin />
        </div>
      </div>
    </div>

    <TopNavbar />

    <div class="wrapper wrapper-full-page section content">
      <div class="container">
        <h4 slot="header" class="card-title">Reestablecer contraseña</h4>

        <card id="card">
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(reestablecer)">
              <Form :user="user" @submit.prevent />
              <!-- @onSubmit="enter()" -->
            </form>
          </ValidationObserver>
        </card>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Card from "src/components/Cards/Card.vue";
import Footer from "@/layout/ContentFooter.vue";
import TopNavbar from "@/layout/TopNavbar.vue";
import Form from "@/views/reestablecer/Form.vue";
import { RecuperarPassApi } from "@/api";

export default {
  name: "ReestablecerPass",

  components: {
    Card,
    TopNavbar,
    Footer,
    Form,
  },

  created() {
    this.validate();
  },

  data() {
    return {
      loading: false,
      user: {
        password: null,
        confirm_password: null,
        // email: null,
      },
    };
  },

  methods: {
    async validate() {
      try {
        this.loading = true;

        const hash = this.$route.params.hash;
        //ToDo: sacar el mail de algún lado
        // let email = "mina@example.com";
        // this.user.email = email;
  
        const data = {
          // email: email,
          hash: hash,
        };

        await RecuperarPassApi.validaReestabPass(data);
      } catch (error) {
        // console.log(error);
        this.$toast.error("Esta liga ya no se encuentra disponible", {
          //no es posible procesar solicitid
          timeout: 8000, //15000, //false
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
        this.$router.push({ name: "Home" });
      } finally {
        this.loading = false;
      }
    },

    async reestablecer() {
      try {
        this.loading = true;

        const hash = this.$route.params.hash;
        const data = {
          hash: hash,
          contraseña: this.user.password,
        };

        await RecuperarPassApi.reestablecePass(data);
        this.$toast.success("Contraseña actualizada correctamente, puede iniciar sesión normalmente", {
          timeout: 10000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
        this.$router.push({ name: "Login" });
      } catch (error) {
        // console.log(error);
        this.$toast.error("Sucedió un error, intente más tarde", {
          timeout: 15000, //false
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true,
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
#card {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  /* color: #9a9a9a; */
  color: #ea640b;
  font-size: 13px;
}
</style>
