<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <ValidationProvider
          name="tipo"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{
            required: 'El tipo es obligatorio',
          }"
        >
          <label for="">Tipo</label>
          <select class="custom-select" v-model="currentDocumento.tipo" >
            <option v-for="option in options" :key="option.value">
              {{ option.text }}
            </option>
          </select>
          <!-- <ValidationProvider
            name="documento"
            rules="required"
            v-slot="{ errors }"
            :custom-messages="{ required: 'El nombre es obligatorio' }"
          > -->
          <base-input
            type="text"
            placeholder="Tipo de documento"
            v-model="currentDocumento.descripcion"
            v-if="currentDocumento.tipo === 'Otro'"
            class="mt-2"
          >
          </base-input>
          <!-- <span class="cardErrors">{{ errors[0] }}</span>
          </ValidationProvider> -->

          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <div class="col-md-6">
        <ValidationProvider
          name="documento"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{ required: 'El documento es obligatorio' }"
        >
          <label for="">Documento</label>
          <b-form-file
            v-model="currentDocumento.file"
            browse-text="Seleccionar"
            drop-placeholder="Cargar aquí"
            placeholder="Da click aquí o arrastra el archivo"
            @input="onInput"
          />
          <p class="orange-italic-13p-text">Tamaño máximo del archivo: 25 MB</p>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <label
      class="mt-4 leyenda"
      v-if="currentDocumento.tipo === 'Cuadro de distribución accionaria'"
    >
      Del análisis del cuadro, el staff te contactará para solicitar la
      información requierida de los beneficiarios controladores.
    </label>

    <!-- <div
      class="row"
      v-if="
        currentDocumento.tipo ===
        'PDF del formato de beneficiario controlador correspondiente a cada uno de las personas físicas finales, accionistas de la persona moral, que califiquen en tal carácter'
      "
    >
      <div class="col-6 mt-3">
        <ValidationProvider
          name="documento"
          rules="required"
          v-slot="{ errors }"
          :custom-messages="{ required: 'El nombre es obligatorio' }"
        >
          <base-input
            type="text"
            label="Nombre"
            placeholder="Nombre de la persona física"
            v-model="currentDocumento.nombre"
          >
          </base-input>
          <span class="cardErrors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div> -->

    <div class="text-center mt-5">
      <button type="submit" class="btn btn-fill float-right orange-btn">
        Guardar
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalDocumentos",

  props: {
    currentDocumento: { type: Object },
    options: { type: Array },
  },

  data() {
    return {
      loading: false,

      form: {
        nombre: null,
        documento: null,
        file: null,
        tipo: null,
        nombre: null,
        descripcion: null,//tipoOtro
        cat_documentos_id: null,
      },
    };
  },

  methods: {
    onInput(input) {
      if (input.size > 26214400) {
        this.$toast.error("El archivo subido no puede ser mayor a 25 MB.", {
          timeout: 4000,
          hideProgressBar: false,
          position: "top-right",
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          closeButton: "button",
          icon: true
        });

        this.currentDocumento.file = null;
      }
    },
  },
};
</script>
